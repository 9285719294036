import { useCallback, useRef } from "react";

import { HEADER_ELEMENT_HEIGHT_VARIABLE } from "src/utils/constants/htmlConstants";
import { saveCssVariable } from "src/utils/htmlUtils";

/**
 * Hook to save the header height to a CSS variable.
 */
export default function useDynamicHeaderHeight() {
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  /**
   * Callback ref to save the header height to a CSS variable.
   */
  function updateHeaderHeightRef(node: HTMLElement | null) {
    // Disconnect the previous observer. This is to avoid memory leaks.
    resizeObserverRef.current?.disconnect();

    if (node) {
      const observer = new ResizeObserver(() => {
        saveCssVariable(
          HEADER_ELEMENT_HEIGHT_VARIABLE,
          `${node.offsetHeight}px`
        );
      });
      observer.observe(node);

      resizeObserverRef.current = observer;
    }
  }

  return useCallback(updateHeaderHeightRef, []);
}
