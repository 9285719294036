/* eslint-disable max-len */
import type { USER_PERMISSIONS } from "src/utils/constants/user";

export type UserPermissions = keyof typeof USER_PERMISSIONS;

export type FeatureAccess =
  | { anyOf: UserPermissions[]; noneOf?: UserPermissions[] }
  | { allOf: UserPermissions[]; noneOf?: UserPermissions[] };

export type CapabilityAccess = {
  id: string;
  title: string;
  navigationLink: string;
  accessControl?: FeatureAccess;
  showOnLaunchPage?: boolean;
  description?: string;
  cardTitle?: string;
};

/**
 * Represents a navigation item.
 */
export type NavigationItem = CapabilityAccess & {
  /**
   * Optional fallback navigation link.
   */
  fallbackNavigation?: string;
  /**
   * Optional children navigation items.
   */
  children?: CapabilityAccess[];
};

export const LEGAL_TERMS_PATH = "/legal-terms";

export const NAVIGATION_ITEMS_LEGAL: NavigationItem[] = [
  {
    id: "privacy-policy",
    title: "Privacy Policy",
    navigationLink: `${LEGAL_TERMS_PATH}/privacy-policy`
  },
  {
    id: "terms-and-conditions",
    title: "Terms and Conditions",
    navigationLink: `${LEGAL_TERMS_PATH}/terms-and-conditions`
  }
];

export const ALL_CAPABILITIES: Record<
  | "intelligenceTv"
  | "intelligenceContext"
  | "intelligenceCommerce"
  | "audiences"
  | "creatives"
  | "campaigns"
  | "campaignsOverview"
  | "campaignsBookings"
  | "campaignsSetup"
  | "campaignsResources"
  | "reportsStandard"
  | "reportsAdvanced"
  | "admin",
  CapabilityAccess
> = {
  intelligenceTv: {
    id: "intelligence-tv",
    title: "TV",
    navigationLink: "/intelligence/tv",
    accessControl: { allOf: ["TV_ACCESS"] },
    showOnLaunchPage: true,
    cardTitle: "TV Intelligence",
    description:
      "Explore linear TV, streaming and YouTube  insights for optimal reach and frequency."
  },
  intelligenceContext: {
    id: "intelligence-context",
    title: "Context",
    navigationLink: "/intelligence/context",
    accessControl: { allOf: ["DISCOVER_ACCESS"] },
    showOnLaunchPage: true,
    cardTitle: "Context Intelligence",
    description:
      "Unlock brand insights and audience data, to plan effective campaigns."
  },
  intelligenceCommerce: {
    id: "intelligence-commerce",
    title: "Commerce",
    navigationLink: "/intelligence/commerce",
    accessControl: { allOf: ["COMMERCE_ACCESS"] },
    showOnLaunchPage: false,
    description: "Commerce access"
  },
  audiences: {
    id: "audiences",
    title: "Audiences",
    navigationLink: "/audiences",
    accessControl: { allOf: ["AUDIENCES_ACCESS"] },
    showOnLaunchPage: true,
    cardTitle: "Audiences",
    description:
      "Search for targetable audiences and setup campaigns for activation."
  },
  creatives: {
    id: "creatives",
    title: "Creatives",
    navigationLink: "/creatives",
    accessControl: { allOf: ["CREATIVES_ACCESS"] },
    showOnLaunchPage: false,
    description: "Creatives access"
  },
  campaigns: {
    id: "campaigns",
    title: "Campaigns",
    navigationLink: "/campaigns",
    accessControl: {
      allOf: ["INTERNAL_ACCESS", "CAMPAIGNS_ACCESS"],
      noneOf: ["DEMO_MODE"]
    },
    showOnLaunchPage: false,
    description: "Campaigns access"
  },
  campaignsOverview: {
    id: "campaigns-overview",
    navigationLink: "/campaigns",
    title: "Overview"
  },
  campaignsBookings: {
    id: "campaigns-bookings",
    navigationLink: "/campaigns/bookings",
    title: "Bookings"
  },
  campaignsSetup: {
    id: "campaigns-setup",
    navigationLink: "/campaigns/setup",
    title: "Setup"
  },
  campaignsResources: {
    id: "campaigns-resources",
    navigationLink: "/campaigns/resources",
    title: "Resources"
  },
  reportsStandard: {
    id: "reports-standard",
    title: "Standard",
    navigationLink: "/reports/standard",
    accessControl: { allOf: ["REPORT_ACCESS"] },
    showOnLaunchPage: true,
    cardTitle: "Reports",
    description:
      "Improve business outcomes with data rich custom visualizations."
  },
  reportsAdvanced: {
    id: "reports-advanced",
    title: "Advanced",
    navigationLink: "/reports/advanced",
    accessControl: { allOf: ["ANALYZE_ACCESS"], noneOf: ["DEMO_MODE"] },
    showOnLaunchPage: false,
    description: "Advanced reports access"
  },
  admin: {
    id: "admin",
    title: "Admin",
    navigationLink: "/admin",
    accessControl: { allOf: ["USER_MANAGEMENT_ACCESS"] },
    showOnLaunchPage: false,
    description: "Admin access"
  }
};

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: "intelligence",
    title: "Intelligence",
    navigationLink: "/intelligence",
    fallbackNavigation: "/intelligence/tv",
    children: [
      {
        ...ALL_CAPABILITIES.intelligenceTv
      },
      {
        ...ALL_CAPABILITIES.intelligenceContext
      },
      {
        ...ALL_CAPABILITIES.intelligenceCommerce
      }
    ]
  },
  {
    ...ALL_CAPABILITIES.audiences
  },
  {
    ...ALL_CAPABILITIES.creatives
  },
  {
    ...ALL_CAPABILITIES.campaigns,
    children: [
      { ...ALL_CAPABILITIES.campaignsOverview },
      { ...ALL_CAPABILITIES.campaignsBookings },
      { ...ALL_CAPABILITIES.campaignsSetup },
      { ...ALL_CAPABILITIES.campaignsResources }
    ]
  },
  {
    id: "reports",
    title: "Reports",
    navigationLink: "/reports",
    fallbackNavigation: "/reports/standard",
    children: [
      {
        ...ALL_CAPABILITIES.reportsStandard
      },
      {
        ...ALL_CAPABILITIES.reportsAdvanced
      }
    ]
  }
];
