import axios from "axios";
import { useEffect } from "react";

import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";

import useUserFeatureFlag from "../useUserFeatureFlag";

/**
 * Batch requests into groups of `batchSize`.
 *
 * @param urls - Array of URLs to batch
 * @param batchSize - Number of requests to batch
 * @returns Array of batches
 */
function batchRequests(urls: string[], batchSize = 2) {
  return urls.reduce((acc, url, index) => {
    const batchIndex = Math.floor(index / batchSize);
    acc[batchIndex] = acc[batchIndex] || [];
    acc[batchIndex].push(url);
    return acc;
  }, [] as string[][]);
}

/**
 * Retrieve remote URLs from the environment variables.
 *
 * @returns Array of remote URLs
 */
function getRemoteUrls() {
  return Object.entries(window._env_)
    .map(([key, value]) => {
      if (key === "profile") return "";

      return value.replace("/remoteEntry.js", "");
    })
    .filter(Boolean)
    .concat(window.location.origin);
}

/**
 * Hook to clear site data for all remote origins.
 * This hook is only enabled when the `clearSiteData` feature flag is enabled.
 */
export default function useClearSiteData() {
  const { value: clearSiteData } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.debug.CLEAR_SITE_DATA,
    false
  );

  /**
   * Clear site data for all remote origins.
   */
  useEffect(() => {
    if (!clearSiteData) return;

    const abortController = new AbortController();
    const signal = abortController.signal;

    const urls = getRemoteUrls();
    const batches = batchRequests(urls);

    /**
     * Initiates the process of clearing site data for all remote origins.
     *
     * @param batch - Array of batches
     */
    async function processBatch(batch: string[][]) {
      for (const currentBatch of batch) {
        await Promise.allSettled(
          currentBatch.map(async (origin) => {
            try {
              await axios.get(`${origin}/browser-action/clear-cache`, {
                signal,
                fetchOptions: { priority: "low" }
              });
            } catch (error) {
              console.error(`Failed to clear site data for ${origin}`, error);
            }
          })
        );
      }
    }

    processBatch(batches);

    return () => {
      abortController.abort();
    };
  }, [clearSiteData]);
}
