export const INTERNAL_USER = "INTERNAL";
export const EXTERNAL_USER = "EXTERNAL";

export const HUB_LOGIN_TOKEN_COOKIE = "hub-login-token";
export const HUB_AUTH_TOKEN_COOKIE = "hub-auth-token";
export const TOKEN_EXPIRY_TIME = "hub-auth-expiry-date";
export const HUB_USER_GEO_COOKIE = "hub-user-geo";
export const HUB_USER_PERMISSIONS = "hub-user-permissions";
export const HUB_USER_FIRSTNAME_COOKIE = "hub-user-first-name";
export const HUB_USER_EMAIL_COOKIE = "hub-user-email";
export const HUB_USER_LASTNAME_COOKIE = "hub-user-last-name";
export const HUB_USER_DESIGNATION_COOKIE = "hub-user-designation";
export const HUB_USER_IMAGE_COOKIE = "hub-user-image";
export const HUB_USER_DEFAULT_GEO_COOKIE = "hub-user-default-geo";
export const HUB_TERMS_ACCEPTED = "hub-terms-accepted";
export const HUB_DEMO_MODE = "hub-demo-mode";
export const HUB_COOKIELESS_DISCOVER_MODE = "hub-cookieless-discover-mode";
export const HUB_COOOKIELESS_DEFAULT_GEO = "hub-cookieless-default-geo";
export const HUB_COOKIELESS_USER_GEO_COOKIE = "hub-cookieless-user-geo";
export const HUB_AUDIENCE_MANAGER = "hub-audience-manager";
export const UNIFIED_PLATFORM_APP = "unified-platform";
export const THEME = "theme";
export const PIXIE = "isPixieEnabled";

export const USER_PERMISSIONS = {
  INTERNAL_ACCESS: "INTERNAL_ACCESS",
  USER_MANAGEMENT_ACCESS: "USER_MANAGEMENT_ACCESS",
  DISCOVER_ACCESS: "DISCOVER_ACCESS",
  COOKIELESS_ACCESS: "COOKIELESS_ACCESS",
  AUDIENCES_ACCESS: "AUDIENCES_ACCESS",
  TV_ACCESS: "TV_ACCESS",
  COMMERCE_ACCESS: "COMMERCE_ACCESS",
  REPORT_ACCESS: "REPORT_ACCESS",
  ANALYZE_ACCESS: "ANALYZE_ACCESS",
  CREATIVES_ACCESS: "CREATIVES_ACCESS",
  CAMPAIGNS_ACCESS: "CAMPAIGNS_ACCESS",
  DEMO_MODE: "DEMO_MODE"
};

export const WHITELABEL_RESTRICTED_PERMISSIONS = new Set<
  keyof typeof USER_PERMISSIONS
>(["CREATIVES_ACCESS", "CAMPAIGNS_ACCESS"]);
