import Axios from "axios";

import { router } from "src/routes";

import { getOktaAccessToken, getOktaIdToken, isJwtExpired } from "./authUtil";

/**
 * 1. Before login we don't have token, so before re-directing to /login we need to check if token is present
 *    otherwise it will end in recursion.
 * 2. After Login we fetch token & after given time it expires, so logout user.
 * 3. After Login, the access token gets revoked, after say 24hrs, but we have idToken.
 *    So we need to logout user if it has idToken but no access token
 */
Axios.interceptors.request.use(
  (config) => {
    const idToken = getOktaIdToken();
    const accessToken = getOktaAccessToken();

    if ((idToken && !accessToken) || (accessToken && isJwtExpired())) {
      router.navigate("/logout");
      return config;
    }
    if (accessToken && !config.headers.Authorization) {
      config.headers.Authorization = `${accessToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export { Axios };
