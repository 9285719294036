/* eslint-disable max-len */
import styled from "@emotion/styled";

const MainStyled = styled.main`
  display: flex;
  gap: 2rem;
  justify-content: space-evenly;
  min-height: 100vh;
  padding: 2rem;
  background-color: ${({ theme }) => theme.vars.palette.surface.background.miq};
  color: ${({ theme }) => theme.vars.palette.container.dynamic.neutral.subtle.background.default.regular};
  align-items: center;

  .miq-logo-img {
    max-width: 100px;
  }

  .login-animation {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.vars.palette.border.dynamic.neutral.bold.disabled};
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1;
    width: 44px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.vars.palette.border.dynamic.neutral.inversed.default};);
  }

  .carousel.carousel-slider .control-arrow {
    top: 40%;
    color: ${({ theme }) => theme.vars.palette.border.dynamic.neutral.inversed.default};
    font-size: 26px;
    bottom: 0;
    height: 42px;
    width: 42px;
    background: ${({ theme }) => theme.vars.palette.container.dynamic.neutral.subtle.foreground.default.regular};
    border-radius: 50%;
    opacity: 1;
  }

  .carousel .control-next.control-arrow:before {
    display: inline-block;
    border: none;
    width: 24px;
    height: 24px;
    margin: 5px;
    background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M9.80234 18.8106L16.3465 12.2664C16.493 12.12 16.493 11.8825 16.3465 11.736L9.80234 5.19187C9.65589 5.04542 9.41843 5.04542 9.27201 5.19187L8.6533 5.81058C8.50684 5.95701 8.50684 6.19443 8.6533 6.34092L14.3001 12.0012L8.6533 17.6615C8.50684 17.808 8.50684 18.0454 8.6533 18.1918L9.27201 18.8106C9.41843 18.957 9.65589 18.957 9.80234 18.8106Z" fill="white"/%3E%3Crect opacity="0.01" width="24" height="24" fill="white"/%3E%3C/svg%3E'); /* SVG encoded data */
    background-size: cover;
  }

  .carousel .control-prev.control-arrow:before {
    display: inline-block;
    border: none;
    width: 24px;
    height: 24px;
    margin: 5px 5px 5px 3px;
    background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M14.1977 5.18942L7.65348 11.7336C7.50703 11.88 7.50703 12.1175 7.65348 12.264L14.1977 18.8081C14.3441 18.9546 14.5816 18.9546 14.728 18.8081L15.3467 18.1894C15.4932 18.043 15.4932 17.8056 15.3467 17.6591L9.69987 11.9988L15.3467 6.33849C15.4932 6.19204 15.4932 5.95461 15.3467 5.80816L14.728 5.18942C14.5816 5.04299 14.3441 5.04299 14.1977 5.18942Z" fill="white"/%3E%3Crect opacity="0.01" x="24" y="24" width="24" height="24" transform="rotate(180 24 24)" fill="white"/%3E%3C/svg%3E'); /* SVG encoded data */
    background-size: cover;
  }

  .miq-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }

  .animation-text {
    margin: 0;
  }

  .animation-title {
    font-size: 36px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: 0.3px;
    text-align: center;
    margin-bottom: 16px;
  }

  .animation-description {
    width: 374px;
    height: 38px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }

  .login {
    margin-right: 80px;
    width: 516px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .login-card {
    z-index: 2;
    width: 516px;
    padding: 12% 10%;
    min-height: 680px;
    max-height: 715px;
    border-radius: 2px;
    color: ${({ theme }) => theme.vars.palette.surface.foreground.static.neutral.lvl1};
    background-color: ${({ theme }) => theme.vars.palette.container.dynamic.neutral.subtle.background.default.regular};
    box-shadow: ${({ theme }) => `0px 1px 4px 0px ${theme.vars.palette.shadow.lvl8}, 0px 8px 16px 0px ${theme.vars.palette.shadow.lvl4}`};

    .logo-subtitle-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .logo {
      width: 100px;
      text-align: center;
    }

    .form-subtitle-description {
      margin-top: 24px;
      margin-bottom: 40px;
      color: ${({ theme }) => theme.colors.primaryDark};
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
    }

    .form-subtitle-signin {
      margin-top: 56px;
      margin-bottom: 24px;
      color: ${({ theme }) => theme.colors.primaryDark};
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
    .back-button {
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 110px;
      margin-top: 16px;
    }
    .login-footer {
      .forgot-password {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 19px;
        padding-top: 24px;
      }
      .account-help-text {
        margin-right: 4px;
      }
      .oval {
        margin: 0 8px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: ${({ theme }) => theme.colors.neutral500};
      }
    }

    .slack-icon {
      margin-left: 8px;
      margin-right: 4px;
    }

    .link-text {
      cursor: pointer;
      .back-icon {
        margin-right: 8px;
      }
    }
  }

  .link-button {
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    font-size: inherit;
    cursor: pointer;
  }

  .left-background-panel {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: fixed;
    filter: blur(100px);
    top: 65%;
    right: 15%;
  }

  .right-background-panel {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: fixed;
    filter: blur(100px);
    top: 65%;
    left: 15%;
  }

  .footer-logo {
    width: 75px;
    height: 44px;
    top: 93%;
    z-index: 1;
    position: fixed;
  }

  .o-form-error-container.o-form-has-errors {
    margin-bottom: 16px;
  }

  .o-form-input-name-password.o-form-control.okta-form-input-field.input-fix.o-form-has-errors {
    height: 34px !important;
  }

  .o-form-input-name-username.o-form-control.okta-form-input-field.input-fix.o-form-has-errors {
    height: 34px !important;
  }
`;

export default MainStyled;
