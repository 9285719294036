import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";

import { clearLocalStorage } from "./utils";

const LogoutPage = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    clearLocalStorage();
    oktaAuth.signOut();
  }, [oktaAuth]);

  return <>Session expired. Please login again.</>;
};

export default LogoutPage;
