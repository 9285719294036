import _ from "lodash";

/**
 * Scroll the target element into view if it is not already in view.
 */
export const scrollIntoViewIfNeeded = (
  target: Element,
  scrollOptions: ScrollIntoViewOptions = {},
  parentElement: Element | null = null
) => {
  if (parentElement) {
    const parentRect = parentElement.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    if (targetRect.top < parentRect.top) {
      parentElement.scrollBy({
        top: targetRect.top - parentRect.top,
        behavior: "smooth"
      });
    }

    if (targetRect.bottom > parentRect.bottom) {
      parentElement.scrollBy({
        top: targetRect.bottom - parentRect.bottom,
        behavior: "smooth"
      });
    }

    return;
  }

  // Target is above the current viewport
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView({
      block: "start",
      inline: "nearest",
      ...scrollOptions
    });
  }

  // Target is below the current viewport
  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView({
      block: "end",
      inline: "nearest",
      ...scrollOptions
    });
  }
};

/**
 * Saves the CSS variable with the given name and value.
 */
export function saveCssVariable(name: string, value: string) {
  document.documentElement.style.setProperty(name, value);
}

/**
 * Converts string px value to number.
 *
 * @param px value in px
 * @returns number value of the px or 0
 */
export function convertPxToNumber(px: string) {
  const size = parseInt(px.split("px")[0], 10);

  return _.isNaN(size) ? 0 : size;
}
