import {
  initializeFaro as initFaro,
  createReactRouterV6DataOptions,
  ReactIntegration,
  getWebInstrumentations
} from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { matchRoutes } from "react-router-dom";

import { grafanaEnvironment } from "../config/config";

export const initializeFaro = () =>
  initFaro({
    url: "https://faro-collector-prod-us-east-0.grafana.net/collect/e563022517587c7823bf88f83d2fe560",
    app: {
      name: "unified-platform",
      version: "1.0.0",
      environment: grafanaEnvironment
    },

    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),

      // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation(),

      // React integration for React applications.
      new ReactIntegration({
        router: createReactRouterV6DataOptions({
          matchRoutes
        })
      })
    ]
  });
