import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import type { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import PageStyles from "src/components/pageStyles";
import PageTitle from "src/components/pageTitle";
import useBeaconTracking from "src/hooks/useBeaconTracking";
import { oktaAuth } from "src/utils/oktaConfig";

/**
 * Entry point for the application.
 */
export default function IndexEntryPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useBeaconTracking();

  /**
   * Clear Beacon session when the user logs out.
   */
  useEffect(() => {
    if (["/login", "/logout"].includes(pathname) && window.Beacon) {
      window.Beacon("logout");
    }
  }, [pathname]);

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth,
    originalUri
  ) => {
    if (originalUri) {
      navigate(toRelativeUrl(originalUri, window.location.origin));
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <PageTitle />
      <PageStyles />
      <Outlet />
    </Security>
  );
}
