import { useEffect, useState } from "react";

import PageLoader from "src/components/pageLoader";
import { HEADER_ELEMENT_HEIGHT_VARIABLE } from "src/utils/constants/htmlConstants";
import { convertPxToNumber } from "src/utils/htmlUtils";

import ComingSoon from "../../../components/comingSoon";

/**
 * Creatives component
 */
const Creatives = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    function setWidthAndHeight(windowHeight: number, windowWidth: number) {
      const headerHeight = convertPxToNumber(
        document.documentElement.style.getPropertyValue(
          HEADER_ELEMENT_HEIGHT_VARIABLE
        )
      );

      const iframeHeight = windowHeight - headerHeight;
      setHeight(iframeHeight);
      setWidth(windowWidth);
    }

    function resizeHandler() {
      setWidthAndHeight(window.innerHeight, window.innerWidth);
    }

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [isLoading]);

  function handleIframeLoad() {
    setIsLoading(false);
  }

  if (isError) {
    return <ComingSoon>Coming Soon</ComingSoon>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      {!isError && (
        <iframe
          style={isLoading ? { display: "none" } : {}}
          data-testid="creatives-iframe"
          src="https://creative.miqdigital.com/en-us/showcase"
          title="Creatives"
          width={width}
          height={height}
          onLoad={handleIframeLoad}
          onError={() => setIsError(true)}
        />
      )}
      {isError && <div>Error loading Creatives</div>}
    </>
  );
};

export default Creatives;
