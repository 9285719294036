import { useTheme } from "@emotion/react";
import {
  Button,
  Typography,
  TelevisionSimpleIcon,
  MagnifyingGlassIcon,
  UsersThreeIcon,
  ChartBarIcon
} from "@miq/fiber-ui";
import { useNavigate } from "react-router-dom";

import useMixpanel from "src/hooks/useMixpanel";
import usePageAccess from "src/redux/slices/pageAccess/hooks/usePageAccess";
import { LAUNCH_BUTTON_CLICK } from "src/utils/constants/mixpanelConstants";

import {
  CardBody,
  CardContent,
  CardText,
  Icon,
  IconWrapper,
  StyledCard,
  Wrapper
} from "./styled";

export default function Cards() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { capabilities } = usePageAccess();
  const { trackEvent } = useMixpanel();

  const iconMap: Record<string, JSX.Element> = {
    TV: (
      <TelevisionSimpleIcon
        weight="regular"
        size="32"
        color={theme.vars.palette.surface.foreground.static.neutral.lvl3}
      />
    ),
    Context: (
      <MagnifyingGlassIcon
        weight="regular"
        size="32"
        color={theme.vars.palette.surface.foreground.static.neutral.lvl3}
      />
    ),
    Audiences: (
      <UsersThreeIcon
        weight="regular"
        size="32"
        color={theme.vars.palette.surface.foreground.static.neutral.lvl3}
      />
    ),
    Standard: (
      <ChartBarIcon
        weight="regular"
        size="32"
        color={theme.vars.palette.surface.foreground.static.neutral.lvl3}
      />
    )
  };

  /**
   * Handles the click event for the launch button.
   *
   * @param title capability title
   * @param navigationLink navigation link for the capability
   */
  function handleLaunchClick(title: string, navigationLink: string) {
    const event = `${title}${LAUNCH_BUTTON_CLICK}`;
    trackEvent(event);
    navigate(navigationLink);
  }

  return (
    <Wrapper id="card-component">
      {capabilities
        .filter((capability) => capability.showOnLaunchPage)
        .map((card) => (
          <StyledCard key={card.id}>
            <CardContent>
              <CardBody>
                <IconWrapper>
                  <Icon>{iconMap[card.title]}</Icon>
                </IconWrapper>
                <Typography variant="Headline" size="md" fontWeight="medium">
                  {card.cardTitle}
                </Typography>
                <CardText variant="Body" size="sm" fontWeight="regular">
                  {card.description}
                </CardText>
                <Button
                  primary
                  onClick={() =>
                    handleLaunchClick(card.title, card.navigationLink)
                  }
                >
                  Launch
                </Button>
              </CardBody>
            </CardContent>
          </StyledCard>
        ))}
    </Wrapper>
  );
}
