import styled from "@emotion/styled";
import { Grid } from "@miq/fiber-ui";

export const CardsHolder = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  a:hover {
    cursor: pointer;
  }
`;

export const TermsCard = styled.div`
  .terms-card {
    margin-bottom: 24px;
    .terms-card-title {
      padding: 24px 24px 18px !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      letter-spacing: -0.32px !important;
      line-height: 19px !important;
    }
    .terms-card-body {
      padding: 24px !important;
      font-size: 14px !important;
      letter-spacing: -0.15px !important;
      line-height: 19px !important;
      .terms-card-item-holder {
        margin-bottom: 20px;
        .terms-card-item-number {
          margin-right: 20px;
        }

        .card-list {
          margin: 15px 0px 0px 24px;
          font-size: 14px !important;
          letter-spacing: -0.15px !important;
          line-height: 19px !important;
          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export const GridStyled = styled(Grid)`
  max-width: 1200px;
  margin-inline: auto;
  margin-top: 24px;
  margin-bottom: 72px;
`;

export const NavigationListStyled = styled.ul<{
  heightOffset: number;
  headerHeight: number;
  maxHeight: number | null;
  topOffset: number;
}>`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overscroll-behavior: contain;
  padding-bottom: 24px;

  &.fixed {
    position: fixed;
    top: ${({ headerHeight, topOffset }) => headerHeight + topOffset}px;
    height: calc(100vh - ${({ heightOffset }) => heightOffset}px);
    max-height: ${({ maxHeight, heightOffset }) =>
      maxHeight ? `${maxHeight - heightOffset}px` : "none"};
    overflow-y: auto;
    width: 282px;
  }
`;

export const NavigationLinkStyled = styled.button`
  background: none;
  border: none;
  text-align: left;

  .nav-link--active {
    color: var(
      --fiber-colors-container-dynamic-brand-subtle-foreground-selected
    );
  }
`;
