import { Card, Col, Row, Typography } from "@miq/fiber-ui";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

import type { TermsAndConditionsType } from "src/redux/slices/whitelabel/utils";
import { scrollIntoViewIfNeeded } from "src/utils/htmlUtils";

import {
  CardsHolder,
  GridStyled,
  NavigationLinkStyled,
  NavigationListStyled,
  TermsCard
} from "./styled";

type Props = {
  navItems: string[];
  cardsData: TermsAndConditionsType[];
};

/**
 * Page layout component for the legal terms pages.
 */
export default function PageLayout({ navItems, cardsData }: Readonly<Props>) {
  const [activeNavItem, setActiveNavItem] = useState(navItems[0] ?? "");
  /**
   * Limits the navigation height when the cards holder is at the end of the page.
   */
  const [maxNavHeight, setMaxNavHeight] = useState<number | null>(null);
  const [isFixedNav, setIsFixedNav] = useState(false);

  const navigationRef = useRef<HTMLUListElement>(null);
  const cardsHolderRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const cardsRef = useRef<HTMLDivElement[]>([]);

  /**
   * Adds the card reference to the list of cards.
   */
  const addCardRef = useCallback((element: HTMLDivElement) => {
    cardsRef.current.push(element);
  }, []);

  const header = document.getElementById("ufp-app-header");
  const headerHeight = header?.offsetHeight ?? 64;
  const topOffset = 20;
  const bottomOffset = 20;
  const navHeightOffset = headerHeight + topOffset + bottomOffset;

  // Registers the window scroll event listener.
  useEffect(() => {
    /**
     * Scrolls the active navigation item into view.
     */
    function scrollActiveNavIntoView() {
      const element = document.getElementsByClassName("nav-link--active")[0];
      if (element)
        scrollIntoViewIfNeeded(
          element,
          { behavior: "smooth" },
          navigationRef.current
        );
    }

    /**
     * Updates the navigation height when the cards holder is at the end of the page.
     */
    function handleNavHeightAtEndOfPage() {
      if (!cardsHolderRef.current) return;

      const { bottom, height, top } =
        cardsHolderRef.current.getBoundingClientRect();

      if (bottom <= window.innerHeight) {
        setMaxNavHeight(height + top);
      } else {
        setMaxNavHeight(null);
      }
    }

    /**
     * Updates the navigation position based on the scroll position.
     */
    function handleWindowScroll() {
      if (window.scrollY >= headerHeight) {
        setIsFixedNav(true);
      } else {
        setIsFixedNav(false);
      }

      handleNavHeightAtEndOfPage();
      scrollActiveNavIntoView();
    }

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, [headerHeight, navHeightOffset]);

  // Initializes the IntersectionObserver to track the active navigation item.
  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries
          .filter((entry) => entry.isIntersecting)
          .sort((a, b) => {
            const rectA = a.boundingClientRect;
            const rectB = b.boundingClientRect;
            return rectA.top - rectB.top;
          });

        if (visibleEntries.length > 0) {
          // Get the topmost visible card
          const topCard = visibleEntries[0];
          const cardId = topCard.target.getAttribute("id");

          if (cardId) {
            setActiveNavItem(cardId);
          }
        }
      },
      {
        root: null,
        threshold: 0.5
      }
    );

    // Observe all cards
    cardsRef.current.forEach((cardRef) => {
      if (cardRef && observerRef.current) {
        observerRef.current.observe(cardRef);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  /**
   * Scrolls the active navigation item into view when the activeNavItem changes.
   */
  useEffect(() => {
    const element = document.getElementsByClassName("nav-link--active")[0];
    if (element) scrollIntoViewIfNeeded(element);
  }, [activeNavItem]);

  /**
   * Handles the click event on the navigation item.
   */
  function handleNavItemClick(id: string) {
    setActiveNavItem(id);
    const element = document.getElementById(id);
    const headerEl = document.getElementById("ufp-app-header");

    if (element && headerEl) {
      const cardHeaderHeight = headerEl.clientHeight;
      window.scrollTo({
        // Adding 5px to have some space between the header and the title
        top: element.offsetTop - (cardHeaderHeight + 5),
        behavior: "smooth"
      });
    }
  }

  /**
   * Renders the cards based on the data.
   */
  function renderCards() {
    return (
      <CardsHolder ref={cardsHolderRef}>
        {cardsData.map((value) => (
          <TermsCard key={value.title} id={value.navTitle} ref={addCardRef}>
            <Card className="terms-card">
              <Card.Header className="terms-card-title">
                <Typography
                  variant="Body"
                  size="lg"
                  fontWeight="medium"
                  as="span"
                >
                  {value.title}
                </Typography>
              </Card.Header>
              <Card.Body className="terms-card-body">
                <Typography
                  variant="Body"
                  size="md"
                  fontWeight="regular"
                  as="span"
                >
                  {value.body.map((pointBody, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                      {pointBody.pointHtml}
                    </React.Fragment>
                  ))}
                </Typography>
              </Card.Body>
            </Card>
          </TermsCard>
        ))}
      </CardsHolder>
    );
  }

  return (
    <GridStyled gutter="24">
      <Row>
        {navItems.length > 0 && (
          <Col className="navigation-col legal-terms-col" span="3">
            <NavigationListStyled
              ref={navigationRef}
              className={classNames({ fixed: isFixedNav })}
              heightOffset={navHeightOffset}
              maxHeight={maxNavHeight}
              headerHeight={headerHeight}
              topOffset={topOffset}
            >
              {navItems.map((value) => (
                <li key={value}>
                  <NavigationLinkStyled
                    onClick={handleNavItemClick.bind(null, value)}
                  >
                    <Typography
                      variant="Headline"
                      size="xs"
                      fontWeight="medium"
                      className={classNames({
                        "nav-link--active": activeNavItem === value
                      })}
                    >
                      {value}
                    </Typography>
                  </NavigationLinkStyled>
                </li>
              ))}
            </NavigationListStyled>
          </Col>
        )}
        <Col className="legal-terms-col" span="6">
          {renderCards()}
        </Col>
      </Row>
    </GridStyled>
  );
}
