import { PIXIE, THEME } from "src/utils/constants/user";
import { getFromLocalStorage, setInLocalStorage } from "src/utils/storageUtil";

export function clearLocalStorage() {
  const userTheme = (getFromLocalStorage(THEME) as "light" | "dark") || "light";
  const isPixie = (getFromLocalStorage(PIXIE) as "true" | "false") || "false";
  localStorage.clear();
  setInLocalStorage(THEME, userTheme);
  setInLocalStorage(PIXIE, isPixie);
}
