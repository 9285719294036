import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import { Navigate, useMatches } from "react-router-dom";

import Restricted from "src/pages/restricted";
import usePageAccess from "src/redux/slices/pageAccess/hooks/usePageAccess";
import { useUserDetails } from "src/redux/slices/userSlice";

type AccessRouteProps = PropsWithChildren;

/**
 * Provides access control to the children components.
 * Requirements:
 * * `id` should be set on the route where the access control is needed.
 * Usage:
 * ```tsx
 * {
    path: "tv/*",
    id: ALL_CAPABILITIES.intelligenceTv.id,
    element: (
      <AccessRoute>
        <Tvi />
      </AccessRoute>
    ),
},
 * ```
 * 
 * TODO: Refactor this component to simplify access restrictions.
 * This component has tight coupling with the route setup.
 */
const AccessRoute: React.FC<AccessRouteProps> = ({ children }) => {
  const { capabilities } = usePageAccess();
  const { isDemoMode } = useUserDetails();
  const routes = useMatches();

  /**
   * Check if the user has access to the route.
   */
  const hasAccess = useMemo(
    () =>
      routes.some((route) =>
        capabilities.some((capability) => capability.id === route.id)
      ),
    [capabilities, routes]
  );

  if (!hasAccess && isDemoMode) return <Navigate to="/" />;

  if (!hasAccess) return <Restricted />;

  return <>{children}</>;
};

export default AccessRoute;
