import { Global, useTheme } from "@emotion/react";
import { useEffect } from "react";

import useWhiteLabel from "src/redux/slices/whitelabel/hooks/useWhiteLabel";

import { globalStyles } from "./styled";

/**
 * Component to set the root styles based on the white label configuration.
 */
const RootStyles = () => {
  const { clientControlConfig, folderName, favicon } = useWhiteLabel();
  const theme = useTheme();

  useEffect(() => {
    /**
     * Sets the favicon.
     */
    async function setFavicon() {
      const faviconEl = document.getElementById(
        "favicon-link-el"
      ) as HTMLLinkElement;
      if (faviconEl) {
        faviconEl.href = favicon;
      }
    }

    setFavicon();
  }, [clientControlConfig.icon, favicon, folderName]);

  return <Global styles={() => globalStyles(theme)} />;
};

export default RootStyles;
