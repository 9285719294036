import styled from "@emotion/styled";
import { Typography } from "@miq/fiber-ui";

export const CardWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 550px;
  padding: 48px 72px 36px;
  width: 588px;
  z-index: 2;
  background: var(--fiber-colors-surface-background-lvl1);
  box-shadow: 0 4px 40px 0 var(--fiber-colors-shadow-lvl4);
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const Logo = styled.img`
  height: 40px;
  width: auto;
`;

export const Title = styled(Typography)`
  margin-bottom: 36px;
  margin-top: 52px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 18px;
`;

export const FooterStyled = styled.footer`
  width: 75px;
  height: 44px;
  top: 93%;
  z-index: 1;
  position: fixed;
`;
