import { useEffect, useState } from "react";

import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";

/**
 * Hook to get the global banner settings.
 */
export default function useGlobalBanner() {
  const { value: isEnabled } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.globalBanner.IS_ENABLED,
    false
  );
  const { value: bannerTitle } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.globalBanner.BANNER_MESSAGE,
    ""
  );
  const { value: globalBannerType } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.globalBanner.BANNER_TYPE,
    "good"
  );
  const { value: isGlobalBannerDismissible } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.globalBanner.IS_DISMISSIBLE,
    true
  );

  const [isBannerVisible, setIsBannerVisible] = useState(() => isEnabled);

  useEffect(() => {
    setIsBannerVisible(isEnabled);
  }, [isEnabled]);

  /**
   * Hides the global banner.
   */
  function hideBanner() {
    setIsBannerVisible(false);
  }

  return {
    isBannerVisible,
    bannerTitle,
    globalBannerType,
    isGlobalBannerDismissible,
    hideBanner
  };
}
