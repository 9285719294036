import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  getAllMiqLabUsers,
  getMiqUserByEmailId
} from "src/pages/campaigns/services";
import { getUserDetails } from "src/redux/slices/userSlice/userReducer";

export interface LabUser {
  id: number;
  name: string;
  email: string;
  type: string;
}

interface LabDetails {
  allUsers: LabUser[] | null;
  analystUsers: LabUser[] | null;
  currentUser: unknown | null;
}

/**
 * Initializes Lab users and sets the lab user details.
 * !IMPORTANT: This hook is meant to be a singleton and should be used only once in the application.
 *
 * @returns Lab Details
 */
export default function useLabDetails(): LabDetails {
  const [allUsers, setAllUsers] = useState<LabUser[] | null>(null);
  const [analystUsers, setAllAnalystUsers] = useState<LabUser[] | null>(null);
  const [currentUser, setCurrentUser] = useState<unknown | null>(null);
  const oktaAuth = useOktaAuth();
  const accessToken = oktaAuth.authState?.accessToken?.accessToken ?? "";

  const [apiError, setApiError] = useState<Error | null>(null);

  const { userDetails } = useSelector(getUserDetails);
  const { email: userEmail } = userDetails ?? {};

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const response = await getAllMiqLabUsers(accessToken);
        setAllUsers(response.data);
        const analystUser = response.data.filter(
          (user: { type: string }) => user.type === "Analyst"
        );
        setAllAnalystUsers(analystUser);
      } catch (error) {
        console.error("Error fetching users:", error);
        setApiError(new Error("Error fetching users", { cause: error }));
      }
    };

    const fetchCurrentUser = async (email: string) => {
      try {
        if (email) {
          const response = await getMiqUserByEmailId(email, accessToken);
          setCurrentUser({ currentUser: response.data });
        }
      } catch (error) {
        console.error("Error fetching current user:", error);
        setApiError(new Error("Error fetching current user", { cause: error }));
      }
    };

    getAllUsers();
    fetchCurrentUser(userEmail ?? "");
  }, [userEmail, accessToken]);

  /**
   * If the API fails, we should show the ErrorBoundary component.
   */
  if (apiError) {
    throw apiError;
  }

  return { allUsers, analystUsers, currentUser };
}
