import { LoginCallback } from "@okta/okta-react";
import { Navigate, type RouteObject } from "react-router-dom";

import Fallback from "src/components/fallback";
import HomePage from "src/pages/home";
import IndexEntryPage from "src/pages/indexEntry";
import LaunchPage from "src/pages/launchPage";
import LoginPage from "src/pages/login";
import LogoutPage from "src/pages/logout";
import Restricted from "src/pages/restricted";
import Unauthorized from "src/pages/unauthorized";
import SecureRoute from "src/routes/components/SecureRoute";
import {
  HOME_PAGE_ID,
  LOGIN_PAGE_ID
} from "src/utils/constants/routeConstants";

import legalTermsRoute from "./legalTerms";
import {
  adminRoute,
  audienceRoute,
  campaignsRoute,
  creativesRoute,
  intelligenceRoute,
  reportRoute
} from "./mfeRoutes";
import authLoader, { remoteLoaderWrapper } from "./utils/loader";

const routes: RouteObject[] = [
  {
    element: <IndexEntryPage />,
    children: [
      {
        element: (
          <SecureRoute>
            <HomePage />
          </SecureRoute>
        ),
        id: HOME_PAGE_ID,
        loader: authLoader(remoteLoaderWrapper(window._env_.tsa, "tsa", "Tsa")),
        path: "/",
        ErrorBoundary: Fallback,
        children: [
          {
            index: true,
            element: <LaunchPage />
          },
          {
            path: "/restricted",
            element: <Restricted />
          },
          { ...intelligenceRoute },
          { ...audienceRoute },
          { ...campaignsRoute },
          { ...creativesRoute },
          { ...reportRoute },
          { ...adminRoute },
          { ...legalTermsRoute }
        ]
      },
      {
        path: "/login",
        id: LOGIN_PAGE_ID,
        element: <LoginPage />
      },
      {
        path: "/login/callback",
        element: <LoginCallback />
      },
      { path: "/logout", element: <LogoutPage /> },
      {
        path: "/terms-and-conditions",
        element: <Navigate to="/legal-terms/terms-and-conditions" />
      },
      {
        path: "/unauthorized",
        element: <Unauthorized />
      },
      {
        path: "*",
        element: <div>Not Found</div>
      }
    ]
  }
];

export default routes;
