import type { UserClaims } from "@okta/okta-auth-js";
import { OktaAuth } from "@okta/okta-auth-js";

import { authServerProperties } from "src/config/config";
import { store } from "src/redux/store";

export type UserClaimWithUid = UserClaims & { uid?: string };

const { display, clientId, issuer, redirectUri, scope } =
  authServerProperties.oidc;
const { idpID } = authServerProperties;

// Todo: Once refresh token is implemented, check if we can remove autoRemove
const oktaAuthConfig = {
  issuer,
  clientId,
  redirectUri,
  tokenManager: {
    autoRenew: true,
    autoRemove: false
  }
};

const DEFAULT_SIGN_IN_LABEL = "Sign in with Google";
const googleSocialAuthLabel =
  store.getState().whiteLabel.clientControlConfig?.oktaLogin
    ?.socialAuthGoogleLabel ?? DEFAULT_SIGN_IN_LABEL;

// authUtil
const oktaSignInConfig = {
  issuer,
  baseUrl: issuer.split("/oauth2")[0],
  clientId,
  redirectUri,
  idps: [{ type: "GOOGLE", id: idpID }],
  i18n: {
    en: {
      "socialauth.google.label": googleSocialAuthLabel
    }
  },
  authParams: {
    display,
    scopes: scope.split(" "),
    redirectUri
  },
  features: {
    rememberMe: false
  }
};

export const oktaAuth = new OktaAuth(oktaAuthConfig);

export { oktaAuthConfig, oktaSignInConfig };
