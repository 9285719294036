import { useSelector } from "react-redux";

import { getWhiteLabelConfig } from "../whiteLabelReducer";

/**
 * Hook to access the white label configuration.
 */
const useWhiteLabel = () => {
  const whiteLabel = useSelector(getWhiteLabelConfig);
  const { clientControlConfig } = whiteLabel;

  if (!whiteLabel.isLoaded || clientControlConfig === null) {
    throw new Error("White label configuration is not loaded yet");
  }

  return {
    ...whiteLabel,
    clientControlConfig
  };
};

export default useWhiteLabel;
