import styled from "@emotion/styled";

export const SmallScreenWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1200px) {
    position: fixed;
    background-color: ${({ theme }) =>
      theme.vars.palette.container.dynamic.neutral.subtle.background.default
        .regular};
    position: fixed;
    z-index: 999999999;
    height: 100%;
    width: 100%;
    display: block;
  }
`;

export const SmallScreenTextWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SmallScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SmallScreenTitle = styled.div`
  margin-top: 12px;
  text-align: center;
`;

export const SmallScreenSubtitle = styled.div`
  margin-top: 16px;
  text-align: center;
`;
