import axios from "axios";

import { labUserServiceProperties } from "src/config/config";

export const getAllMiqLabUsers = (accessToken: string) => {
  const url =
    `${labUserServiceProperties.labMiqBaseUrl}` +
    `${labUserServiceProperties.labMiqBasePath}/user/all`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const getMiqUserByEmailId = (emailId: string, accessToken: string) => {
  const url =
    `${labUserServiceProperties.labMiqBaseUrl}` +
    `${labUserServiceProperties.labMiqBasePath}/user?email=${emailId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
