import { useOktaAuth } from "@okta/okta-react";
import type { HelpHero } from "helphero";
import initHelpHero from "helphero";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { helpHeroAppId } from "src/config/config";
import { getUserDetails } from "src/redux/slices/userSlice/userReducer";
import { EXTERNAL_USER, INTERNAL_USER } from "src/utils/constants/user";
import type { UserClaimWithUid } from "src/utils/oktaConfig";

/**
 * Initializes HelpHero and sets the user attributes.
 * !IMPORTANT: This hook is meant to be a singleton and should be used only once in the application.
 *
 * @returns HelpHero instance
 */
export default function useHelpHero() {
  const { userDetails, googleAccInfo } = useSelector(getUserDetails);
  const { authState } = useOktaAuth();
  const [helpHero, setHelpHero] = useState<HelpHero | null>(null);

  const { isInternalUser, email } = userDetails || {};
  const { uid } = (authState?.accessToken?.claims ?? {}) as UserClaimWithUid;
  const identifier = uid || email;

  useEffect(() => {
    // If the user is not logged in, we cannot initialize HelpHero
    if (
      !identifier ||
      isInternalUser == null ||
      !helpHeroAppId ||
      !googleAccInfo
    )
      return;

    const helpHeroClient = initHelpHero(helpHeroAppId);
    helpHeroClient.identify(identifier, {
      type: isInternalUser ? INTERNAL_USER : EXTERNAL_USER,
      email,
      department: googleAccInfo.department,
      region: googleAccInfo.region
    });
    setHelpHero(helpHeroClient);
  }, [
    identifier,
    isInternalUser,
    email,
    googleAccInfo,
    googleAccInfo?.department,
    googleAccInfo?.region
  ]);

  return helpHero;
}
