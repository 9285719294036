import { type RouteObject } from "react-router-dom";

import Admin from "src/components/remoteModules/mfeApps/components/admin";
import { AccessRoute } from "src/routes/components/accessRoute";
import { ALL_CAPABILITIES } from "src/utils/constants/navigation";

import authLoader, { remoteLoaderWrapper } from "../utils/loader";

const route: RouteObject = {
  id: ALL_CAPABILITIES.admin.id,
  path: "/accounts/*",
  element: (
    <AccessRoute>
      <Admin />
    </AccessRoute>
  ),
  loader: authLoader(remoteLoaderWrapper(window._env_.admin, "admin", "Admin"))
};

export default route;
