import styled from "@emotion/styled";

export const LogoLayersStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  .solid-layers,
  .outline-layers {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .solid-layers {
    z-index: 1;
  }

  .outline-layers {
    z-index: 2;
  }

  .left-svg,
  .right-svg,
  .top-right-svg,
  .bottom-right-svg,
  .bottom-left-svg {
    position: absolute;
  }

  .right-svg {
    right: 0;
    bottom: 0;

    width: 1272.496826171875px;
    height: 1524.2548828125px;
    top: 7.46px;
    left: 977.27px;
    opacity: 0.6;
  }

  .left-svg {
    left: 5%;
    bottom: 0;

    width: 1272.496826171875px;
    height: 1524.2548828125px;
    top: 262.22px;
    left: 63.9px;
    opacity: 0.6;
  }

  .top-right-svg {
    right: 0;
    top: 0;

    width: 1265px;
    height: 1519.41650390625px;
    top: -1001.27px;
    left: 1346.47px;
    opacity: 1;
  }

  .bottom-right-svg {
    right: 0;
    bottom: 0;

    width: 1265px;
    height: 1519.41650390625px;
    top: 801.35px;
    left: 1007.38px;
    opacity: 1;
  }

  .bottom-left-svg {
    left: 0;
    bottom: 0;

    width: 1265px;
    height: 1519.41650390625px;
    top: 10.73px;
    left: -291px;
    opacity: 1;
  }
`;
