import { useTheme } from "@emotion/react";
import { CustomIcon, Typography } from "@miq/fiber-ui";

import {
  SmallScreenContent,
  SmallScreenSubtitle,
  SmallScreenTextWrapper,
  SmallScreenTitle,
  SmallScreenWrapper
} from "./styled";

const SmallScreenOverlay = () => {
  const theme = useTheme();
  return (
    <SmallScreenWrapper>
      <SmallScreenTextWrapper>
        <SmallScreenContent>
          <CustomIcon
            color={theme.vars.palette.surface.foreground.dynamic.error.default}
            iconName="SmileySad"
            size="48"
            weight="regular"
            data-testid="smiley-sad-icon"
          />
          <SmallScreenTitle>
            <Typography
              as="h1"
              fontWeight="medium"
              size="xl"
              variant="Headline"
            >
              This page requires a larger screen
              <br />
            </Typography>
          </SmallScreenTitle>
          <SmallScreenSubtitle>
            <Typography as="h1" fontWeight="regular" size="md" variant="Body">
              It looks like your browser isn’t fully extended or your screen
              isn’t large enough.
              <br />
              To give you the best possible experience please expand your
              browser or try a larger device.
            </Typography>
          </SmallScreenSubtitle>
        </SmallScreenContent>
      </SmallScreenTextWrapper>
    </SmallScreenWrapper>
  );
};

export default SmallScreenOverlay;
