import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { UserContextProvider } from "src/pages/campaigns/context";
import { setCookie } from "src/utils/storageUtil";

const Campaigns: React.FC = () => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (authState?.accessToken) {
      setCookie("token", authState.accessToken.accessToken);
    }
  }, [authState]);

  return (
    <UserContextProvider>
      <Outlet />
    </UserContextProvider>
  );
};

export default Campaigns;
