import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";
import { HUB_TERMS_ACCEPTED, PIXIE } from "src/utils/constants/user";
import { getFromLocalStorage, setInLocalStorage } from "src/utils/storageUtil";

import {
  fetchGoogleAccDetails,
  fetchUserDetails,
  toggleDemoMode
} from "./thunk";

export type User = {
  loginSessionId: string;
  email: string;
  userGeo: string;
  firstName: string;
  permissions: string[];
  termsAccepted: boolean;
  isInternalUser: boolean;
};

export type GoogleAccInfo = {
  id: string;
  uniquename: string;
  entityType: string;
  firstName: string;
  lastName: string;
  email: string;
  title?: string;
  thumbnailPhotoUrl?: string;
  department?: string;
  region?: string;
  status: string;
  groups: string[];
  isExternal: boolean;
};

interface UserState {
  userDetails: User | null;
  googleAccInfo: GoogleAccInfo | null;
  isPixieTheme: boolean;
}

const initialState: UserState = {
  userDetails: null,
  googleAccInfo: null,
  isPixieTheme: getFromLocalStorage(PIXIE) === "true"
};

/**
 * The slice for the user state.
 */
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    acceptTermsConditions: (state) => {
      if (state.userDetails) {
        state.userDetails.termsAccepted = true;
        setInLocalStorage(HUB_TERMS_ACCEPTED, "true");
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.userDetails = action.payload;
    });
    builder.addCase(fetchUserDetails.rejected, (state) => {
      state.userDetails = null;
      window.location.pathname = "/unauthorized";
    });
    builder.addCase(fetchGoogleAccDetails.fulfilled, (state, action) => {
      state.googleAccInfo = action.payload;
    });
    builder.addCase(fetchGoogleAccDetails.rejected, (state) => {
      state.googleAccInfo = null;
    });
    builder.addCase(toggleDemoMode.fulfilled, (state, action) => {
      if (!state.userDetails) return;

      state.userDetails = {
        ...state.userDetails,
        permissions: action.payload
      };
    });
  }
});

/**
 * Selector to get the user details from the state.
 */
export const getUserDetails = (state: RootState) => state.user;
export const { acceptTermsConditions } = userSlice.actions;
export default userSlice.reducer;
