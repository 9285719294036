import type {
  BrowsingDataAgg,
  BrowsingDataRaw,
  BuyingDataAgg,
  BuyingDataRaw,
  InsightsData,
  WatchingDataAgg,
  WatchingDataRaw
} from "../../../type";
import {
  BROWSING_AGGREGATE,
  BROWSING_RAW,
  BUYING_AGGREGATE,
  BUYING_RAW,
  WATCHING_AGGREGATE,
  WATCHING_RAW
} from "../data";

/**
 * Formats a number to a string.
 *
 * @param number Number to format
 * @returns formatted number string
 */
function formatNumber(number: number): string {
  if (number >= 10 ** 6) {
    return `${(number / 10 ** 6).toFixed(2)}m`;
  }
  return number.toLocaleString();
}

/**
 * Combines multiple arrays into a single array.
 * The combined array will have elements from each array in the order they are passed.
 *
 * @param arr Arrays to combine
 * @returns Combined array
 */
function combineArrays<T>(...arr: T[][]): T[] {
  const combinedArray: T[] = [];
  const maxLength = arr
    .map((item) => item.length)
    .reduce((a, b) => Math.max(a, b), 0);

  for (let i = 0; i < maxLength; i++) {
    for (const array of arr) {
      if (array[i]) {
        combinedArray.push(array[i]);
      }
    }
  }
  return combinedArray;
}

/**
 * Converts the first character of a string to uppercase and the rest to lowercase.
 *
 * @param str string to convert
 * @returns string with first character in uppercase and rest in lowercase
 */
function toProperCase(str: string): string {
  const modified = str.trim().replace(/_/g, " ");
  return modified.charAt(0).toUpperCase() + modified.slice(1).toLowerCase();
}

/**
 * Transforms the browsing aggregate data into insights data.
 *
 * @param arr data to transform
 * @returns insights data
 */
function formatBrowsingAggregateInsight(
  arr: BrowsingDataAgg[]
): Omit<InsightsData, "id">[] {
  return arr.map((item) => ({
    query: `${formatNumber(item.impressions)} HHs browsed ${item.category} on ${item.entity}`,
    browser: item.browser,
    behaviour: toProperCase(item.behaviour),
    timePart: item.dayPart,
    region: item.region,
    device: item.device
  }));
}

/**
 * Transforms the browsing raw data into insights data.
 *
 * @param arr data to transform
 * @returns insights data
 */
function formatBrowsingRawInsight(
  arr: BrowsingDataRaw[]
): Omit<InsightsData, "id">[] {
  return arr.map((item) => ({
    query: `${item.category} on ${item.entity}`,
    browser: item.browser,
    behaviour: toProperCase(item.behaviour),
    timePart: `${item.date} ${item.time}`,
    region: item.region,
    device: item.device
  }));
}

/**
 * Transforms the watching aggregate data into insights data.
 *
 * @param arr data to transform
 * @returns insights data
 */
function formatWatchingAggregateInsight(
  arr: WatchingDataAgg[]
): Omit<InsightsData, "id">[] {
  return arr.map((item) => ({
    query: `${formatNumber(item.reach)} HHs watched ${item.show} on ${item.entity}`,
    behaviour: toProperCase(item.behaviour),
    timePart: item.dayPart,
    region: item.region
  }));
}

/**
 * Transforms the watching raw data into insights data.
 *
 * @param arr data to transform
 * @returns insights data
 */
function formatWatchingRawInsight(
  arr: WatchingDataRaw[]
): Omit<InsightsData, "id">[] {
  return arr.map((item) => ({
    query: `${item.show} on ${item.entity}`,
    behaviour: toProperCase(item.behaviour),
    timePart: `${item.date} ${item.time}`,
    region: item.region
  }));
}

/**
 * Transforms the buying aggregate data into insights data.
 *
 * @param arr data to transform
 * @returns insights data
 */
function formatBuyingAggregateInsight(
  arr: BuyingDataAgg[]
): Omit<InsightsData, "id">[] {
  return arr.map((item) => ({
    // eslint-disable-next-line max-len
    query: `${formatNumber(item.transactions)} ${item.product} were bought in ${item.region}, ${item.country} at ${item.retailer}`,
    behaviour: toProperCase(item.behaviour),
    timePart: item.dayPart
  }));
}

/**
 * Transforms the buying raw data into insights data.
 *
 * @param arr data to transform
 * @returns insights data
 */
function formatBuyingRawInsight(
  arr: BuyingDataRaw[]
): Omit<InsightsData, "id">[] {
  return arr.map((item) => ({
    query: `${item.product} purchased at ${item.retailer}`,
    behaviour: toProperCase(item.behaviour),
    timePart: `${item.date} ${item.time}`,
    region: item.region
  }));
}

/**
 * Generates insights data from the raw and aggregate data.
 */
export function generateInsights() {
  return combineArrays(
    formatBrowsingAggregateInsight(BROWSING_AGGREGATE),
    formatWatchingRawInsight(WATCHING_RAW),
    formatBuyingAggregateInsight(BUYING_AGGREGATE),
    formatBrowsingRawInsight(BROWSING_RAW),
    formatWatchingAggregateInsight(WATCHING_AGGREGATE),
    formatBuyingRawInsight(BUYING_RAW)
  ).map((insight, index) => ({ ...insight, id: index }));
}
