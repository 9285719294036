import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useBeaconTracking = () => {
  const location = useLocation();
  useEffect(() => {
    // Trigger Beacon page-viewed event
    window.Beacon("event", {
      type: "page-viewed",
      url: document.location.href,
      title: document.title
    });

    // Trigger suggestion updates for Beacon
    window.Beacon("suggest");
  }, [location.pathname]); // Runs when the pathname changes
};

export default useBeaconTracking;
