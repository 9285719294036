import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { pageAccessReducer } from "./slices/pageAccess";
import { tsaReducer } from "./slices/tsaSlice";
import { userReducer } from "./slices/userSlice";
import { whiteLabelReducer } from "./slices/whitelabel";

const rootReducer = combineReducers({
  user: userReducer,
  whiteLabel: whiteLabelReducer,
  pageAccess: pageAccessReducer,
  tsa: tsaReducer
});

/**
 * The root state of the Redux store.
 */
type RootState = ReturnType<typeof rootReducer>;

/**
 * Setup the Redux store with the preloaded state.
 */
export function setupStore(preloadedState: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
}

/**
 * The Redux store for the application.
 */
export const store = setupStore({});

export type { RootState };
export type AppStore = typeof store;
/**
 * The dispatch function of the Redux store.
 */
export type AppDispatch = typeof store.dispatch;
