import { BaseStyles, ThemeProvider } from "@miq/fiber-ui";
import { ConfigCatProvider, PollingMode } from "configcat-react";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

import RootStyles from "./components/rootStyles";
import SmallScreenOverlay from "./components/smallScreenOverlay";
import { configCatSdkKey, mixpanelProperties } from "./config/config";
import { useWhiteLabel } from "./redux/slices/whitelabel";
import { router } from "./routes";
import { UNIFIED_PLATFORM_APP } from "./utils/constants/user";
import { setInLocalStorage } from "./utils/storageUtil";

const App = () => {
  const { primitives, theme } = useWhiteLabel();

  useEffect(() => {
    setInLocalStorage(UNIFIED_PLATFORM_APP, "true");
  }, []);

  useEffect(() => {
    if (mixpanelProperties.enabled && mixpanel !== undefined) {
      mixpanel.init(mixpanelProperties.token, {
        track_pageview: "url-with-path"
      });
    }
  }, []);

  return (
    <ConfigCatProvider
      sdkKey={configCatSdkKey}
      pollingMode={PollingMode.AutoPoll}
      options={{
        pollIntervalSeconds: 30
      }}
    >
      <HelmetProvider>
        <ThemeProvider primitives={primitives} variant={theme}>
          <BaseStyles />
          <RootStyles />
          <RouterProvider router={router} />
          <SmallScreenOverlay />
        </ThemeProvider>
      </HelmetProvider>
    </ConfigCatProvider>
  );
};

export default App;
