import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { ExtendWithTheme } from "src/types";

type OktaLoginWrapperProps = {
  activeIndex: number;
};

const oktaLoginWrapper = ({
  activeIndex,
  theme
}: ExtendWithTheme<OktaLoginWrapperProps>) => css`
  font-family: ${theme.font.fontFamily} !important;
  .redirection-cookie-dropper {
    display: none;
  }

  #okta-sign-in {
    border: none !important;
    margin: 0 !important;
    font-family: ${theme.font.fontFamily} !important;
    .auth-content {
      padding: 0 !important;
      .input-icon {
        display: none;
      }
      .input-tooltip {
        display: none;
      }
      .okta-form-label {
        display: none;
      }

      .o-form-input {
        ${activeIndex === 1 && `display: none`};
      }

      #okta-signin-username,
      #okta-signin-password,
      .o-form-input-name-oldPassword,
      .o-form-input-name-newPassword,
      .o-form-input-name-confirmPassword {
        box-sizing: border-box !important;
        height: 34px !important;
        border: 1px solid
          ${theme.vars.palette.border.dynamic.neutral.subtle.default} !important;
        border-radius: 2px !important;
        background-color: ${theme.vars.palette.container.static.miq.bold
          .foreground} !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .o-form-fieldset {
        ${activeIndex === 0 && `margin-bottom: 16px !important`};
      }
      .o-form-fieldset-container {
        ${activeIndex === 1 && `display: none`};
      }
      #okta-signin-submit,
      .social-auth-button {
        height: 34px;
        border-radius: 2px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 0;
      }
      .social-auth-button {
        background-color: ${theme.vars.palette.container.dynamic.brand.bold
          .background.default};
        padding-left: 0;
        text-align: center;
        font-weight: 500;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
      }
      .o-form-button-bar {
        padding-bottom: 0px;
        ${activeIndex === 1 && `display: none`};
      }
      .o-form-theme {
        padding: 0;
      }
      o-form-content {
        ${activeIndex === 0 && `display: none`};
      }
      .primary-auth-container {
        ${activeIndex === 0 && `display: none`};
      }
    }
  }

  #okta-sign-in .beacon-blank {
    background-color: ${theme.vars.palette.container.static.miq.bold
      .foreground};
  }

  #okta-sign-in a {
    line-height: 20px;
  }

  #okta-sign-in.auth-container .button-primary,
  #okta-sign-in.auth-container .button-primary:hover,
  #okta-sign-in.auth-container .button-primary:focus,
  #okta-sign-in.auth-container .button-primary:visited,
  #okta-sign-in.auth-container .button-primary:active {
    background: ${theme.vars.palette.container.dynamic.brand.bold.background
      .default};
    border-color: ${theme.vars.palette.border.static.bold.brand};
    box-shadow: none;
    height: 34px;
    line-height: 20px;
  }

  #okta-sign-in.auth-container .button.button-primary.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: ${theme.vars.palette.container.dynamic.success.bold.foreground
      .default};
    background-image: none;
    box-shadow: none;
  }

  #okta-sign-in.auth-container.main-container {
    overflow: hidden;
    background-color: ${theme.vars.palette.container.dynamic.success.bold
      .foreground.default};
    width: 413px;
    box-shadow: none;
  }

  #okta-sign-in.auth-container input[type="submit"],
  #okta-sign-in.auth-container input[type="button"] {
    background: ${theme.vars.palette.container.dynamic.brand.bold.background
      .default};
    font-weight: 600;
    line-height: 20px;
  }

  #okta-sign-in .o-form .o-form-input .input-icon + input,
  #okta-sign-in .o-form .o-form-input .input-icon + input + input,
  #okta-sign-in .o-form .o-form-input .okta-form-input-field input {
    &::placeholder {
      color: ${theme.vars.palette.container.dynamic.neutral.subtle.foreground
        .default.regular};
    }
    padding-left: 16px;
  }

  #okta-sign-in.auth-container .person-16-gray::before {
    color: ${theme.vars.palette.border.dynamic.neutral.subtle.default};
  }
  #okta-sign-in.auth-container .remote-lock-16::before {
    color: ${theme.vars.palette.border.dynamic.neutral.subtle.default};
  }
  #okta-sign-in.auth-container .form-help-16::before {
    color: ${theme.vars.palette.border.dynamic.neutral.subtle.default};
  }

  #okta-sign-in.auth-container .okta-form-input-field {
    background-color: ${theme.vars.palette.container.dynamic.success.bold
      .foreground.default};
    border-color: ${theme.vars.palette.border.dynamic.neutral.subtle.default};
  }

  #okta-sign-in.auth-container .okta-form-input-field input {
    font-family: ${theme.font.fontFamily};
  }

  #okta-sign-in .o-form .o-form-input .o-form-control,
  #okta-sign-in .o-form .o-form-input .o-form-control:hover,
  #okta-sign-in .o-form .o-form-input .o-form-control:active,
  #okta-sign-in .o-form .o-form-input .o-form-control.focused-input {
    background-color: ${theme.vars.palette.container.dynamic.success.bold
      .foreground.default};
    border: none;
    box-shadow: none;
  }

  #okta-sign-in .o-form-explain.o-form-input-error {
    color: ${theme.vars.palette.surface.foreground.static.error};
  }

  #okta-sign-in .error-16-red:before,
  #okta-sign-in .error-16-small:before {
    color: ${theme.vars.palette.surface.foreground.static.error};
  }
  #okta-sign-in .help-wrap .infobox.infobox-error:before,
  #okta-sign-in .infobox-error:before,
  #okta-sign-in .infobox.infobox-subtle.infobox-error:before {
    background-color: ${theme.vars.palette.container.static.error.bold
      .background};
  }

  .pt-4 {
    display: initial;
  }

  #okta-sign-in .social-auth-google-button,
  #okta-sign-in .social-auth-google-button:hover {
    background: ${theme.vars.palette.container.dynamic.brand.bold.background
      .default};
    color: ${theme.vars.palette.container.dynamic.brand.bold.foreground
      .default};
    line-height: 20px;
  }

  #okta-sign-in .auth-footer {
    display: none;
  }

  .okta-sign-in-header {
    display: none;
  }

  .okta-form-divider {
    display: none;
  }

  .okta-form-title {
    display: none;
  }

  //hiding ------OR------
  #okta-sign-in .auth-divider {
    display: none;
  }

  .footer-text {
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    visibility: hidden;
    a {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  //To hide Need help signing in? and Help
  .js-help {
    display: none !important;
  }
  //To prevent background color change on autocomplete in chrome
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .link-button {
    line-height: 20px !important;
  }
`;

export const OktaLoginWrapper = styled("div")<OktaLoginWrapperProps>`
  ${oktaLoginWrapper}
`;
