import type {
  BrowsingDataAgg,
  BrowsingDataRaw,
  BuyingDataAgg,
  BuyingDataRaw,
  WatchingDataAgg,
  WatchingDataRaw
} from "../../../type";

export const BROWSING_AGGREGATE: BrowsingDataAgg[] = [
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "CA, US",
    dayPart: "12pm - 3pm",
    category: "Movies",
    entity: "nypost.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 2060
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "CA, US",
    dayPart: "12pm - 3pm",
    category: "Business News",
    entity: "msn.com",
    device: "Computer",
    browser: "Chrome",
    impressions: 20943
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "York, GB",
    dayPart: "3am - 6am",
    category: "Music",
    entity: "independent.co.uk",
    device: "iPhone",
    browser: "Webkit",
    impressions: 9375
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "VA, US",
    dayPart: "6pm - 9pm",
    category: "Autos & Vehicles",
    entity: "cargurus.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 5373
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "TX, US",
    dayPart: "12pm - 3pm",
    category: "Baseball",
    entity: "mlb.com",
    device: "Computer",
    browser: "Chrome",
    impressions: 3380
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "Manchester, GB",
    dayPart: "9am - 12pm",
    category: "Football",
    entity: "football365.com",
    device: "Android",
    browser: "Chrome",
    impressions: 1545
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "CA, US",
    dayPart: "12am - 3am",
    category: "Beauty & Personal Care",
    entity: "forbes.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 2005
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "ON, CA",
    dayPart: "12pm - 3pm",
    category: "News",
    entity: "buzzfeed.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 1590
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "ME, US",
    dayPart: "6am - 9am",
    category: "Local News",
    entity: "pressherald.com",
    device: "Computer",
    browser: "Chrome",
    impressions: 1469
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "NSW, AU",
    dayPart: "12am - 3am",
    category: "Car Shopping",
    entity: "carsales.com.au",
    device: "Computer",
    browser: "Chrome",
    impressions: 1423
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "WA, AU",
    dayPart: "9am - 12pm",
    category: "Computer & Video Games",
    entity: "ign.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 993
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "VA, US",
    dayPart: "6pm - 9pm",
    category: "Autos & Vehicles",
    entity: "cargurus.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 5373
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "London, GB",
    dayPart: "3am - 6am",
    category: "World News",
    entity: "thesun.co.uk",
    device: "iPhone",
    browser: "Webkit",
    impressions: 18828
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "Kent, GB",
    dayPart: "3am - 6am",
    category: "Family & Relationships",
    entity: "netmums.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 4251
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "Glasgow, GB",
    dayPart: "12pm - 3pm",
    category: "Celebrity News",
    entity: "planetf1.com",
    device: "Android",
    browser: "Chrome",
    impressions: 1981
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "LEEDS, GB",
    dayPart: "3am - 6pm",
    category: "World News",
    entity: "telegraph.co.uk",
    device: "Computer",
    browser: "Webkit",
    impressions: 1006
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "ON, CA",
    dayPart: "12pm - 3pm",
    category: "Sports News",
    entity: "yardbarker.com",
    device: "Android",
    browser: "Chrome",
    impressions: 2313
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "ON, CA",
    dayPart: "9am - 12pm",
    category: "Business News",
    entity: "ca.finance.yahoo.com",
    device: "Computer",
    browser: "Chrome",
    impressions: 1288
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "NSW, AU",
    dayPart: "3pm - 6pm",
    category: "World News",
    entity: "theguardian.com",
    device: "iPhone",
    browser: "Webkit",
    impressions: 11874
  },
  {
    behaviour: "browsing",
    duration: "7 days",
    region: "NSW, AU",
    dayPart: "9pm - 12am",
    category: "Retirement",
    entity: "yourlifechoices.com.au",
    device: "Computer",
    browser: "Webkit",
    impressions: 2217
  }
];

export const BROWSING_RAW: BrowsingDataRaw[] = [
  {
    behaviour: "browsing",
    region: "ANS, GB",
    time: "8:32 pm",
    date: "24-03-2025",
    category: "Movies",
    entity: "dailymail.co.uk",
    device: "Android",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "IL, US",
    time: "7:45 am",
    date: "24-03-2025",
    category: "Lifestyles",
    entity: "msn.com",
    device: "iPhone",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "Cardiff, GB",
    time: "9:33 am",
    date: "24-03-2025",
    category: "Motor Sports",
    entity: "crash.net",
    device: "Computer",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "Derby, GB",
    time: "1:50 pm",
    date: "24-03-2025",
    category: "News",
    entity: "bigissue.com",
    device: "Android",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "HI, US",
    time: "6:34 pm",
    date: "24-03-2025",
    category: "News",
    entity: "usatoday.com",
    device: "Android",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "MB, CA",
    time: "10:25 am",
    date: "24-03-2025",
    category: "World News",
    entity: "theguardian.com",
    device: "Android",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "London, GB",
    time: "10:30 am",
    date: "24-03-2025",
    category: "Real Estate",
    entity: "zoopla.co.uk",
    device: "Computer",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "Kent, GB",
    time: "5:27 am",
    date: "24-03-2025",
    category: "Finance",
    entity: "mirror.co.uk",
    device: "iPhone",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "WY, US",
    time: "12:30 am",
    date: "24-03-2025",
    category: "Business & Industry",
    entity: "forbes.com",
    device: "iPhone",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "TAS, AU",
    time: "5:17 am",
    date: "24-03-2025",
    category: "Cooking & Recipes",
    entity: "allrecipes.com",
    device: "Android",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "NT, AU",
    time: "4:58 pm",
    date: "25-03-2025",
    category: "Movies",
    entity: "dailymail.co.uk",
    device: "iPhone",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "NB, CA",
    time: "2:46 am",
    date: "25-03-2025",
    category: "Hockey",
    entity: "hockeydb.com",
    device: "Computer",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "DE, US",
    time: "7:47 pm",
    date: "25-03-2025",
    category: "Finance",
    entity: "smartspender.org",
    device: "iPad",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "SC, US",
    time: "8:15 pm",
    date: "25-03-2025",
    category: "Local News",
    entity: "wcjb.com",
    device: "iPhone",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "NM, US",
    time: "4:26 am",
    date: "25-03-2025",
    category: "Home & Garden",
    entity: "hometalk.com",
    device: "Computer",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "ND, US",
    time: "3:16 pm",
    date: "25-03-2025",
    category: "Technology News",
    entity: "scitechdaily.com",
    device: "Android",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "MO, US",
    time: "3:01 pm",
    date: "25-03-2025",
    category: "Weather",
    entity: "accuweather.com",
    device: "Computer",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "DE, US",
    time: "7:47 pm",
    date: "25-03-2025",
    category: "Finance",
    entity: "smartspender.org",
    device: "iPad",
    browser: "Webkit"
  },
  {
    behaviour: "browsing",
    region: "NT, CA",
    time: "4:05 pm",
    date: "25-03-2025",
    category: "Tourist Destinations",
    entity: "newengland.com",
    device: "Computer",
    browser: "Chrome"
  },
  {
    behaviour: "browsing",
    region: "PE, CA",
    time: "9:28 am",
    date: "25-03-2025",
    category: "News",
    entity: "reuters.com",
    device: "Android",
    browser: "Chrome"
  }
];

export const WATCHING_AGGREGATE: WatchingDataAgg[] = [
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "AU",
    region: "QLD, AU ",
    dayPart: "6pm - 9pm",
    entity: "10play",
    show: "Friends",
    reach: 342174,
    impressions: 174432972
  },
  {
    behaviour: "Watching",
    duration: "180_days",
    country: "CA",
    region: "BC, CA",
    dayPart: "6pm - 9pm",
    entity: "CTV",
    show: "The Big Bang Theory",
    reach: 823977,
    impressions: 89552064
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "AU",
    region: "NSW, AU",
    dayPart: "6pm - 9pm",
    entity: "Seven",
    show: "Home and Away",
    reach: 489951,
    impressions: 62594112
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "AU",
    region: "NSW, AU",
    dayPart: "3pm - 6pm",
    entity: "Seven",
    show: "Seven News",
    reach: 1022678,
    impressions: 39103134
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "AU",
    region: "NSW, AU",
    dayPart: "6pm - 9pm",
    entity: "Channel9",
    show: "Married at First Sight",
    reach: 732606,
    impressions: 31622220
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "AU",
    region: "VA, AU",
    dayPart: "6pm - 9pm",
    entity: "Channel9",
    show: "Tipping Point Australia",
    reach: 726075,
    impressions: 2327934
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "AU",
    region: "NSW, AU",
    dayPart: "9am - 12pm",
    entity: "Seven",
    show: "The Morning Show",
    reach: 353262,
    impressions: 18793971
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "AU",
    region: "VIC, AU",
    dayPart: "6pm - 9pm",
    entity: "Seven",
    show: "Australian Idol",
    reach: 315567,
    impressions: 213843784
  },
  {
    behaviour: "Watching",
    duration: "180_days",
    country: "CA",
    region: "ON, CA",
    dayPart: "6pm - 9pm",
    entity: "Net-On",
    show: "NHL Hockey",
    reach: 1749102,
    impressions: 9409708
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "CA",
    region: "AL, CA",
    dayPart: "6pm - 9pm",
    entity: "Net-On",
    show: "NHL Hockey",
    reach: 534996,
    impressions: 95630128
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "CA",
    region: "ON, CA",
    dayPart: "6pm - 9pm",
    entity: "Chek",
    show: "Jeopardy!",
    reach: 240833,
    impressions: 8532385
  },
  {
    behaviour: "Watching",
    duration: "180_days",
    country: "CA",
    region: "ON, CA ",
    dayPart: "6pm - 9pm",
    entity: "CityTV",
    show: "Judy Justice",
    reach: 1132320,
    impressions: 6477420
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "CA",
    region: "ON, CA ",
    dayPart: "9pm - 12am",
    entity: "TSN",
    show: "SportsCentre",
    reach: 1090296,
    impressions: 24942972
  },
  {
    behaviour: "Watching",
    duration: "180_days",
    country: "CA",
    region: "ON, CA ",
    dayPart: "9pm - 12am",
    entity: "TSN",
    show: "NBA Basketball",
    reach: 1023087,
    impressions: 542301
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "UK",
    region: "Essex, UK",
    dayPart: "6pm - 9pm",
    entity: "ITV1",
    show: "ITV Evening News",
    reach: 313287,
    impressions: 27274587
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "UK",
    region: "Essex, UK",
    dayPart: "6pm - 9pm",
    entity: "ITV1",
    show: "Emmerdale",
    reach: 221444,
    impressions: 22936622
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "UK",
    region: "Kent, UK",
    dayPart: "6pm - 9pm",
    entity: "ITV1",
    show: "Good Morning Britain",
    reach: 135520,
    impressions: 16823136
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "UK",
    region: "Kent, UK",
    dayPart: "12pm - 3pm",
    entity: "ITVX",
    show: "Loose Women",
    reach: 20736,
    impressions: 160448
  },
  {
    behaviour: "Watching",
    duration: "90_days",
    country: "UK",
    region: "Hertfordshire, UK",
    dayPart: "9pm - 12am",
    entity: "ITVX",
    show: "Love Island: All Stars",
    reach: 21184,
    impressions: 172416
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "US",
    region: "CH, US",
    dayPart: "12pm - 3pm",
    entity: "FOX",
    show: "NFL Football",
    reach: 2079952,
    impressions: 10630802
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "US",
    region: "NY, US",
    dayPart: "6am - 9am",
    entity: "ABC",
    show: "Good Morning America",
    reach: 1356460,
    impressions: 9197776
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "US",
    region: "ATL, US",
    dayPart: "3pm - 6pm",
    entity: "ABC",
    show: "College Football",
    reach: 1413118,
    impressions: 5588954
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "US",
    region: "CH, US",
    dayPart: "9pm - 12am",
    entity: "ABC",
    show: "Jimmy Kimmel Live!",
    reach: 651896,
    impressions: 3418030
  },
  {
    behaviour: "Watching",
    duration: "30_days",
    country: "US",
    region: "PH, US",
    dayPart: "9pm - 12am",
    entity: "NBC",
    show: "NFL Football",
    reach: 1541190,
    impressions: 3234742
  }
];

export const WATCHING_RAW: WatchingDataRaw[] = [
  {
    behaviour: "Watching",
    country: "US",
    region: "CH, US",
    time: "9:49 am",
    date: "24-03-2025",
    entity: "YouTube TV",
    show: "Dr. Phil"
  },
  {
    behaviour: "Watching",
    country: "US",
    region: "MI, US",
    time: "8:27 pm",
    date: "24-03-2025",
    entity: "Peacock",
    show: "Charmed"
  },
  {
    behaviour: "Watching",
    country: "US",
    region: "BUF, US",
    time: "1:52 pm",
    date: "24-03-2025",
    entity: "YouTube TV",
    show: "Jeopardy!"
  },
  {
    behaviour: "Watching",
    country: "US",
    region: "KTVO, US",
    time: "5:34 pm",
    date: "24-03-2025",
    entity: "Tubi TV",
    show: "The Office"
  },
  {
    behaviour: "Watching",
    country: "US",
    region: "NY, US",
    time: "8:45 am",
    date: "24-03-2025",
    entity: "YouTube TV",
    show: "Wheel of Fortune"
  },
  {
    behaviour: "Watching",
    country: "AU",
    region: "VIC, AU",
    time: "10:55 am",
    date: "24-03-2025",
    entity: "Binge",
    show: "Chicago P.D."
  },
  {
    behaviour: "Watching",
    country: "AU",
    region: "QLD, AU ",
    time: "10:32 am",
    date: "24-03-2025",
    entity: "9now",
    show: "Seinfeld"
  },
  {
    behaviour: "Watching",
    country: "AU",
    region: "QLD, AU ",
    time: "5:47 am",
    date: "24-03-2025",
    entity: "10play",
    show: "Gogglebox Australia"
  },
  {
    behaviour: "Watching",
    country: "AU",
    region: "VA, AU",
    time: "12:33 am",
    date: "24-03-2025",
    entity: "Binge",
    show: "Jurassic World: Fallen Kingdom"
  },
  {
    behaviour: "Watching",
    country: "AU",
    region: "NSW, AU",
    time: "5:47 am",
    date: "24-03-2025",
    entity: "Binge",
    show: "Below Deck Mediterranean"
  },
  {
    behaviour: "Watching",
    country: "CA",
    region: "MB, CA",
    time: "3:28 am",
    date: "25-03-2025",
    entity: "CityTV",
    show: "The Price Is Right"
  },
  {
    behaviour: "Watching",
    country: "CA",
    region: "BC, CA",
    time: "1:20 pm",
    date: "25-03-2025",
    entity: "CTV",
    show: "Young Sheldon"
  },
  {
    behaviour: "Watching",
    country: "CA",
    region: "QC, CA",
    time: "11:37 am",
    date: "25-03-2025",
    entity: "CMT",
    show: "Frasier"
  },
  {
    behaviour: "Watching",
    country: "CA",
    region: "AB, CA",
    time: "10:33 am",
    date: "25-03-2025",
    entity: "CTV",
    show: "Hell's Kitchen"
  },
  {
    behaviour: "Watching",
    country: "CA",
    region: "BC, CA",
    time: "11:47 pm",
    date: "25-03-2025",
    entity: "CTV",
    show: "Late Night with Seth Meyers"
  },
  {
    behaviour: "Watching",
    country: "UK",
    region: "Sunderland, uk",
    time: "1:25 pm",
    date: "25-03-2025",
    entity: "BBC1",
    show: "Homes Under the Hammer"
  },
  {
    behaviour: "Watching",
    country: "UK",
    region: "Sunderland, uk",
    time: "2:30 pm",
    date: "25-03-2025",
    entity: "BBC1",
    show: "Antiques Road Trip"
  },
  {
    behaviour: "Watching",
    country: "UK",
    region: "Leicester, UK ",
    time: "7:30 pm",
    date: "25-03-2025",
    entity: "Food",
    show: "The Great British Bake Off"
  },
  {
    behaviour: "Watching",
    country: "UK",
    region: "Birmingham, UK",
    time: "6:31 pm",
    date: "25-03-2025",
    entity: "E4",
    show: "The Big Bang Theory"
  },
  {
    behaviour: "Watching",
    country: "UK",
    region: "Edinburgh, UK",
    time: "7:52 pm",
    date: "25-03-2025",
    entity: "My4- E4",
    show: "The Simpsons"
  }
];

export const BUYING_AGGREGATE: BuyingDataAgg[] = [
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Attlanta, GA",
    state: "Georgia",
    dayPart: "3pm - 6pm",
    retailer: "Kroger",
    product: "Coca-Cola Classics",
    reach: 668,
    transactions: 993
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Columbus, OH",
    state: "Ohio",
    dayPart: "3pm - 6pm",
    retailer: "Kroger",
    product: "Pepsis",
    reach: 229,
    transactions: 351
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Detroit, MI ",
    state: "Michigan",
    dayPart: "3pm - 6pm",
    retailer: "Kroger",
    product: "Red Bull Energy Drinks",
    reach: 117,
    transactions: 148
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Atlanta, GA",
    state: "Georgia",
    dayPart: "9am - 12pm",
    retailer: "Kroger",
    product: "Minute Maid Fruit Punchs",
    reach: 106,
    transactions: 124
  },
  {
    behaviour: "Buying",
    duration: "60_days",
    country: "US",
    region: "Los Angeles, CA",
    state: "California",
    dayPart: "9am - 12pm",
    retailer: "Costco",
    product: "Skinny Pop Organic Popcorn",
    reach: 108,
    transactions: 126
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Chicago, IL ",
    state: "Illinois",
    dayPart: "6am - 9am",
    retailer: "Albertsons",
    product: "Coors Light Beer (24pk)",
    reach: 41,
    transactions: 80
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Los Angeles, CA",
    state: "California",
    dayPart: "6am - 9am",
    retailer: "Costco",
    product: "Skinny Pop Organic Popcorn",
    reach: 69,
    transactions: 84
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Los Angeles, CA",
    state: "California",
    dayPart: "3pm - 6pm",
    retailer: "Kroger",
    product: "Kettle Brand Sea Salt Potato Chips",
    reach: 31,
    transactions: 37
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Dallas-Ft. Worth, TX",
    state: "Texas",
    dayPart: "3pm - 6pm",
    retailer: "Target",
    product: "Airpods Pro (2nd Gen)",
    reach: 21,
    transactions: 21
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Detroit, MI ",
    state: "Michigan",
    dayPart: "9am - 12pm",
    retailer: "Kroger",
    product: "Minute Maid Lemonades",
    reach: 44,
    transactions: 50
  },
  {
    behaviour: "Buying",
    duration: "90_days",
    country: "US",
    region: "Houston, TX",
    state: "Texas",
    dayPart: "3pm - 6pm",
    retailer: "Walmart",
    product: "Coca-Colas",
    reach: 312,
    transactions: 426
  }
];

export const BUYING_RAW: BuyingDataRaw[] = [
  {
    behaviour: "Buying",
    country: "US",
    region: "Wausau-Rhinelander, WI",
    state: "Wisconsin",
    date: "25-03-2025",
    time: "4:31 pm",
    retailer: "Walmart",
    product: "Samsung remote controls"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Phoenix, AZ",
    state: "Arizona",
    date: "25-03-2025",
    time: "5:14 pm",
    retailer: "Albertsons",
    product: "Glenfiddich Scotch"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Sarasota, FL ",
    state: "Florida",
    date: "24-03-2025",
    time: "11:23 am",
    retailer: "CVS",
    product: "Olaplex 8.5fl Mask"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Phoenix, AZ",
    state: "Arizona",
    date: "24-03-2025",
    time: "10:53 am",
    retailer: "Kroger",
    product: "Gallo Vermouth"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Honolulu, HW",
    state: "Hawaii",
    date: "24-03-2025",
    time: "11:13 am",
    retailer: "Petco",
    product: "Freshpet Vital - Complete meals for dogs"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Dayton, OH",
    state: "Ohio",
    date: "24-03-2025",
    time: "1:25 pm",
    retailer: "Lowe's",
    product: "Samsung water filters"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Yuma-El Centro, AZ",
    state: "Arizona",
    date: "25-03-2025",
    time: "1:30 pm",
    retailer: "Target",
    product: "Apple Airtag (1pck)"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Jacksonville, GA",
    state: "Georgia",
    date: "25-03-2025",
    time: "3:20 pm",
    retailer: "Kroger",
    product: "Coors Light"
  },
  {
    behaviour: "Buying",
    country: "US",
    region: "Ft. Wayne, IN",
    state: "Indiana",
    date: "25-03-2025",
    time: "1:15 pm",
    retailer: "Kroger",
    product: "Minute Maid Lemonade"
  }
];
