import React from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { sendMailToResetPassword } from "../../util";

type EmailRequest = {
  userEmail?: string;
  [key: string]: any;
};

type ReCaptchaProps = {
  emailRequest: EmailRequest;
  setIsCaptchaEnabled: (value: boolean) => void;
  isCaptchaEnabled: boolean;
  onBlankInput: () => void;
  onFormSubmitSuccess: () => void;
  onFormSubmitError: (errorMessage: string) => void;
};

const ReCaptcha: React.FC<ReCaptchaProps> = ({
  emailRequest,
  setIsCaptchaEnabled,
  isCaptchaEnabled,
  onBlankInput,
  onFormSubmitSuccess,
  onFormSubmitError
}) => {
  const onVerifyHandler = async (token: string) => {
    const { userEmail } = emailRequest;

    if (!userEmail) {
      onBlankInput();
      setIsCaptchaEnabled(false);
      return;
    }

    if (!userEmail.includes("@")) {
      onFormSubmitError("Please enter a valid email address.");
      return;
    }

    const request = { ...emailRequest, captchaToken: token };

    try {
      await sendMailToResetPassword(request);
      onFormSubmitSuccess();
    } catch (error: any) {
      const errorObj = error.response ? error.response.data : null;
      const errorStatus = error.response ? error.response.status : null;
      if (errorObj?.message && errorStatus !== 401) {
        onFormSubmitError(errorObj.message);
      } else {
        onFormSubmitError("Something went wrong. Please try again.");
      }
    } finally {
      setIsCaptchaEnabled(false);
    }
  };

  return (
    <>{isCaptchaEnabled && <GoogleReCaptcha onVerify={onVerifyHandler} />}</>
  );
};

export default ReCaptcha;
