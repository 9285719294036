import styled from "@emotion/styled";

const LoaderWrapperStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export default LoaderWrapperStyled;
