import { Button, Typography } from "@miq/fiber-ui";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import loginMiq from "src/assets/images/login/loginMiq.svg";
import { ReactComponent as MiqFooterSvg } from "src/assets/images/miqFooterSvg.svg";
import { useWhiteLabel } from "src/redux/slices/whitelabel";

import RequestAccess from "../login/components/requestAccess";
import {
  REQUEST_ACCESS_FORM,
  UNAUTHORIZED_PAGE,
  type FormType
} from "../login/constants/loginConstants";

import {
  CardFooter,
  CardWrapper,
  FooterStyled,
  Logo,
  CardContent,
  CardText,
  Title
} from "./styled";

export default function Unauthorized() {
  const [currentForm, setCurrentForm] = useState<FormType>(UNAUTHORIZED_PAGE);
  const {
    clientControlConfig: { showPoweredByMiQLogo }
  } = useWhiteLabel();

  function changeForm(formType: FormType) {
    setCurrentForm(formType);
  }

  const navigate = useNavigate();

  return (
    <>
      {_.isEqual(currentForm, UNAUTHORIZED_PAGE) ? (
        <CardWrapper>
          <CardContent>
            <CardText>
              <div>
                <Logo src={loginMiq} alt="Miq-Logo" />
              </div>
              <Title as="h1" fontWeight="semibold" size="md" variant="Headline">
                Unauthorized!
              </Title>
              <Typography as="h1" fontWeight="medium" size="lg" variant="Body">
                Sorry. You are not authorized to view this page.
              </Typography>
            </CardText>
            <CardFooter>
              <Button
                as="a"
                primary
                onClick={() => changeForm(REQUEST_ACCESS_FORM)}
              >
                <Typography variant="Body" size="md" fontWeight="semibold">
                  Request Access
                </Typography>
              </Button>
              <Button as="a" primary onClick={() => navigate("/logout")}>
                <Typography variant="Body" size="md" fontWeight="semibold">
                  Login as a different user
                </Typography>
              </Button>
            </CardFooter>
          </CardContent>
          {showPoweredByMiQLogo && (
            <FooterStyled>
              <MiqFooterSvg className="footer-logo" />
            </FooterStyled>
          )}
        </CardWrapper>
      ) : (
        <CardWrapper>
          <CardContent>
            <div>
              <Logo src={loginMiq} alt="Miq-Logo" />
            </div>
            <Title as="h1" fontWeight="semibold" size="md" variant="Headline">
              Request Access
            </Title>
            <RequestAccess />
          </CardContent>
          {showPoweredByMiQLogo && (
            <FooterStyled>
              <MiqFooterSvg className="footer-logo" />
            </FooterStyled>
          )}
        </CardWrapper>
      )}
    </>
  );
}
