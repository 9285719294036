import { Button, Checkbox, Typography } from "@miq/fiber-ui";

import { AcceptConditionsHolder, AcceptConditionsText, Footer } from "./styled";

type Props = {
  onAcceptTerms: () => void;
  termsAccepted: boolean;
  toggleCheckBox: () => void;
};

/**
 * AcceptTerms component.
 */
export default function AcceptTerms({
  onAcceptTerms,
  termsAccepted,
  toggleCheckBox
}: Readonly<Props>) {
  return (
    <Footer className="terms-conditions-footer">
      <AcceptConditionsHolder className="accept-conditions-holder">
        <AcceptConditionsText className="accept-conditions-text">
          <Checkbox
            id="accept-conditions-checkbox"
            className="accept-conditions-checkbox"
            checked={termsAccepted}
            onChange={toggleCheckBox}
          >
            <Typography variant="Body" size="md" fontWeight="regular" as="span">
              I have read and agree with terms and conditions
            </Typography>
          </Checkbox>
          <Typography variant="Body" size="md" fontWeight="medium" as="span">
            <Button
              className="accept-conditions-button"
              primary
              disabled={!termsAccepted}
              onClick={() => onAcceptTerms()}
            >
              Continue to the app
            </Button>
          </Typography>
        </AcceptConditionsText>
      </AcceptConditionsHolder>
    </Footer>
  );
}
