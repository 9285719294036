import styled from "@emotion/styled";

export const AnimatedCloudStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;

  svg {
    fill: linear-gradient(180deg, #ff7e00 0%, #d0009c 100%);
    filter: blur(100px);
  }

  .spot-1 {
    --sway-start-x: 10%;
    --sway-start-y: 20%;

    --sway-end-x: 70%;
    --sway-end-y: 25%;

    --sway-duration: 20s;
  }

  .spot-2 {
    --sway-start-x: 70%;
    --sway-start-y: 80%;

    --sway-end-x: 15%;
    --sway-end-y: 100%;

    --sway-duration: 20s;
    transform: rotate(104.5deg);
  }

  .sway {
    position: absolute;
    transform: translate(-50%, -50%);
    left: var(--sway-start-x);
    top: var(--sway-start-y);
    animation: sway-keyframes var(--sway-duration, 20s) infinite linear;
  }

  @keyframes sway-keyframes {
    50% {
      left: var(--sway-end-x);
      top: var(--sway-end-y);
    }
  }
`;
