import { useTheme } from "@emotion/react";
import {
  Avatar,
  Shell,
  GearIcon,
  HandshakeIcon,
  HeartIcon,
  SignOutIcon,
  Toggle,
  Badge
} from "@miq/fiber-ui";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { TsaHeaderNavigationItem } from "src/components/remoteModules/mfeApps/components/tsa/TsaHeaderNavigationItem";
import WhiteLabelRestricted from "src/components/whiteLabelRestricted";
import { partnershipsUrl } from "src/config/config";
import useMixpanel from "src/hooks/useMixpanel";
import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import type { AppDispatch } from "src/redux";
import { toggleDemoMode, useUserDetails } from "src/redux/slices/userSlice";
import { useWhiteLabel } from "src/redux/slices/whitelabel";
import { setTheme } from "src/redux/slices/whitelabel/whiteLabelReducer";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";
import {
  BROWSE_ALL_SEARCHES,
  HEADER_NAV_CLICK
} from "src/utils/constants/mixpanelConstants";
import {
  ALL_CAPABILITIES,
  type NavigationItem
} from "src/utils/constants/navigation";
import { USER_PERMISSIONS } from "src/utils/constants/user";

import GlobalBanner from "../GlobalBanner";

import SubNav from "./components/SubNav";
import useDynamicHeaderHeight from "./hooks/useDynamicHeaderHeight";
import { BadgeStyled, BadgeWrapper } from "./styled";

type Props = {
  navigationItems: NavigationItem[];
  onNavigationItemClick: (link: string) => void;
  selectedTab: NavigationItem | null;
  selectedSubNav: NavigationItem | null;
};

/**
 * Main header component for the application.
 */
const HeaderShell: React.FC<Props> = ({
  navigationItems,
  onNavigationItemClick,
  selectedTab,
  selectedSubNav
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { userDetails, googleAccInfo, isDemoMode } = useUserDetails();
  const {
    isWhiteLabel,
    headerLogo,
    clientControlConfig,
    theme: variant
  } = useWhiteLabel();
  const theme = useTheme();
  const navigate = useNavigate();
  const resizeCallbackRef = useDynamicHeaderHeight();
  const { trackEvent } = useMixpanel();

  const { value: showHeroFeatures } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.heroFeatures.SHOW_HERO_FEATURES,
    false
  );

  const {
    title: designation = "",
    firstName,
    lastName,
    thumbnailPhotoUrl
  } = googleAccInfo || {};
  const { permissions: userPermissions, termsAccepted } = userDetails;
  const fullName = getFullName();

  /**
   * Show beta badge on beta environment.
   * TODO: Remove after navigation beta.
   */
  const showBetaBadge = window.location.hostname.includes("beta");

  /**
   * Get the full name of the user.
   */
  function getFullName() {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    return firstName || userDetails?.firstName || "";
  }

  /**
   * Check if the user has the permission.
   */
  function isPermitted(permission: string): boolean {
    return userPermissions?.includes(permission) ?? false;
  }

  /**
   * Render the navigation items.
   */
  function renderNavigation() {
    return navigationItems.map((navItem) => renderNavItem(navItem));
  }

  /**
   * Handle the theme toggle.
   */
  function handleThemeToggle() {
    const newTheme = variant === "dark" ? "light" : "dark";
    dispatch(setTheme({ theme: newTheme }));
  }

  /**
   * Handle the saved searches click.
   */
  function handleSavedSearchesClick() {
    trackEvent(BROWSE_ALL_SEARCHES);
    navigate(
      `${ALL_CAPABILITIES.intelligenceContext.navigationLink}/searches/recent`
    );
  }

  /**
   * Tracks the tab click
   *
   * @param tab Tab name
   */
  function trackTabClick(tab: string) {
    const event = `${tab.toLowerCase()}${HEADER_NAV_CLICK}`;
    trackEvent(event);
  }

  /**
   * Render the navigation item.
   */
  function renderNavItem(navItem: NavigationItem) {
    const isActive = navItem.id === selectedTab?.id;

    /**
     * Handle the tab click.
     */
    function handleTabClick() {
      trackTabClick(navItem.title);
      onNavigationItemClick(navItem.navigationLink);
    }

    return (
      <Shell.Nav.NavItem
        key={navItem.id}
        className="header-nav-link"
        onClick={handleTabClick}
        active={isActive}
        data-testid={`nav-item-${navItem.title.toLowerCase()}`}
      >
        <div className={classNames({ "active-tab": isActive })}>
          {navItem.title}
        </div>
      </Shell.Nav.NavItem>
    );
  }

  /**
   * Renders the user info.
   */
  function renderUserInfo() {
    return (
      <Shell.UserInfo
        rightSpace={<TsaHeaderNavigationItem />}
        isUnified
        menuOptions={
          termsAccepted && (
            <>
              <Shell.UnifiedUserMenu.User
                userName={fullName}
                designation={designation}
                image={thumbnailPhotoUrl}
              />
              <>
                <Shell.UnifiedUserMenu.Section>
                  {isPermitted(USER_PERMISSIONS.INTERNAL_ACCESS) && (
                    <Shell.UnifiedUserMenu.Item
                      onClick={() => {
                        dispatch(toggleDemoMode());
                      }}
                      toggle={
                        <Toggle
                          data-testid="demo-mode-toggle"
                          checked={isDemoMode}
                          type="compact"
                          disabled={false}
                        />
                      }
                    >
                      Demo mode
                    </Shell.UnifiedUserMenu.Item>
                  )}
                  {showHeroFeatures && (
                    <WhiteLabelRestricted>
                      <Shell.UnifiedUserMenu.Item
                        onClick={handleThemeToggle}
                        toggle={
                          <Toggle
                            data-testid="theme-toggle"
                            checked={variant === "dark"}
                            type="compact"
                            disabled={false}
                          />
                        }
                      >
                        Dark mode
                      </Shell.UnifiedUserMenu.Item>
                    </WhiteLabelRestricted>
                  )}
                </Shell.UnifiedUserMenu.Section>
                <Shell.UnifiedUserMenu.Separator />
                <Shell.UnifiedUserMenu.Section>
                  {isPermitted(USER_PERMISSIONS.INTERNAL_ACCESS) &&
                    isPermitted(USER_PERMISSIONS.USER_MANAGEMENT_ACCESS) && (
                      <Shell.UnifiedUserMenu.Item
                        onClick={() => navigate("/accounts")}
                        icon={
                          <GearIcon
                            weight="regular"
                            size="24"
                            color={
                              theme.vars.palette.container.dynamic.neutral
                                .subtle.foreground.hover
                            }
                            aria-hidden
                          />
                        }
                      >
                        Settings
                      </Shell.UnifiedUserMenu.Item>
                    )}
                  {isPermitted(USER_PERMISSIONS.DISCOVER_ACCESS) && (
                    <Shell.UnifiedUserMenu.Item
                      onClick={handleSavedSearchesClick}
                      icon={
                        <HeartIcon
                          weight="regular"
                          size="24"
                          color={
                            theme.vars.palette.container.dynamic.neutral.subtle
                              .foreground.hover
                          }
                          aria-hidden
                        />
                      }
                    >
                      Pixels and searches
                    </Shell.UnifiedUserMenu.Item>
                  )}
                  <Shell.UnifiedUserMenu.Item
                    onClick={() => window.open(partnershipsUrl)}
                    icon={
                      <HandshakeIcon
                        color={
                          theme.vars.palette.container.dynamic.neutral.subtle
                            .foreground.hover
                        }
                        aria-hidden
                      />
                    }
                  >
                    Partnerships
                  </Shell.UnifiedUserMenu.Item>
                </Shell.UnifiedUserMenu.Section>
                <Shell.UnifiedUserMenu.Separator />
              </>
              {clientControlConfig.showTermsAndConditions && (
                <>
                  <Shell.UnifiedUserMenu.Section>
                    <Shell.UnifiedUserMenu.Item
                      onClick={() =>
                        navigate("/legal-terms/terms-and-conditions")
                      }
                    >
                      Terms & policies
                    </Shell.UnifiedUserMenu.Item>
                  </Shell.UnifiedUserMenu.Section>
                  <Shell.UnifiedUserMenu.Separator />
                </>
              )}
              <Shell.UnifiedUserMenu.Section>
                <Shell.UnifiedUserMenu.Item
                  onClick={() => navigate("/logout")}
                  data-testid="signout-item"
                  icon={
                    <SignOutIcon
                      aria-hidden
                      color={
                        theme.vars.palette.container.dynamic.neutral.subtle
                          .foreground.default.regular
                      }
                    />
                  }
                >
                  Sign Out
                </Shell.UnifiedUserMenu.Item>
              </Shell.UnifiedUserMenu.Section>
            </>
          )
        }
        showAvatar
      >
        <Avatar name={fullName} image={thumbnailPhotoUrl} />
      </Shell.UserInfo>
    );
  }

  return (
    <Shell
      className="primary-navigation-bar centered-navbar"
      // Adding key to force re-rendering of the component when the navigation items change.
      key={navigationItems.map((item) => item.title).join()}
      id="primary-nav-bar"
    >
      <div
        ref={resizeCallbackRef}
        data-testid="app-header-wrapper"
        id="navShellHeader"
      >
        <GlobalBanner />
        <Shell.Header
          className="unified-header-wrapper"
          animation={!isWhiteLabel}
        >
          <Shell.Header.HeaderImage>
            <Link to="/">
              <img alt="logo" className="aiq-logo" src={headerLogo} />
            </Link>
            <BadgeWrapper>
              {/* TODO: Remove after navigation beta */}
              {showBetaBadge && (
                <BadgeStyled
                  className="beta-badge"
                  small
                  showIcon={false}
                  variant="neutral"
                >
                  Beta
                </BadgeStyled>
              )}
              {isDemoMode && (
                <Badge data-testid="demo-mode-badge" variant="info" isCompact>
                  Demo mode
                </Badge>
              )}
            </BadgeWrapper>
          </Shell.Header.HeaderImage>
          <Shell.Nav
            activeLinkColor={!isWhiteLabel && theme.colors.miqGradientColor}
          >
            {renderNavigation()}
          </Shell.Nav>
          {renderUserInfo()}
        </Shell.Header>
        {selectedTab?.children && selectedTab.children.length > 0 && (
          <SubNav
            navItems={selectedTab.children}
            onSubNavClick={onNavigationItemClick}
            selectedTab={selectedSubNav}
          />
        )}
      </div>
    </Shell>
  );
};

export default HeaderShell;
