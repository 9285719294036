import { ArrowLeftIcon, Col, Row, Typography } from "@miq/fiber-ui";
import { useEffect } from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";

import { useUserDetails } from "src/redux/slices/userSlice";
import { useWhiteLabel } from "src/redux/slices/whitelabel";

import {
  AmendedTextStyled,
  BackLinkColStyled,
  BackLinkStyled,
  HeaderGridStyled,
  HeaderTextWrapperStyled
} from "./styled";

const PAGE_LABELS = {
  terms: "Terms and conditions",
  privacy: "Privacy policy"
};

/**
 * Wrapper component for the legal terms pages.
 */
export default function LegalTermsWrapper() {
  const navigate = useNavigate();
  const matches = useMatches();
  const { userDetails } = useUserDetails();
  const {
    clientControlConfig: { showTermsAndConditions }
  } = useWhiteLabel();

  const isTermsPage = !!matches.find(
    (match) => match.id === "terms-and-conditions"
  );
  const { termsAccepted } = userDetails;

  const pageTitle = isTermsPage ? PAGE_LABELS.terms : PAGE_LABELS.privacy;
  const lastAmendedDate = "7 June 2021";

  /**
   * Redirect the user to the home page if T&C page is not enabled.
   */
  useEffect(() => {
    if (!showTermsAndConditions) navigate("/");
  }, [navigate, showTermsAndConditions]);

  return (
    <>
      <HeaderGridStyled gutter="24">
        <Row justify="space-between">
          <Col span="10">
            <HeaderTextWrapperStyled>
              <Typography
                as="h1"
                variant="Headline"
                size="xl"
                fontWeight="semibold"
              >
                {pageTitle}
              </Typography>
              {/**
               * Show the last amended date only for the 'Terms of Use' page.
               */}
              {isTermsPage && (
                <AmendedTextStyled
                  as="p"
                  variant="Body"
                  size="sm"
                  fontWeight="medium"
                >
                  Last Amended: {lastAmendedDate}
                </AmendedTextStyled>
              )}
            </HeaderTextWrapperStyled>
          </Col>
          {termsAccepted && (
            <BackLinkColStyled span="2">
              <BackLinkStyled to="/">
                <ArrowLeftIcon
                  weight="regular"
                  size="24"
                  color="var(--fiber-colors-surface-foreground-dynamic-brand-default)"
                />
                <Typography variant="Body" size="md" fontWeight="medium">
                  Go back to apps
                </Typography>
              </BackLinkStyled>
            </BackLinkColStyled>
          )}
        </Row>
      </HeaderGridStyled>
      <Outlet />
    </>
  );
}
