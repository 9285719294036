import type { PropsWithChildren } from "react";

import { useWhiteLabel } from "src/redux/slices/whitelabel";

/**
 * Hides children if the user is a whitelabel user.
 */
export default function WhiteLabelRestricted({ children }: PropsWithChildren) {
  const { isWhiteLabel } = useWhiteLabel();

  if (isWhiteLabel) {
    return null;
  }

  return <>{children}</>;
}
