import { ErrorBoundary } from "react-error-boundary";
import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Fallback from "src/components/fallback";
import Bookings from "src/components/remoteModules/mfeApps/components/bookings";
import CampaignsApp from "src/components/remoteModules/mfeApps/components/campaign";
import Resources from "src/components/remoteModules/mfeApps/components/resources";
import Setup from "src/components/remoteModules/mfeApps/components/setup";
import Campaigns from "src/pages/campaigns/Campaigns";
import { AccessRoute } from "src/routes/components/accessRoute";
import { ALL_CAPABILITIES } from "src/utils/constants/navigation";

import authLoader, { remoteLoaderWrapper } from "../utils/loader";

const route: RouteObject = {
  id: ALL_CAPABILITIES.campaigns.id,
  path: "/campaigns",
  element: (
    <AccessRoute>
      <ErrorBoundary FallbackComponent={Fallback}>
        <Campaigns />
      </ErrorBoundary>
    </AccessRoute>
  ),
  // TODO: Move all routes to NavigationConfig.
  children: [
    {
      id: ALL_CAPABILITIES.campaignsOverview.id,
      index: true,
      element: (
        <AccessRoute>
          <CampaignsApp />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(
          window._env_.lab_campaigns,
          "lab_campaigns",
          "CampaignsApp"
        )
      )
    },
    {
      path: "details/*",
      element: (
        <AccessRoute>
          <CampaignsApp />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(
          window._env_.lab_campaigns,
          "lab_campaigns",
          "CampaignsApp"
        )
      )
    },
    {
      id: ALL_CAPABILITIES.campaignsBookings.id,
      path: "bookings/*",
      element: (
        <AccessRoute>
          <Bookings />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(window._env_.bookings, "bookings", "BookingsApp")
      )
    },
    {
      id: ALL_CAPABILITIES.campaignsSetup.id,
      path: "setup/*",
      element: (
        <AccessRoute>
          <Setup />
        </AccessRoute>
      ),

      loader: authLoader(remoteLoaderWrapper(window._env_.acs, "acs", "AcsApp"))
    },
    {
      id: ALL_CAPABILITIES.campaignsResources.id,
      path: "resources/*",
      element: (
        <AccessRoute>
          <Resources />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(
          window._env_.advertisers,
          "advertisers",
          "AdvertisersApp"
        )
      )
    },
    {
      path: "*",
      element: <Navigate to="/campaigns/overview" />
    }
  ]
};

export default route;
