import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";
import type {
  FeatureAccess,
  CapabilityAccess,
  NavigationItem
} from "src/utils/constants/navigation";
import { NAVIGATION_ITEMS } from "src/utils/constants/navigation";

export type PageAccessState = {
  capabilities: CapabilityAccess[] | null;
  navigation: NavigationItem[] | null;
};

const initialState: PageAccessState = {
  capabilities: null,
  navigation: null
};

/**
 * Check if the user has access to the feature.
 */
function checkAccess(permissions: string[], access: FeatureAccess): boolean {
  const isRestricted = access.noneOf
    ? access.noneOf.some((permission) => permissions.includes(permission))
    : false;

  if ("anyOf" in access) {
    return (
      access.anyOf.some((permission) => permissions.includes(permission)) &&
      !isRestricted
    );
  }

  if ("allOf" in access) {
    return (
      access.allOf.every((permission) => permissions.includes(permission)) &&
      !isRestricted
    );
  }

  return true;
}

/**
 * The slice for the page access state.
 */
const pageAccessSlice = createSlice({
  name: "pageAccess",
  initialState,
  reducers: {
    initializeAccess: (
      state,
      action: PayloadAction<{
        allCapabilities: Record<string, CapabilityAccess>;
        permissions: string[];
      }>
    ) => {
      const { permissions, allCapabilities } = action.payload;

      const filteredCapabilities = Object.entries(allCapabilities)
        .map(([, value]) => value)
        .filter((capability) => {
          if (!capability.accessControl) {
            return true;
          }

          return checkAccess(permissions, capability.accessControl);
        });
      const updatedNav: NavigationItem[] = [];
      NAVIGATION_ITEMS.forEach((navItem) => {
        if (
          navItem.accessControl &&
          !checkAccess(permissions, navItem.accessControl)
        ) {
          return;
        }

        if (navItem.children) {
          const updatedChildren = navItem.children.filter((child) => {
            if (child.accessControl) {
              return checkAccess(permissions, child.accessControl);
            }

            return true;
          });

          // If the parent has children and the user does not have access to any of them, do not show the parent.
          if (navItem.children.length > 0 && updatedChildren.length === 0) {
            return;
          }

          updatedNav.push({
            ...navItem,
            children: updatedChildren
          });
        } else {
          updatedNav.push(navItem);
        }
      });

      state.capabilities = filteredCapabilities;
      state.navigation = updatedNav;
    }
  }
});

export const { initializeAccess } = pageAccessSlice.actions;

export const getPageAccess = (state: RootState) => state.pageAccess;

export default pageAccessSlice.reducer;
