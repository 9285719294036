import { Badge, LaptopIcon, TelevisionIcon } from "@miq/fiber-ui";

type Props = {
  type: string;
  label: string;
};

/**
 * Badge component to display the type of insight
 */
export default function InsightsBadge({ type, label }: Props) {
  function getIcon() {
    switch (type) {
      case "watching":
        return <TelevisionIcon size={16} weight="regular" />;
      case "browsing":
        return <LaptopIcon size={16} weight="regular" />;
      case "buying":
        return <TelevisionIcon size={16} weight="regular" />;
      default:
        return undefined;
    }
  }

  return (
    <Badge
      variant="light"
      customIcon={getIcon()}
      showIcon
      position="left"
      badgeStyle="outline"
    >
      {label}
    </Badge>
  );
}
