import type { ThemeProvider } from "@miq/fiber-ui/dist/types/themes/index";
import _ from "lodash";
import type { ReactNode } from "react";

import { primitives as pixiePrimitives } from "src/utils/primitives";
import { getFromLocalStorage } from "src/utils/storageUtil";

import hubControlConfig from "../clientConfig/default/controlConfig.json";
import { DOMAIN_FOLDER_MAP, DOMAIN_NAME } from "../constants";

export type ControlConfigType = typeof hubControlConfig;

export type PartialPrimitives = Parameters<
  typeof ThemeProvider
>[0]["primitives"];

export type TermsAndConditionsType = {
  title: string;
  navTitle: string;
  body: Array<{
    pointHtml: ReactNode;
  }>;
};

/**
 * Returns the subdomain of the current domain.
 * Splits the subdomain at the first hyphen.
 *
 * @example
 * url: "http://localhost:3000"
 * returns: "localhost"
 *
 * url: "https://unified-platform-dev.miqdigital.com"
 * returns: "unified"
 */
export function getCurrentDomain() {
  return window.location.host.split(".")[0].split("-")[0];
}

/**
 * Retrieves the client name from the domain name.
 * Uses the {@link DOMAIN_NAME} to map the domain name to the client name.
 */
export function getDomainName() {
  const domain = getCurrentDomain();
  return DOMAIN_NAME[domain as keyof typeof DOMAIN_NAME];
}

/**
 * Retrieves the folder name from the client name.
 */
export function getClientFolder() {
  const domain = getCurrentDomain();
  return (
    DOMAIN_FOLDER_MAP[domain as keyof typeof DOMAIN_FOLDER_MAP] ?? "default"
  );
}

/**
 * Checks if the client has a white label configuration.
 */
export function isWhiteLabelClient() {
  const domain = getClientFolder();
  return domain !== "default";
}

/**
 * Imports the specified client configuration file from path and the domain folder.
 *
 * @param domainFolder The folder name of the domain.
 * @param path Path to the file without the leading slash. e.g. "controlConfig.json".
 */
async function clientConfigImport<ReturnType>(
  domainFolder: string,
  path: string
): Promise<ReturnType> {
  return import(
    `src/redux/slices/whitelabel/clientConfig/${domainFolder}/${path}`
  );
}

/**
 * Dynamically imports the client configuration.
 *
 * @param domainFolder The folder name of the domain
 * @param path Path to the file without the leading slash. e.g. "controlConfig.json".
 */
export async function dynamicImportConfig<ReturnType>(
  domainFolder: string,
  path: string
): Promise<ReturnType> {
  let content;
  try {
    content = await clientConfigImport<ReturnType>(domainFolder, path);
  } catch (error) {
    const message = error instanceof Error ? error.message : "";
    console.error("Error loading client config. Path: ", path, message);
    console.error("Loading default client config");
    content = await clientConfigImport<ReturnType>("default", path);
  }

  return content;
}

/**
 * Loads the client control configuration and merges it with the default configuration.
 *
 * @param domainFolder The folder name of the domain.
 */
export async function loadControlConfig(
  domainFolder: string
): Promise<ControlConfigType> {
  const clientConfig = await dynamicImportConfig<Partial<ControlConfigType>>(
    domainFolder,
    "controlConfig.json"
  );
  const mergedConfig = _.merge(hubControlConfig, clientConfig);
  return mergedConfig;
}

/**
 * Loads the client primitives from the specified domain folder.
 * If the domain folder is "default" or primitives are not found, it returns the default primitives.
 *
 * @param domainFolder The folder name of the domain.
 * @returns
 */
export async function loadPrimitives(
  domainFolder: string
): Promise<PartialPrimitives | undefined> {
  const isPixie = getFromLocalStorage("isPixieEnabled") === "true";
  if (domainFolder === "default" && !isPixie) {
    return;
  }
  try {
    const { primitives } = await clientConfigImport<{
      primitives: PartialPrimitives;
    }>(domainFolder, "primitives");
    return primitives;
  } catch (_error) {
    return pixiePrimitives;
  }
}

/**
 * Loads the client logo path from the specified path.
 *
 * @param domainFolder The folder name of the domain.
 */
export async function getLogo(domainFolder: string, imagePath: string) {
  const { default: imgPath } = await dynamicImportConfig<{ default: string }>(
    domainFolder,
    `resources/images/${imagePath}`
  );
  return imgPath;
}

/**
 * Retrieves the favicon path from the specified path.
 *
 * @param domainFolder The folder name of the domain.
 * @param fileName The name of the favicon file.
 */
export async function getFavicon(domainFolder: string, fileName: string) {
  const { default: path } = await clientConfigImport<{ default: string }>(
    domainFolder,
    `resources/images/${fileName}`
  );

  return path;
}

/**
 * Loads the terms and conditions from the specified domain folder.
 */
export async function loadTermsAndConditions(domainFolder: string) {
  const { allTermsCards } = await dynamicImportConfig<{
    allTermsCards: TermsAndConditionsType[];
  }>(domainFolder, "termsAndConditions");
  return allTermsCards;
}
