import { SidePanel } from "@miq/fiber-ui";
import { useDispatch, useSelector } from "react-redux";

import Tsa from "src/components/remoteModules/mfeApps/components/tsa";
import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import type { AppDispatch } from "src/redux";
import { closeTsaApp, getTsaState } from "src/redux/slices/tsaSlice/tsaReducer";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";

import { SidePanelWrapper } from "./styled";

export const TsaAppSidePanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { value: isTsaPresent } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.tradingAgent.SHOW_TSA_MFE,
    false
  );
  const { isOpen: isTsaOpen } = useSelector(getTsaState);

  return (
    <SidePanelWrapper data-testid="side-panel-wrapper">
      <SidePanel
        width="500px"
        top="var(--app-header-height, 64px)"
        isOpen={isTsaOpen && isTsaPresent}
        onClose={() => dispatch(closeTsaApp())}
      >
        <Tsa />
      </SidePanel>
    </SidePanelWrapper>
  );
};
