import styled from "@emotion/styled";

const Footer = styled.footer`
  background-color: ${({ theme }) =>
    theme.vars.palette.surface.background.lvl1};
  display: flex;
  justify-content: center;
  width: 100%;
  height: 64px;
  position: fixed;
  bottom: 0;
`;

const AcceptConditionsHolder = styled.div`
  width: 1200px;
  margin-top: 15px;
  .accept-conditions-checkbox {
    margin: 3px 5px 0px 0px;
  }
`;

const AcceptConditionsText = styled.div`
  display: flex;
  float: right;
`;

export { AcceptConditionsHolder, AcceptConditionsText, Footer };
