import styled from "@emotion/styled";

const FallbackStyled = styled.div`
  flex-grow: 1;

  .fall-back-icon {
    font-size: 44px;
  }

  .fall-back-title {
    margin-top: 12px;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
  }

  .fall-back-subtitle {
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
`;

export default FallbackStyled;
