export const FEATURE_FLAG_KEYS = {
  globalBanner: {
    IS_ENABLED: "globalBannerIsEnabled",
    BANNER_MESSAGE: "globalBannerMessage",
    BANNER_TYPE: "globalBannerType",
    IS_DISMISSIBLE: "globalBannerIsDismissible"
  },
  heroFeatures: {
    SHOW_HERO_FEATURES: "heroFeatures",
    SHOW_HOMEPAGE_SIZZLE: "showSizzleHomepage"
  },
  creatives: {
    SHOW_CREATIVES_MFE: "creativesmfe"
  },
  tradingAgent: {
    SHOW_TSA_MFE: "istradingagentmfeavailable"
  },
  debug: {
    CLEAR_SITE_DATA: "clearSiteData"
  },
  pixie: {
    SHOW_PIXIE: "pixieIsEnabled"
  }
};
