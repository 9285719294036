import { Navigate } from "react-router-dom";

import usePageAccess from "src/redux/slices/pageAccess/hooks/usePageAccess";

type Props = {
  id: string;
};

/**
 * Redirects to the first child route that the user has access to.
 */
export default function FallbackRedirect({ id }: Props) {
  const { navigation } = usePageAccess();

  const parentRoute = navigation.find((nav) => nav.id === id);
  const redirectUrl = parentRoute?.children?.[0]?.navigationLink ?? "/";

  return <Navigate to={redirectUrl} replace />;
}
