import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import OktaSignIn from "@okta/okta-signin-widget";
import React, { useEffect, useRef } from "react";

import { oktaSignInConfig } from "src/utils/oktaConfig";

import { OktaLoginWrapper } from "./styled";

type OktaLoginProps = {
  onSuccess: (tokens: any) => void;
  onError: (error: any) => void;
  activeIndex: number;
};

const signInWidget = new OktaSignIn(oktaSignInConfig);
const OktaLogin = ({ onSuccess, onError, activeIndex }: OktaLoginProps) => {
  const widgetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!widgetRef?.current) return;

    signInWidget
      .showSignInToGetTokens({
        el: widgetRef.current
      })
      .then(onSuccess)
      .catch(onError);

    function addPlaceholderText() {
      // Function to check if the input fields are found
      const checkPasswordInputFields = () => {
        const oldPasswordField = document.querySelector(
          'input[name="oldPassword"]'
        );
        const newPasswordField = document.querySelector(
          'input[name="newPassword"]'
        );
        const confirmPasswordField = document.querySelector(
          'input[name="confirmPassword"]'
        );
        return oldPasswordField && newPasswordField && confirmPasswordField;
      };

      const checkLoginInputFields = () => {
        const usernameField = document.querySelector('input[name="username"]');
        const passwordField = document.querySelector('input[name="password"]');
        return usernameField && passwordField;
      };

      const config = { childList: true, subtree: true };

      const observer = new MutationObserver((mutationsList, observerRef) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            const loginNode = document.querySelector(".primary-auth");
            const resetNode = document.querySelector(".password-expired");
            if (loginNode && checkLoginInputFields()) {
              const usernameField = loginNode.querySelector(
                'input[name="username"]'
              );
              const passwordField = loginNode.querySelector(
                'input[name="password"]'
              );
              if (usernameField) {
                usernameField.setAttribute(
                  "placeholder",
                  "Enter your username"
                );
              }
              if (passwordField) {
                passwordField.setAttribute(
                  "placeholder",
                  "Enter your password"
                );
              }
              observerRef.disconnect();
            }
            /* istanbul ignore next */
            // NO SONAR
            if (resetNode && checkPasswordInputFields()) {
              const oldPasswordField = resetNode.querySelector(
                'input[name="oldPassword"]'
              );
              const newPasswordField = resetNode.querySelector(
                'input[name="newPassword"]'
              );
              const confirmPasswordField = resetNode.querySelector(
                'input[name="confirmPassword"]'
              );
              if (oldPasswordField) {
                oldPasswordField.setAttribute("placeholder", "Old Password");
              }
              if (newPasswordField) {
                newPasswordField.setAttribute("placeholder", "New Password");
              }
              if (confirmPasswordField) {
                confirmPasswordField.setAttribute(
                  "placeholder",
                  "Confirm Password"
                );
              }
              observerRef.disconnect();
            }
          }
        }
      });

      observer.observe(document.documentElement, config);
    }

    // Wait for the Okta widget to be initialized
    const intervalId = setInterval(() => {
      if (document.querySelector(".o-form")) {
        addPlaceholderText();
        clearInterval(intervalId);
      }
    }, 100);

    return () => signInWidget.remove();
  }, [onSuccess, onError]);

  return (
    <OktaLoginWrapper activeIndex={activeIndex}>
      <div ref={widgetRef} id="widget-container" data-testid="okta-login" />
      <img
        src="https://okta-redirect.miqdigital.com/setCookie?url=https://unified-platform.miqdigital.com"
        className="redirection-cookie-dropper"
        alt="redirection-cookie-dropper"
      />
    </OktaLoginWrapper>
  );
};

export default OktaLogin;
