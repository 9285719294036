import { Button, CaretLeftIcon, Typography } from "@miq/fiber-ui";
import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import type { KeyboardEvent } from "react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import loginMiq from "src/assets/images/login/loginMiq.svg";
import slackIcon from "src/assets/images/login/slack.svg";
import type { AppDispatch } from "src/redux";
import useWhiteLabel from "src/redux/slices/whitelabel/hooks/useWhiteLabel";
import { setTheme } from "src/redux/slices/whitelabel/whiteLabelReducer";
import { THEME } from "src/utils/constants/user";
import { getFromLocalStorage } from "src/utils/storageUtil";

import ForgotPassword from "./components/forgotPassword";
import LoginCarousel from "./components/loginCarousel";
import OktaLogin from "./components/oktaLogin";
import RequestAccess from "./components/requestAccess";
import UserSwitcher from "./components/userSwitcher";
import type { FormType } from "./constants/loginConstants";
import {
  FORGOT_PASSWORD_FORM,
  IT_SUPPORT_SLACK_LINK,
  LOGIN_FORM,
  LOGIN_FORM_DESCRIPTION_FIRST_PART,
  LOGIN_FORM_DESCRIPTION_SECOND_PART,
  REQUEST_ACCESS_FORM,
  SUBTITLE_MAP_ARRAY
} from "./constants/loginConstants";
import MainStyled from "./styled";

/**
 * Login Component
 */
const LoginPage: React.FC = () => {
  const { logo: loginLogo, isWhiteLabel, headerLogo } = useWhiteLabel();
  const subtitleMap = new Map<string, string>(SUBTITLE_MAP_ARRAY);
  const [currentForm, setCurrentForm] = useState<FormType>(LOGIN_FORM);
  const { oktaAuth, authState } = useOktaAuth();
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Set the theme to light on mount.
   */
  useEffect(() => {
    dispatch(setTheme({ theme: "light", persistInStorage: false }));

    return () => {
      const selectedTheme = (getFromLocalStorage(THEME) ?? "light") as
        | "light"
        | "dark";
      dispatch(setTheme({ theme: selectedTheme }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate("/");
    }
  }, [authState?.isAuthenticated, navigate]);

  /**
   * Function to change the form based on the formType
   */
  function changeForm(
    formType: FormType,
    event?: KeyboardEvent<HTMLButtonElement>
  ) {
    if ((event && event.key === "Enter") || !event) {
      setCurrentForm(formType);
    }
  }

  /**
   * Open slack channel on click of help button
   */
  function goToSlackChannel() {
    window.open(IT_SUPPORT_SLACK_LINK);
  }

  /**
   * Function to handle the success of the login
   */
  const onSuccess = (tokens: any) => {
    const { idToken, accessToken } = tokens;

    // re-mount App when token available on login
    if (!!idToken && !!accessToken) {
      // && !!refreshToken
      // ? Question: Why do we need to update the key?
      // props.updateKey();
    }
    oktaAuth.handleLoginRedirect(tokens);
  };

  /**
   * Function to handle the error of the login
   */
  const onError = (err: any) => {
    console.error("Error: Okta Login ", err);
  };

  /**
   * Function to handle the tab change
   */
  const onTabChange = (index: number) => {
    setActiveIndex(index);
  };

  const currentFormClassName = _.isEqual(currentForm, LOGIN_FORM)
    ? ""
    : "d-none";

  if (!authState) return null;

  return (
    <MainStyled data-testid="login">
      <div className="left-col login-animation">
        <div className="miq-logo">
          <img
            className="miq-logo-img"
            src={isWhiteLabel ? headerLogo : loginMiq}
            alt="logo"
          />
        </div>
        <LoginCarousel />
      </div>
      <div />

      <div className="right-col">
        <div className="login-card">
          <div>
            <div className="logo-subtitle-wrapper">
              <img className="miq-logo-img" src={loginLogo} alt="logo" />
              <div className="form-subtitle-description">
                <Typography
                  variant="Body"
                  size="md"
                  fontWeight="regular"
                  as="span"
                >
                  <span>{LOGIN_FORM_DESCRIPTION_FIRST_PART}</span>
                  <br />
                  <span>{LOGIN_FORM_DESCRIPTION_SECOND_PART}</span>
                </Typography>
              </div>
            </div>
            {_.isEqual(currentForm, LOGIN_FORM) && (
              <UserSwitcher
                onTabChange={onTabChange}
                activeIndex={activeIndex}
              />
            )}
            <div>
              <div className="form-subtitle-signin">
                <Typography
                  variant="Headline"
                  size="sm"
                  fontWeight="medium"
                  as="span"
                >
                  <span>{subtitleMap.get(currentForm)}</span>
                </Typography>
              </div>
              <div className={currentFormClassName}>
                <OktaLogin
                  onSuccess={onSuccess}
                  onError={onError}
                  activeIndex={activeIndex}
                />
              </div>
              {_.isEqual(currentForm, FORGOT_PASSWORD_FORM) && (
                <ForgotPassword />
              )}
              {_.isEqual(currentForm, REQUEST_ACCESS_FORM) && <RequestAccess />}
            </div>
          </div>
          {_.isEqual(activeIndex, 0) ||
          _.isEqual(currentForm, REQUEST_ACCESS_FORM) ? (
            <div className="login-footer">
              {_.isEqual(currentForm, LOGIN_FORM) ? (
                <>
                  <div className="d-flex flex-row justify-content-start forgot-password">
                    <Button
                      as="a"
                      primary
                      onKeyDown={(event) =>
                        changeForm(FORGOT_PASSWORD_FORM, event)
                      }
                      className="link-text"
                      onClick={() => changeForm(FORGOT_PASSWORD_FORM)}
                    >
                      <Typography
                        variant="Body"
                        size="md"
                        fontWeight="regular"
                        as="span"
                        color="inherit"
                      >
                        Forgot Password?
                      </Typography>
                    </Button>
                    <div className="align-self-center oval" />
                    <Button
                      as="a"
                      primary
                      onKeyDown={(event) =>
                        changeForm(REQUEST_ACCESS_FORM, event)
                      }
                      className="link-text"
                      onClick={() => changeForm(REQUEST_ACCESS_FORM)}
                    >
                      <Typography
                        variant="Body"
                        size="md"
                        fontWeight="regular"
                        as="span"
                        color="inherit"
                      >
                        Help
                      </Typography>
                    </Button>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-baseline">
                    <div className="account-help-text">
                      <Typography
                        variant="Body"
                        size="md"
                        fontWeight="regular"
                        as="span"
                      >
                        Don’t have an account?
                      </Typography>
                    </div>
                    <Button
                      as="a"
                      primary
                      onKeyDown={(event) =>
                        changeForm(REQUEST_ACCESS_FORM, event)
                      }
                      className="link-text"
                      onClick={() => changeForm(REQUEST_ACCESS_FORM)}
                    >
                      <Typography
                        variant="Body"
                        size="md"
                        fontWeight="regular"
                        as="span"
                        color="inherit"
                      >
                        Request Access
                      </Typography>
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  as="a"
                  primary
                  onKeyDown={(event) => changeForm(LOGIN_FORM, event)}
                  className="link-text d-flex flex-row justify-content-start align-items-center back-button"
                  onClick={() => changeForm(LOGIN_FORM)}
                >
                  <CaretLeftIcon
                    weight="regular"
                    size="32"
                    color="var(--fiber-colors-surface-foreground-dynamic-brand-default)"
                  />
                  <Typography
                    variant="Body"
                    size="md"
                    fontWeight="regular"
                    as="span"
                  >
                    <span>Back to sign in</span>
                  </Typography>
                </Button>
              )}
            </div>
          ) : (
            !_.isEqual(currentForm, REQUEST_ACCESS_FORM) &&
            _.isEqual(activeIndex, 1) && (
              <div className="d-flex flex-row justify-content-start align-items-center">
                <Typography
                  variant="Body"
                  size="md"
                  fontWeight="regular"
                  as="span"
                >
                  <div>Facing problems? Get in touch with</div>
                </Typography>
                <img className="slack-icon" src={slackIcon} alt="slack-icon" />
                <Button
                  as="a"
                  primary
                  data-testid="itsupport"
                  onKeyDown={goToSlackChannel}
                  className="link-text"
                  onClick={goToSlackChannel}
                >
                  <Typography
                    variant="Body"
                    size="md"
                    fontWeight="regular"
                    as="span"
                    color="inherit"
                  >
                    #itsupport
                  </Typography>
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    </MainStyled>
  );
};

export default LoginPage;
