import { Loader } from "@miq/fiber-ui";
import { useEffect } from "react";

import LoaderWrapperStyled from "./styled";

const PageLoader = () => {
  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <LoaderWrapperStyled data-testid="page-loader-wrapper">
      <Loader height={30} width={30} type="hub" isVisible />
    </LoaderWrapperStyled>
  );
};

export default PageLoader;
