import { createAsyncThunk } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";
import { getLoginDetails, getUserProfile } from "src/services/userService";
import { COOKIELESS_GEOS } from "src/utils/constants/geoConstants";
import { ALL_CAPABILITIES } from "src/utils/constants/navigation";
import {
  HUB_COOKIELESS_USER_GEO_COOKIE,
  HUB_COOOKIELESS_DEFAULT_GEO,
  HUB_DEMO_MODE,
  HUB_LOGIN_TOKEN_COOKIE,
  HUB_TERMS_ACCEPTED,
  HUB_USER_DEFAULT_GEO_COOKIE,
  HUB_USER_EMAIL_COOKIE,
  HUB_USER_FIRSTNAME_COOKIE,
  HUB_USER_GEO_COOKIE,
  HUB_USER_LASTNAME_COOKIE,
  HUB_USER_PERMISSIONS,
  USER_PERMISSIONS,
  WHITELABEL_RESTRICTED_PERMISSIONS
} from "src/utils/constants/user";
import {
  removeFromLocalStorage,
  setInLocalStorage
} from "src/utils/storageUtil";

import { initializeAccess } from "../pageAccess";
import type { WhiteLabelType } from "../whitelabel/whiteLabelReducer";

import type { GoogleAccInfo, User } from "./userReducer";

const MIQ_EMAIL_DOMAIN = "@miqdigital.com";

/**
 * Adds demo mode to the permissions.
 *
 * @param permissions permissions
 * @returns updated permissions with demo mode
 */
function addDemoModeToPermissions(permissions: string[]) {
  return permissions.concat(USER_PERMISSIONS.DEMO_MODE);
}

/**
 * Removes demo mode from the permissions.
 *
 * @param permissions permissions
 * @returns updated permissions without demo mode
 */
function removeDemoModeFromPermissions(permissions: string[]) {
  return permissions.filter(
    (permission) => permission !== USER_PERMISSIONS.DEMO_MODE
  );
}

/**
 * Checks if the user is an internal user.
 * Uses the {@link MIQ_EMAIL_DOMAIN} to check if the email is an internal email.
 */
function isInternalUser(email: string) {
  return email.includes(MIQ_EMAIL_DOMAIN);
}

/**
 * Saves the user details to the local storage.
 * These details are consumed by the micro-frontends, to identify the user.
 */
function saveUserDetailsToLocalStorage(
  userDetails: User,
  whiteLabel: WhiteLabelType
) {
  const { clientControlConfig } = whiteLabel;
  let geoCookie = clientControlConfig?.defaultGeo ?? "";
  if (geoCookie === "DYNAMIC") {
    geoCookie = userDetails.userGeo === "global" ? "US" : userDetails.userGeo;
  }

  setInLocalStorage(HUB_LOGIN_TOKEN_COOKIE, userDetails.loginSessionId);
  setInLocalStorage(HUB_USER_GEO_COOKIE, geoCookie);
  setInLocalStorage(HUB_USER_DEFAULT_GEO_COOKIE, userDetails.userGeo);
  setInLocalStorage(
    HUB_USER_PERMISSIONS,
    JSON.stringify(userDetails.permissions)
  );
  setInLocalStorage(
    HUB_COOOKIELESS_DEFAULT_GEO,
    clientControlConfig?.defaultCookielessGeo ?? ""
  );
  setInLocalStorage(HUB_COOKIELESS_USER_GEO_COOKIE, COOKIELESS_GEOS.US);

  if (
    userDetails.termsAccepted &&
    clientControlConfig?.showTermsAndConditions
  ) {
    setInLocalStorage(HUB_TERMS_ACCEPTED, `${userDetails.termsAccepted}`);
  } else {
    removeFromLocalStorage(HUB_TERMS_ACCEPTED);
  }

  setInLocalStorage(HUB_USER_FIRSTNAME_COOKIE, userDetails.firstName);
  setInLocalStorage(HUB_USER_EMAIL_COOKIE, userDetails.email);
}

/**
 * Saves the google account details to the local storage.
 */
function saveGoogleAccDetailsToLocalStorage(googleAccInfo: GoogleAccInfo) {
  setInLocalStorage(HUB_USER_LASTNAME_COOKIE, googleAccInfo.lastName);
}

/**
 * Fetches the google account details.
 */
export const fetchGoogleAccDetails = createAsyncThunk(
  "user/fetchGoogleAccDetails",
  async (email: string) => {
    try {
      const userDetails = await getUserProfile(email);
      if (!userDetails?.data) {
        throw new Error("Google user details not found");
      }
      saveGoogleAccDetailsToLocalStorage(userDetails.data);
      return userDetails.data;
    } catch (error) {
      console.error("Failed to fetch user details from google: ", error);
      throw new Error("Failed to fetch user details from google");
    }
  }
);

/**
 * Fetches the user details from the admin service.
 */
export const fetchUserDetails = createAsyncThunk<
  User,
  void,
  { state: RootState }
>("user/fetchUserDetails", async (_params, { dispatch, getState }) => {
  try {
    const { whiteLabel } = getState();
    const { isWhiteLabel } = whiteLabel;

    const response = await getLoginDetails();
    if (!response?.data?.responseBody) {
      throw new Error("User details not found");
    }

    const isDemoMode = localStorage.getItem(HUB_DEMO_MODE) === "demo";

    // Destructuring the object so that we can add additional properties. The original object is frozen.
    const userDetails = { ...response.data.responseBody };
    const email = userDetails.email;

    if (isDemoMode) {
      userDetails.permissions = addDemoModeToPermissions(
        userDetails.permissions
      );
    }

    if (isWhiteLabel) {
      userDetails.permissions = userDetails.permissions.filter(
        (item) => !WHITELABEL_RESTRICTED_PERMISSIONS.has(item as any)
      );
    }

    dispatch(
      initializeAccess({
        permissions: userDetails.permissions,
        allCapabilities: ALL_CAPABILITIES
      })
    );
    dispatch(fetchGoogleAccDetails(email));

    userDetails.isInternalUser = isInternalUser(email);

    userDetails.termsAccepted =
      response.data.responseBody.termsAccepted ?? false;
    if (whiteLabel.clientControlConfig?.showTermsAndConditions === false) {
      userDetails.termsAccepted = true;
    }

    saveUserDetailsToLocalStorage(userDetails, whiteLabel);
    return userDetails;
  } catch (error) {
    console.error("Failed to fetch user details: ", error);
    throw new Error("Failed to fetch user details");
  }
});

/**
 * Toggles the demo mode for the user.
 *
 * @returns string[] updated permissions
 */
export const toggleDemoMode = createAsyncThunk<
  string[],
  void,
  { state: RootState }
>("user/toggleDemoMode", async (_params, { dispatch, getState }) => {
  const {
    user: { userDetails }
  } = getState();
  const permissions = userDetails?.permissions ?? [];
  const isDemoMode = permissions.includes(USER_PERMISSIONS.DEMO_MODE);

  /**
   * If the user is in demo mode, add demo mode to permissions.
   */
  let updatedPermissions;
  if (!isDemoMode) {
    updatedPermissions = addDemoModeToPermissions(permissions);
    setInLocalStorage(HUB_DEMO_MODE, "demo");
  } else {
    updatedPermissions = removeDemoModeFromPermissions(permissions);
    setInLocalStorage(HUB_DEMO_MODE, "profile");
  }

  dispatch(
    initializeAccess({
      permissions: updatedPermissions,
      allCapabilities: ALL_CAPABILITIES
    })
  );

  return updatedPermissions;
});
