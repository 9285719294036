import { useState } from "react";

import analyseAnimation from "src/assets/images/login/Analyse700px.gif";
import discoverAnimation from "src/assets/images/login/Discover700px.gif";
import reportAnimation from "src/assets/images/login/Report700px.gif";

type Slides = {
  title: string;
  description: string;
  gif: string;
  interval: number;
};

const defaultSlides: Slides[] = [
  {
    title: "TV Intelligence",
    description:
      // eslint-disable-next-line max-len
      "Explore linear TV, streaming and YouTube  insights for optimal reach and frequency.",
    gif: analyseAnimation,
    interval: 4800
  },
  {
    title: "Context Intelligence",
    description:
      "Unlock brand insights and audience data, to plan effective campaigns.",
    gif: discoverAnimation,
    interval: 8500
  },
  {
    title: "Reports",
    description:
      "Improve business outcomes with data rich custom visualizations.",
    gif: reportAnimation,
    interval: 6100
  }
];

const useLoginCarouselItems = () => {
  const [carouselItems] = useState(defaultSlides);
  const [activeIndex, setActiveIndex] = useState(0);

  function handleSlideChange(index: number) {
    setActiveIndex(index);
  }

  return {
    carouselItems,
    activeItem: carouselItems[activeIndex],
    handleSlideChange
  };
};

export default useLoginCarouselItems;
