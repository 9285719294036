import styled from "@emotion/styled";

export const CardList = styled.ul`
  margin-left: 24px;
  margin-top: 15px;
`;

export const FooterStyled = styled.footer`
  display: flex;
  justify-content: center;
  margin-block: 64px;
`;
