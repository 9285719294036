import type { SettingTypeOf } from "configcat-react";
import { useFeatureFlag } from "configcat-react";
import { useSelector } from "react-redux";

import { getUserDetails } from "src/redux/slices/userSlice";

/**
 * Wrapper around the useFeatureFlag hook that adds user details to the hook.
 */
const useUserFeatureFlag: typeof useFeatureFlag = (
  key,
  defaultValue,
  userOptions
) => {
  const { userDetails, googleAccInfo } = useSelector(getUserDetails);
  const { email = "" } = userDetails ?? {};

  const {
    region = "",
    department = "",
    isExternal = false
  } = googleAccInfo ?? {};
  const type = isExternal ? "external" : "internal";
  const userObj = {
    identifier: email,
    email,
    custom: {
      region,
      department,
      type,
      ...userOptions?.custom
    }
  };
  const response = useFeatureFlag(key, defaultValue, userObj);

  if (!userDetails)
    return {
      loading: true,
      value: defaultValue as SettingTypeOf<typeof defaultValue>
    };

  return response;
};

export default useUserFeatureFlag;
