import React, { createContext, useContext } from "react";

import type { LabUser } from "src/pages/campaigns/hooks/useLabDetails";
import useLabDetails from "src/pages/campaigns/hooks/useLabDetails";

export interface UserContextType {
  all: {
    allUsers: LabUser[];
    allAnalystUsers: LabUser[];
  };
  current: unknown | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }

  return context;
};

export const UserContextProvider = (props: React.PropsWithChildren<object>) => {
  const { allUsers, analystUsers, currentUser } = useLabDetails();

  if (!(allUsers && analystUsers && currentUser)) return null;

  const userContextObject = {
    current: currentUser,
    all: {
      allUsers,
      allAnalystUsers: analystUsers
    }
  };

  return (
    <UserContext.Provider value={userContextObject}>
      {props.children}
    </UserContext.Provider>
  );
};
