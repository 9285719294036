import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getPageAccess } from "src/redux/slices/pageAccess";

/**
 * Wrapper for secure routes, that require the user to be authenticated.
 */
const SecureRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();

  const { capabilities, navigation } = useSelector(getPageAccess);

  useEffect(() => {
    // During initialization authState would be empty.
    if (!authState) return;

    if (!authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      navigate("/login");
    }

    if (capabilities && navigation && navigation.length === 0) {
      navigate("/unauthorized");
    }
  }, [authState, navigate, oktaAuth, capabilities, navigation]);

  if (authState?.isAuthenticated) {
    return <>{children}</>;
  }

  return null;
};

export default SecureRoute;
