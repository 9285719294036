import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { mixpanelProperties } from "src/config/config";
import { getUserDetails } from "src/redux/slices/userSlice";
import { MIXPANEL_EVENT_APP_LOGIN } from "src/utils/constants/mixpanelConstants";

/**
 * Validates an email address.
 * TODO: simplify the regular expression.
 *
 * @param email email to validate
 * @returns boolean
 */
const validateEmail = (email: string) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * Retrieves the domain from the email.
 *
 * @param email email to extract the domain from
 * @returns string
 */
const getEmailDomain = (email: string) => {
  if (!validateEmail(email) || _.isEmpty(email)) {
    return undefined;
  }

  const stringAfterAt = email.substring(email.indexOf("@") + 1, email.length);
  return stringAfterAt.substring(0, stringAfterAt.indexOf("."));
};

/**
 * Hook to interact with Mixpanel.
 */
export default function useMixpanel() {
  const { googleAccInfo, userDetails } = useSelector(getUserDetails);
  const { authState } = useOktaAuth();

  const mixpanel = window.mixpanel;

  const isMixPanelLoaded = mixpanelProperties.enabled && mixpanel !== undefined;

  /**
   * Sets the Mixpanel People properties.
   */
  const initializeMixpanelUser = useCallback(() => {
    const { sub } = authState?.accessToken?.claims ?? {};
    const { department, title } = googleAccInfo ?? {};
    const { isInternalUser, loginSessionId } = userDetails ?? {};

    /**
     * Creates an object with the properties to be set in Mixpanel People.
     *
     * @param userId User ID
     */
    function getMixpanelPeopleProperties(userId: string) {
      const userType = isInternalUser ? "internal" : "external";
      const userInfo = {
        user: userId,
        username: userId,
        type: userType,
        $email: userId,
        login_session_id: loginSessionId
      };
      if (isInternalUser) {
        return {
          ...userInfo,
          department,
          title
        };
      }

      return {
        ...userInfo,
        agency: getEmailDomain(userId)
      };
    }

    if (isMixPanelLoaded && !_.isUndefined(mixpanel.people)) {
      const peopleProperties = getMixpanelPeopleProperties(sub ?? "");
      if (!_.isEmpty(peopleProperties.user)) {
        mixpanel.identify(sub);
      }
      mixpanel.people.set(peopleProperties);
      mixpanel.track(MIXPANEL_EVENT_APP_LOGIN);
    }
  }, [
    authState?.accessToken?.claims,
    googleAccInfo,
    isMixPanelLoaded,
    mixpanel,
    userDetails
  ]);

  /**
   * Tracks an event in Mixpanel.
   *
   * @param eventName event name
   * @param properties event properties
   */
  const trackEvent = useCallback(
    (eventName: string, properties?: Record<string, any>) => {
      if (isMixPanelLoaded && mixpanel?.track) {
        mixpanel.track(eventName, properties);
      }
    },
    [mixpanel, isMixPanelLoaded]
  );

  return { mixpanel, initializeMixpanelUser, trackEvent };
}
