import axios from "axios";

import { adminServiceProperties } from "src/config/config";
import {
  getDomainName,
  loadControlConfig
} from "src/redux/slices/whitelabel/utils";

export const sendMailToDev = async ({
  name,
  email,
  organization,
  jobTitle,
  phoneNumber
}: {
  name: string;
  email: string;
  organization: string;
  jobTitle: string;
  phoneNumber: string;
}) => {
  // eslint-disable-next-line max-len
  const sendMailToDevURL = `//${adminServiceProperties.noAuthBaseURL}${adminServiceProperties.adminBasePath}/accessRequestMail`;
  const config = await loadControlConfig(getDomainName());
  return axios.post(sendMailToDevURL, {
    name,
    email,
    organization,
    jobTitle,
    phoneNumber,
    clientName: config.title,
    receiverMail: config.oktaLogin.requestAccessReceiverMail,
    senderMail: config.oktaLogin.requestAccessSenderMail
  });
};
