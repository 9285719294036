import { useTheme } from "@emotion/react";
import { useLayoutEffect } from "react";
import { useMatches } from "react-router-dom";

import { BODY_BACKGROUND_VARIABLE } from "src/utils/constants/htmlConstants";
import { LOGIN_PAGE_ID } from "src/utils/constants/routeConstants";
import { saveCssVariable } from "src/utils/htmlUtils";

export default function PageStyles() {
  const matches = useMatches();
  const theme = useTheme();

  /**
   * Adding overscroll background color to the app.
   * Using useLayoutEffect to ensure that the overscroll background color is applied before the component is rendered.
   */
  useLayoutEffect(() => {
    if (matches.find((match) => match.id === LOGIN_PAGE_ID)) {
      saveCssVariable(
        BODY_BACKGROUND_VARIABLE,
        theme.vars.palette.surface.background.miq
      );
    } else {
      saveCssVariable(
        BODY_BACKGROUND_VARIABLE,
        theme.vars.palette.surface.background.lvl2
      );
    }
  }, [matches, theme]);

  return null;
}
