import { useTheme } from "@emotion/react";
import { CustomIcon } from "@miq/fiber-ui";

import { useWhiteLabel } from "src/redux/slices/whitelabel";

import { RestrictedStyled } from "./styled";

/**
 *  Default message component when user doesn't have access to that particular page
 */
export default function Restricted() {
  const theme = useTheme();
  const { clientControlConfig } = useWhiteLabel();
  const { companyName } = clientControlConfig;

  return (
    <RestrictedStyled className="restricted">
      <div className="restricted-content">
        <div className="restricted-icon">
          <CustomIcon
            data-testid="custom-icon"
            iconName="SmileySad"
            weight="regular"
            size="48"
            color={theme.vars.palette.surface.foreground.dynamic.error.default}
          />
        </div>
        <div className="restricted-title">Woops...!</div>
        <div className="restricted-subtitle">
          Seems like you don’t have access. Please reach out
          <br />
          to your {companyName} representative to request access.
        </div>
      </div>
    </RestrictedStyled>
  );
}
