import { useTheme } from "@emotion/react";
import { Button, Callout, Input, WarningCircleIcon } from "@miq/fiber-ui";
import React, { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { googleRecaptchaSiteKey } from "src/config/config";
import useWhiteLabel from "src/redux/slices/whitelabel/hooks/useWhiteLabel";

import ReCaptcha from "./components/ReCaptcha";
import useForgotPasswordState from "./hooks/useForgotPasswordState";
import ForgotPasswordStyled from "./styled";
import { generateEmailThemeAndConfig } from "./util";

const ForgotPassword: React.FC = () => {
  const { clientControlConfig, domainName } = useWhiteLabel();
  const theme = useTheme();

  const {
    isFormSubmitted,
    isLoading,
    isError,
    userEmail,
    errorMessage,
    isInputBlank,
    onBlankInput,
    onFormSubmitSuccess,
    onEmailInput,
    onFormLoading,
    onFormSubmitError
  } = useForgotPasswordState();
  const {
    forgotPassword: { agencyEmail },
    title: agencyName
  } = clientControlConfig;

  const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(false);

  const highLightInputClassName = `input-container ${
    isError ? "highlightInput" : ""
  }`;
  const errorMessageText =
    errorMessage && !isInputBlank ? `${errorMessage}` : "";
  const buttonClassName = `resetButton ${isLoading ? "inactive-button" : ""}`;

  function onResetClick() {
    onFormLoading();
    setIsCaptchaEnabled(true);
  }

  function updateUserName(event: React.FormEvent<HTMLInputElement>) {
    onEmailInput(event.currentTarget.value);
  }

  const getEmailRequest = () => {
    const requestBody = {
      userEmail,
      agencyLogoImage: "",
      agencyEmail,
      agencyName,
      emailConfig: generateEmailThemeAndConfig(
        theme,
        domainName,
        clientControlConfig.emailTemplateConfig.base
      )
    };
    return requestBody;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaSiteKey}>
      <ForgotPasswordStyled>
        <div id="forgotPasswordContainer">
          <div className="auth-body">
            {isFormSubmitted && (
              <Callout
                isDescriptive
                variant="success"
                width="100%"
                withBorder
                zIndex={6000}
              >
                <Callout.Title>
                  <p>An email is on its way…</p>
                </Callout.Title>
                <Callout.Description>
                  Nice! An email has been sent to <span>{userEmail}</span>
                  &nbsp; with instructions on how to reset your password.
                </Callout.Description>
              </Callout>
            )}
            {!isFormSubmitted && (
              <div className="form">
                {isError && (
                  <Callout
                    variant="error"
                    width="100%"
                    withBorder
                    zIndex={6000}
                  >
                    <Callout.Title>
                      <b>Password reset failed. Please try again.</b>
                    </Callout.Title>
                    <Callout.Description>
                      {errorMessageText}
                    </Callout.Description>
                  </Callout>
                )}
                {isInputBlank && (
                  <div className="form-input-error">
                    <span className="error-icon">
                      <WarningCircleIcon
                        weight="regular"
                        size="16"
                        color={
                          theme.vars.palette.surface.foreground.static.error
                        }
                      />
                    </span>
                    <span>This field cannot be left blank</span>
                  </div>
                )}
                <div className={highLightInputClassName}>
                  <Input
                    data-testid="email-holder"
                    width="100%"
                    value={userEmail}
                    onChange={updateUserName}
                    disabled={isLoading}
                    type="email"
                    placeholder="Email Or Username"
                  />
                </div>
                <ReCaptcha
                  emailRequest={getEmailRequest()}
                  isCaptchaEnabled={isCaptchaEnabled}
                  setIsCaptchaEnabled={setIsCaptchaEnabled}
                  onBlankInput={onBlankInput}
                  onFormSubmitSuccess={onFormSubmitSuccess}
                  onFormSubmitError={onFormSubmitError}
                />
                <Button
                  primary
                  fullwidth
                  className={buttonClassName}
                  loadingState={isLoading ? "loading" : undefined}
                  onClick={onResetClick}
                  disabled={isLoading}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </div>
      </ForgotPasswordStyled>
    </GoogleReCaptchaProvider>
  );
};

export default ForgotPassword;
