import React from "react";

import withRemoteSuspense from "src/components/remoteModules/mfeApps/hoc/withRemoteSuspense";
import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";

import Creatives from "./Creatives";

const App = React.lazy(() => import("./CreativesApp"));
const CreativesApp = withRemoteSuspense(App, { elementKey: "creatives" });
const CreativesWrapper = () => {
  const { value: showCreativesMfe } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.creatives.SHOW_CREATIVES_MFE,
    false
  );

  return showCreativesMfe ? <CreativesApp /> : <Creatives />;
};

export default CreativesWrapper;
