import { useLayoutEffect as useEffect } from "react";
import { useDispatch } from "react-redux";

import type { AppDispatch } from "src/redux";
import { setTheme } from "src/redux/slices/whitelabel/whiteLabelReducer";
import { THEME } from "src/utils/constants/user";
import { getFromLocalStorage } from "src/utils/storageUtil";

import Background from "./components/background";
import InsightsNavigation from "./components/insightsNavigation";
import InsightsPanel from "./components/insightsPanel";
import Welcome from "./components/welcome";
import { SizzlePageContentStyled, SizzlePageStyled } from "./styled";

export default function SizzlePage() {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Set the theme to dark on mount.
   */
  useEffect(() => {
    dispatch(setTheme({ theme: "dark", persistInStorage: false }));

    return () => {
      const selectedTheme = (getFromLocalStorage(THEME) ?? "light") as
        | "light"
        | "dark";
      dispatch(setTheme({ theme: selectedTheme }));
    };
  }, [dispatch]);

  return (
    <SizzlePageStyled>
      <Background />
      <SizzlePageContentStyled>
        <Welcome />
        <InsightsPanel />
        <InsightsNavigation />
      </SizzlePageContentStyled>
    </SizzlePageStyled>
  );
}
