import type { RouteObject } from "react-router-dom";

const FALLBACK_PATHS = ["", "*"];

/**
 * Creates a fallback route for the given route details.
 */
export function generateFallbackRoute(routeDetails: RouteObject) {
  return FALLBACK_PATHS.map((path) => ({
    path,
    ...routeDetails
  }));
}
