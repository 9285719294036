import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";

import { BODY_BACKGROUND_VARIABLE } from "src/utils/constants/htmlConstants";

const helpHeroStyles = () => css`
  #beacon-container .BeaconContainer {
    z-index: 10001;
  }
`;

export const globalStyles = (theme: Theme) => css`
  ::-webkit-scrollbar {
    display: none;
  }

  html,
  body {
    height: auto;
    font-family: ${theme.font.fontFamily};
  }

  html {
    background-color: var(${BODY_BACKGROUND_VARIABLE});
  }

  body {
    background-color: var(${BODY_BACKGROUND_VARIABLE});
  }

  a {
    color: ${theme.vars.palette.surface.foreground.dynamic.brand.default};

    &:hover {
      color: ${theme.vars.palette.surface.foreground.dynamic.brand.hover};
    }
  }

  ${helpHeroStyles()}
`;
