import { Typography } from "@miq/fiber-ui";

import { ReactComponent as MiqFooterSvg } from "src/assets/images/miqFooterSvg.svg";
import { useUserDetails } from "src/redux/slices/userSlice";
import { useWhiteLabel } from "src/redux/slices/whitelabel";

import Cards from "./Cards";
import { FooterStyled, GradientText, TextWrapper } from "./styled";

const LaunchPageV1 = () => {
  const { userDetails } = useUserDetails();
  const { clientControlConfig } = useWhiteLabel();

  const { firstName } = userDetails || {};

  return (
    <>
      <TextWrapper>
        <Typography variant="Headline" size="2xl" fontWeight="light">
          Hi {firstName}, ready to
        </Typography>
        <GradientText variant="Headline" size="2xl" fontWeight="light" as="h1">
          explore
        </GradientText>
        <Typography variant="Headline" size="2xl" fontWeight="light">
          ?
        </Typography>
      </TextWrapper>
      <Cards />
      {clientControlConfig.showPoweredByMiQLogo && (
        <FooterStyled>
          <MiqFooterSvg className="footer-logo" />
        </FooterStyled>
      )}
    </>
  );
};

export default LaunchPageV1;
