import styled from "@emotion/styled";
import { Card, Typography } from "@miq/fiber-ui";

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 88px;
`;

export const GradientText = styled(Typography)`
  background: linear-gradient(
    90deg,
    #d71982 25.52%,
    #ff540c 59.38%,
    #dad310 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-left: 8px;
  &::selection {
    background: highlight;
    color: inherit;
  }
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
`;

export const StyledCard = styled.div`
  display: flex;
  width: 282px;
  height: 282px;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardContent = styled(Card)`
  padding: 32px 24px;
  display: flex;
  width: 282px;
  height: 282px;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const IconWrapper = styled.div`
  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.vars.palette.surface.background.lvl3};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterStyled = styled.footer`
  display: flex;
  justify-content: center;
`;

export const CardText = styled(Typography)`
  height: 36px;
  text-align: center;
`;
