/**
 * Maps the domain name (key) to the client name.
 */
export const DOMAIN_NAME = {
  hub: "hub",
  smrs: "smrs",
  converged: "havas",
  apolloinsights: "groupm",
  noblepeople: "noblepeople",
  carlberg: "carlberg",
  educationdynamics: "educationdynamics",
  localhost: "hub",
  flynnwright: "flynnwright",
  unified: "hub"
};

/**
 * Maps the client name (key) to the folder name.
 */
export const DOMAIN_FOLDER_MAP = {
  hub: "default",
  smrs: "smrs",
  converged: "havas",
  apolloinsights: "groupm",
  noblepeople: "noblepeople",
  nobelpeople: "noblepeople",
  carlberg: "carlberg",
  educationdynamics: "educationdynamics",
  localhost: "default",
  flynnwright: "flynnwright",
  unified: "default"
};
