import type { Theme } from "@emotion/react";
import axios from "axios";

import { adminServiceProperties } from "src/config/config";
import type { ControlConfigType } from "src/redux/slices/whitelabel/utils";

export const emailTemplateTheme = (theme: Theme) => ({
  bodyBackground:
    theme.palette.container.dynamic.neutral.subtle.background.default.variation,
  headerBackground:
    theme.palette.container.dynamic.neutral.subtle.foreground.hover,
  headerTextColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  welcomeContainerBackground:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  agencyLinkColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  saluteContainerBackground:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  saluteMessageColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  buttonTextColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  saluteMessageLinkColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  saluteInfoMessageColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  buttonContainerBackground:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  footerContainerBackground:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  footerColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  footerAddressColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  footerEndBackground:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  buttonColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  footerMailColor:
    theme.palette.container.dynamic.neutral.subtle.background.default.regular,
  buttonContainerBorder:
    theme.palette.container.dynamic.brand.bold.background.default
});

export const generateEmailThemeAndConfig = (
  theme: Theme,
  domainName: string,
  emailConfig: ControlConfigType["emailTemplateConfig"]["base"]
) => {
  const emailTheme = emailTemplateTheme(theme);

  return {
    domain: domainName,
    headerText: emailConfig.headerText,
    welcomeText: emailConfig.welcomeText,
    saluteAgencyTitle: emailConfig.saluteAgencyTitle,
    fontWeightMedium: emailConfig.fontWeightMedium,
    redirectUrl: emailConfig.redirectUrl,
    saluteBtnPadding: emailConfig.saluteBtnPadding,
    buttonAgencyTitle: emailConfig.buttonAgencyTitle,
    footerMail: emailConfig.footerMail,
    footerAddress: emailConfig.footerAddress,
    footerSuggestion: emailConfig.footerSuggestion,
    bodyBackground: emailTheme.bodyBackground,
    headerBackground: emailTheme.headerBackground,
    headerTextColor: emailTheme.headerTextColor,
    headerTextFont: emailConfig.headerTextFont,
    welcomeContainerBackground: emailTheme.welcomeContainerBackground,
    agencyLinkColor: emailTheme.agencyLinkColor,
    agencyLinkFont: emailConfig.agencyLinkFont,
    saluteContainerBackground: emailTheme.saluteContainerBackground,
    saluteMessageColor: emailTheme.saluteMessageColor,
    saluteHelloFont: emailConfig.saluteHelloFont,
    credentialFont: emailConfig.credentialFont,
    fontFamily: emailConfig.fontFamily,
    buttonContainerBorder: emailConfig.buttonContainerBorder,
    buttonContainerBackground: emailTheme.buttonContainerBackground,
    buttonColor: emailTheme.buttonColor,
    buttonFont: emailConfig.buttonFont,
    footerContainerBackground: emailTheme.footerContainerBackground,
    footerColor: emailTheme.footerColor,
    footerFont: emailConfig.footerFont,
    footerMailColor: emailTheme.footerMailColor,
    footerAddressColor: emailTheme.footerAddressColor,
    footerEndBackground: emailTheme.footerEndBackground,
    footerHelp: emailConfig.footerHelp,
    saluteDescriptionFontSize: emailConfig.saluteDescriptionFontSize,
    saluteSupport: emailConfig.saluteSupport,
    saluteExpiry: emailConfig.saluteExpiry
  };
};

export const sendMailToResetPassword = (requestBody: unknown) => {
  const sendRequestToServer = `//${adminServiceProperties.noAuthBaseURL}/auth/reset-password`;
  return axios.post(sendRequestToServer, requestBody, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const sendMailToResetPasswordWithCc = (
  requestBody: unknown,
  ccEmailId: string
) => {
  const sendRequestToServer = `//${adminServiceProperties.baseURL}/auth/reset-password/${ccEmailId}`;
  return axios.post(sendRequestToServer, requestBody, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};
