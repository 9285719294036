import * as configDev from "./envConfigs/config.dev";
import * as configIntegration from "./envConfigs/config.integration";
import * as configProd from "./envConfigs/config.prod";

let config = configDev;

if (window._env_?.profile === "integration") {
  config = configIntegration;
} else if (window._env_?.profile === "production") {
  config = configProd;
}

export const authServerProperties = config.authServerProperties;
export const adminServiceProperties = config.adminServiceProperties;
export const partnershipsUrl = config.partnershipsUrl;
export const userDetailsUrl = config.userDetailsUrl;
export const helpHeroAppId = config.helpHeroAppId;
export const labUserServiceProperties = config.labUserServiceProperties;
export const mixpanelProperties = config.mixpanelProperties;
export const configCatSdkKey = config.configCatSdkKey;
export const grafanaEnvironment = config.grafanaEnvironment;
export const googleRecaptchaSiteKey = config.googleRecaptchaSiteKey;
