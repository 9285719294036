import styled from "@emotion/styled";
import { Col, Grid, Typography } from "@miq/fiber-ui";
import { Link } from "react-router-dom";

export const HeaderGridStyled = styled(Grid)`
  max-width: 1200px;
  margin-inline: auto;
  margin-top: 32px;
`;

export const HeaderTextWrapperStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  color: var(--fiber-colors-surface-foreground-static-neutral-lvl1);
`;

export const AmendedTextStyled = styled(Typography)`
  margin-bottom: 5px !important;
  color: var(--fiber-colors-surface-foreground-static-neutral-lvl3);
`;

export const BackLinkColStyled = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const BackLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;

  &,
  &:hover {
    color: var(--fiber-colors-surface-foreground-dynamic-brand-default);
  }
`;
