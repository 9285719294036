import styled from "@emotion/styled";

const ForgotPasswordStyled = styled.div`
  #forgotPasswordContainer {
    background-color: ${({ theme }) =>
      theme.vars.palette.surface.background.lvl1};
    height: auto;
    min-width: 300px;
    position: relative;
  }

  .form-loader {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 35%;
    top: 30%;
    margin-top: -30px;
    margin-left: -30px;
  }

  .auth-body {
    .email-on-its-way {
      font-size: 16px;
      // font-weight: bold;
      text-align: center;
      margin-bottom: 5%;
      font-family: ${({ theme }) => theme.font.fontFamily};
    }

    .email-sent-text {
      border: ${({ theme }) =>
        `1px solid ${theme.vars.palette.border.static.bold.success}`};
      border-radius: 2px;
      padding: 5%;
      margin-bottom: 5%;
      .info-icon {
        padding-left: 10px;
        display: inline-block;
        height: 22px;
        width: 50px;
        color: #69c573;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${({ theme }) =>
      theme.vars.palette.surface.foreground.static.neutral.lvl1};
    .reset-pwd-text {
      text-align: center;
      font-size: 16px;
      margin: 10px 0 15px;
      padding: 0;
      font-weight: 600;
    }

    .form-input-error {
      color: ${({ theme }) =>
        theme.vars.palette.surface.foreground.static.error};
      padding-bottom: 5%;
    }

    .error-icon {
      display: inline-block;
      border: none !important;
      width: 18px;
      height: 18px;
      background-size: 18px;
      color: ${({ theme }) =>
        theme.vars.palette.surface.foreground.static.error};

      &::before {
        display: none;
        content: "" !important;
      }
    }
  }

  .resetButton {
    margin-block: 24px;
  }

  .email-sent-text,
  .oops-error-message,
  .form-input-error {
    display: flex;
  }

  *:focus {
    outline: none;
  }
`;

export default ForgotPasswordStyled;
