import { Button, Form, Input } from "@miq/fiber-ui";
import type { FormEvent } from "react";
import React from "react";

import useWhiteLabel from "src/redux/slices/whitelabel/hooks/useWhiteLabel";

import { EMAIL_REGEX } from "../../constants/loginConstants";

import type { InputName } from "./hooks/useRequestAccessState";
import useRequestAccessState from "./hooks/useRequestAccessState";
import RequestAccessStyled from "./styled";
import { sendMailToDev } from "./util";

/**
 *  Modal for requesting access
 */
const RequestAccess = () => {
  const {
    clientControlConfig: {
      title: appName,
      oktaLogin: { requestAccessReceiverMail, requestAccessSenderMail }
    }
  } = useWhiteLabel();

  const {
    name,
    email,
    organization,
    jobTitle,
    phoneNumber,
    validate,
    successfulSubmit,
    mailSent,
    updateFormSubmit,
    updateInput,
    updateMailSent,
    updateValidation
  } = useRequestAccessState();

  const isValidState = validate.nameState === "has-success";
  const isInvalidState = validate.nameState === "has-danger";

  function validateInput() {
    let formValid = true;
    const isValid = {
      isNameValid: true,
      isEmailValid: true,
      isOrganizationValid: true
    };
    if (!EMAIL_REGEX.test(email)) {
      isValid.isEmailValid = false;
      formValid = false;
    }
    if (name === "") {
      isValid.isNameValid = false;
      formValid = false;
    }
    if (organization === "") {
      isValid.isOrganizationValid = false;
      formValid = false;
    }
    updateValidation(isValid);
    return formValid;
  }

  function handleChange(event: FormEvent<HTMLInputElement>) {
    const inputEl = event.currentTarget;
    const elName = inputEl.name as InputName;
    updateInput(elName, inputEl.value);
  }

  function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submitForm();
  }

  async function submitForm() {
    if (!validateInput()) return;

    updateFormSubmit();
    const requestBody = {
      name,
      email,
      organization,
      jobTitle,
      phoneNumber,
      clientName: appName,
      receiverMail: requestAccessReceiverMail,
      senderMail: requestAccessSenderMail
    };
    const response = await sendMailToDev(requestBody);

    if (response.data) {
      updateMailSent();
    }
  }

  function renderSuccessMsg() {
    return (
      <div className="submitResponse">
        <span>
          Hi {name},
          <br />
          <br />
          Thank you for showing interest in {appName}
          <br />
          We will review your request and get back to you.
          <br />
          <br />
          Thanks!
          <br />
          <strong>The {appName} Team.</strong>
        </span>
      </div>
    );
  }

  return (
    <RequestAccessStyled className="request-access-form-login-wrapper">
      {successfulSubmit && mailSent && renderSuccessMsg()}
      {successfulSubmit && !mailSent && <div className="loader" />}
      {!successfulSubmit && (
        <Form className="form" onSubmit={handleFormSubmit}>
          <Form.Field
            component={Input}
            type="text"
            placeholder="Enter name"
            validationCaption="This field is mandatory"
            name="name"
            id="name"
            className="form-input"
            required
            width="100%"
            value={name}
            valid={isValidState}
            invalid={isInvalidState}
            onChange={handleChange}
          />
          <Form.Field
            component={Input}
            placeholder="Enter email/username"
            validationCaption="This field is mandatory"
            type="email"
            name="email"
            id="exampleEmail"
            className="form-input"
            width="100%"
            value={email}
            valid={isValidState}
            invalid={isInvalidState}
            onChange={handleChange}
            required
          />
          <Form.Field
            component={Input}
            type="text"
            placeholder="Enter organisation"
            validationCaption="This field is mandatory"
            name="organization"
            id="organization"
            className="form-input"
            required
            width="100%"
            value={organization}
            valid={isValidState}
            invalid={isInvalidState}
            onChange={handleChange}
          />
          <Form.Field
            component={Input}
            type="text"
            name="jobTitle"
            id="jobTitle"
            placeholder="Enter job title"
            className="form-input"
            value={jobTitle}
            onChange={handleChange}
            validationCaption="This field is mandatory"
            width="100%"
          />
          <Form.Field
            component={Input}
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Enter phone number"
            value={phoneNumber}
            className="form-input"
            onChange={handleChange}
            validationCaption="This field is mandatory"
            width="100%"
          />
          <Button primary fullwidth className="submitButton">
            Submit
          </Button>
        </Form>
      )}
    </RequestAccessStyled>
  );
};

export default RequestAccess;
