import styled from "@emotion/styled";
import { Badge } from "@miq/fiber-ui";

export const BadgeStyled = styled(Badge)`
  &.beta-badge {
    justify-content: center;
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  gap: 12px;
`;
