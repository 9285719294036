export const primitives = {
  neutral: {
    "100": "#f7f7fa",
    "200": "#ebebf0",
    "300": "#e1e1e8",
    "400": "#c6c6cc",
    "500": "#a8a8b2",
    "600": "#717180",
    "700": "#4b4b59",
    "800": "#282833",
    "900": "#13131a",
    white: "#ffffff"
  },
  neutralDark: {
    "100": "#1b181f",
    "200": "#25232a",
    "300": "#2d2b34",
    "400": "#3b3843",
    "500": "#4e4a58",
    "600": "#5c5767",
    "700": "#6a6775",
    "800": "#74717e",
    "900": "#888591",
    white: "#ffffff"
  },
  brand: {
    "100": "#f6ebff",
    "200": "#e7c7ff",
    "300": "#d194ff",
    "400": "#a857e5",
    "500": "#7c33b5",
    "600": "#662994",
    "700": "#411661",
    "800": "#1e082e",
    "900": "#0f011b"
  },
  info: {
    "100": "#ebf1ff",
    "200": "#c2d6ff",
    "300": "#89adf5",
    "400": "#5485e8",
    "500": "#2360db",
    "600": "#1344a8",
    "700": "#072c75",
    "800": "#011742",
    "900": "#00050f"
  },
  error: {
    "100": "#ffedef",
    "200": "#ffc9cf",
    "300": "#ff8798",
    "400": "#f74569",
    "500": "#dd2249",
    "600": "#ad0026",
    "700": "#78000e",
    "800": "#430013",
    "900": "#0f0004"
  },
  success: {
    "100": "#ebfff2",
    "200": "#daf2e3",
    "300": "#b4e0c4",
    "400": "#8cd4a6",
    "500": "#5bc783",
    "600": "#3d945d",
    "700": "#236144",
    "800": "#0e2e1f",
    "900": "#000a05"
  },
  warning: {
    "100": "#fff7d6",
    "200": "#ffefb2",
    "300": "#ffe585",
    "400": "#ffd95d",
    "500": "#f8c500",
    "600": "#d1a600",
    "700": "#8d6f00",
    "800": "#483800",
    "900": "#241c00"
  },
  miq: {
    "1000": "#2b0030"
  },
  alpha: {
    "300-32": "rgba(225 225 232 / 0.32)",
    "500-32": "rgba(106 103 117 / 0.32)",
    "600-10": "rgba(113 113 128 / 0.1)",
    "600-12": "rgba(113 113 128 / 0.12)",
    "600-4": "rgba(113 113 128 / 0.04)",
    "600-8": "rgba(113 113 128 / 0.08)"
  },
  data1: {
    "100": "#ffebf1",
    "200": "#fbc3d6",
    "300": "#ff88ac",
    "400": "#ff4f8b",
    "500": "#f90969",
    "600": "#bd0053",
    "700": "#80003b",
    "800": "#4a0022",
    "900": "#320015"
  },
  data2: {
    "100": "#fceaf4",
    "200": "#f7c3e0",
    "300": "#ef7bc3",
    "400": "#d0009c",
    "500": "#b20089",
    "600": "#950176",
    "700": "#790163",
    "800": "#5e0152",
    "900": "#440140"
  },
  data3: {
    "100": "#fff4e2",
    "200": "#ffe4b0",
    "300": "#ffd555",
    "400": "#ffba00",
    "500": "#ff9e00",
    "600": "#ff7e00",
    "700": "#c45c00",
    "800": "#4f1d00",
    "900": "#2e0e00"
  },
  data4: {
    "100": "#ffefe7",
    "200": "#ffc19f",
    "300": "#ff8240",
    "400": "#ff6e19",
    "500": "#ff5800",
    "600": "#bf4200",
    "700": "#882c00",
    "800": "#602100",
    "900": "#391000"
  }
} as const;
