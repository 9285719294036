import { Button } from "@miq/fiber-ui";
import { useNavigate } from "react-router-dom";

import { InsightsNavigationStyled } from "./styled";

/**
 * Insights Navigation component
 */
export default function InsightsNavigation() {
  const navigate = useNavigate();

  return (
    <InsightsNavigationStyled>
      <Button primary outline onClick={() => navigate("/audiences")}>
        Build Audiences
      </Button>
      <Button primary outline onClick={() => navigate("/intelligence/tv")}>
        Explore Intelligence
      </Button>
      <Button primary outline onClick={() => navigate("/creatives")}>
        Interact with Creatives
      </Button>
    </InsightsNavigationStyled>
  );
}
