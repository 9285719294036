import { Typography } from "@miq/fiber-ui";

import { useUserDetails } from "src/redux/slices/userSlice";

import { WelcomeStyled } from "./styled";

/**
 * Welcome component
 */
export default function Welcome() {
  const {
    userDetails: { firstName }
  } = useUserDetails();

  return (
    <WelcomeStyled>
      <Typography variant="Headline" size="2xl" fontWeight="light">
        Hello {firstName},
      </Typography>
      <Typography variant="Headline" size="2xl" fontWeight="light">
        Welcome to the Unified Platform
      </Typography>
    </WelcomeStyled>
  );
}
