import styled from "@emotion/styled";

const RequestAccessStyled = styled.div`
  &.request-access-form-login-wrapper {
    font-family: ${({ theme }) => theme.font.fontFamily};
    font-size: ${({ theme }) => theme.regularText.fontSize};
    margin-bottom: 16px;

    .form > div {
      margin-bottom: 16px;
    }

    .submitResponse {
      font-family: ${({ theme }) => theme.font.fontFamily};
      font-size: ${({ theme }) => theme.regularText.fontSize};
      text-align: left;
    }

    .submitButton {
      margin-bottom: 24px;
    }

    button > span {
      font-weight: 500;
    }
  }
`;

export default RequestAccessStyled;
