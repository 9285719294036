import type { RouteObject } from "react-router-dom";

import Creatives from "src/components/remoteModules/mfeApps/components/creatives";
import { AccessRoute } from "src/routes/components/accessRoute";
import { ALL_CAPABILITIES } from "src/utils/constants/navigation";

import authLoader, { remoteLoaderWrapper } from "../utils/loader";

const route: RouteObject = {
  id: ALL_CAPABILITIES.creatives.id,
  path: "/creatives/*",
  element: (
    <AccessRoute>
      <Creatives />
    </AccessRoute>
  ),
  loader: authLoader(
    remoteLoaderWrapper(window._env_.creatives, "creatives", "Creatives")
  )
};

export default route;
