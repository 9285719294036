import { useReducer } from "react";

export type State = {
  name: string;
  email: string;
  organization: string;
  jobTitle: string;
  phoneNumber: string;
  validate: {
    emailState: string;
    nameState: string;
    organisationState: string;
  };
  successfulSubmit: boolean;
  mailSent: boolean;
};

export type InputName = keyof Pick<State, "name" | "email" | "organization">;

type Action =
  | {
      type: "FORM_INPUT";
      payload: {
        name: InputName;
        value: string;
      };
    }
  | {
      type: "VALIDATE";
      payload: {
        isNameValid: boolean;
        isEmailValid: boolean;
        isOrganizationValid: boolean;
      };
    }
  | { type: "SUCCESSFUL_SUBMIT" }
  | { type: "MAIL_SENT" };

const initialState: State = {
  name: "",
  email: "",
  organization: "",
  jobTitle: "",
  phoneNumber: "",
  validate: {
    emailState: "",
    nameState: "",
    organisationState: ""
  },
  successfulSubmit: false,
  mailSent: false
};

function reducer(state: State, action: Action) {
  const { type } = action;

  switch (type) {
    case "FORM_INPUT": {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value
      };
    }
    case "VALIDATE": {
      const validate = { ...state.validate };
      const { isNameValid, isEmailValid, isOrganizationValid } = action.payload;

      validate.emailState = isEmailValid ? "has-success" : "has-danger";
      validate.nameState = isNameValid ? "has-success" : "has-danger";
      validate.organisationState = isOrganizationValid
        ? "has-success"
        : "has-danger";

      return {
        ...state,
        validate
      };
    }
    case "SUCCESSFUL_SUBMIT":
      return {
        ...state,
        successfulSubmit: true
      };
    case "MAIL_SENT":
      return {
        ...state,
        mailSent: true
      };
    default:
      return state;
  }
}

const useRequestAccessState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  function updateValidation({
    isNameValid,
    isEmailValid,
    isOrganizationValid
  }: {
    isNameValid: boolean;
    isEmailValid: boolean;
    isOrganizationValid: boolean;
  }) {
    dispatch({
      type: "VALIDATE",
      payload: { isEmailValid, isNameValid, isOrganizationValid }
    });
  }

  function updateInput(name: InputName, value: string) {
    dispatch({
      type: "FORM_INPUT",
      payload: { name, value }
    });
  }

  function updateFormSubmit() {
    dispatch({ type: "SUCCESSFUL_SUBMIT" });
  }

  function updateMailSent() {
    dispatch({ type: "MAIL_SENT" });
  }

  return {
    ...state,
    updateValidation,
    updateInput,
    updateFormSubmit,
    updateMailSent
  };
};

export default useRequestAccessState;
