import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PageLayout from "src/pages/legalTerms/components/pageLayout";
import { useUserDetails } from "src/redux/slices/userSlice";
import { acceptTermsConditions } from "src/redux/slices/userSlice/userReducer";
import { useWhiteLabel } from "src/redux/slices/whitelabel";
import type { TermsAndConditionsType } from "src/redux/slices/whitelabel/utils";
import { loadTermsAndConditions } from "src/redux/slices/whitelabel/utils";

import AcceptTerms from "./components/AcceptTerms";
import { updateTermsAcceptedForUser } from "./services";

/**
 * Terms and Conditions page component.
 */
const TermsAndConditions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useUserDetails();
  const { clientControlConfig, folderName } = useWhiteLabel();
  const shouldRedirectToHome = useRef(false);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [hasReadTerms, setHasReadTerms] = useState(false);
  const [termsContent, setTermsContent] = useState<TermsAndConditionsType[]>(
    []
  );

  const navigationItems = termsContent.map((value) => value.navTitle);

  const { termsAccepted: userHasAcceptedTerms } = userDetails;
  const { showTermsAndConditions } = clientControlConfig;

  useLayoutEffect(() => {
    (async () => {
      const content = await loadTermsAndConditions(folderName);
      setTermsContent(content);
    })();
  }, [folderName]);

  useEffect(() => {
    if (!userHasAcceptedTerms && showTermsAndConditions) {
      setHasReadTerms(false);
    } else {
      setHasReadTerms(true);
    }
  }, [userHasAcceptedTerms, showTermsAndConditions]);

  // Wait for the state to be updated before redirecting to home
  useEffect(() => {
    if (userHasAcceptedTerms && shouldRedirectToHome.current) {
      navigate("/");
    }
  }, [userHasAcceptedTerms, navigate]);

  /**
   * Handles the click event of the submit button.
   */
  async function handleAcceptTerms() {
    try {
      await updateTermsAcceptedForUser();
      dispatch(acceptTermsConditions());
      shouldRedirectToHome.current = true;
    } catch (e) {
      console.error("Accept Terms & Conditions failed: ", (e as Error).message);
      setHasReadTerms(false);
      setTermsAccepted(false);
    }
  }

  /**
   * Toggles the checkbox.
   */
  function toggleCheckBox() {
    setTermsAccepted((prev) => !prev);
  }

  if (termsContent.length === 0) return null;

  return (
    <>
      <PageLayout navItems={navigationItems} cardsData={termsContent} />
      {!hasReadTerms && (
        <AcceptTerms
          onAcceptTerms={handleAcceptTerms}
          termsAccepted={termsAccepted}
          toggleCheckBox={toggleCheckBox}
        />
      )}
    </>
  );
};

export default TermsAndConditions;
