import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";
import { THEME } from "src/utils/constants/user";
import { setInLocalStorage } from "src/utils/storageUtil";

import { loadConfigurationThunk, loadLogoThunk, setPrimitives } from "./thunk";
import type { ControlConfigType, PartialPrimitives } from "./utils";
import { getClientFolder, getDomainName, isWhiteLabelClient } from "./utils";

// TODO: Remove after demo
setInLocalStorage(THEME, "light");

/**
 * The white label configuration type.
 */
export type WhiteLabelType = {
  domainName: string;
  folderName: string;
  isWhiteLabel: boolean;
  clientControlConfig: ControlConfigType | null;
  primitives: PartialPrimitives | undefined;
  logo: string;
  headerLogo: string;
  favicon: string;
  isLoaded: boolean;
  theme: "light" | "dark";
};

/**
 * The initial white label configuration.
 */
const whiteLabelInitialData: WhiteLabelType = {
  domainName: getDomainName(),
  folderName: getClientFolder(),
  isWhiteLabel: isWhiteLabelClient(),
  clientControlConfig: null,
  primitives: undefined,
  logo: "",
  headerLogo: "",
  isLoaded: false,
  favicon: "",
  theme: "light"
};

/**
 * The slice for the white label configuration.
 */
const whiteLabelReducer = createSlice({
  name: "whiteLabel",
  initialState: whiteLabelInitialData,
  reducers: {
    setTheme: (
      state,
      action: PayloadAction<{
        theme: "light" | "dark";
        persistInStorage?: boolean;
      }>
    ) => {
      const { theme, persistInStorage = true } = action.payload;
      state.theme = theme;
      if (persistInStorage) {
        setInLocalStorage(THEME, state.theme);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadConfigurationThunk.fulfilled, (state, action) => {
      const { clientControlConfig, clientPrimitives, headerLogo } =
        action.payload;
      state.clientControlConfig = clientControlConfig;
      state.primitives = clientPrimitives;
      state.headerLogo = headerLogo;
    });

    builder.addCase(loadConfigurationThunk.rejected, (_state, action) => {
      // TODO: Add fallback configuration. What should happen if the Whitelabel configuration fails to load?
      console.error("Error loading configuration: ", action.error);
      throw new Error("Error loading configuration");
    });

    builder.addCase(loadLogoThunk.fulfilled, (state, action) => {
      state.logo = action.payload.logo;
      state.favicon = action.payload.favicon;
      state.isLoaded = true;
    });

    builder.addCase(setPrimitives.fulfilled, (state, action) => {
      state.primitives = action.payload.primitives;
    });
  }
});

export const getWhiteLabelConfig = (state: RootState) => state.whiteLabel;
export const { setTheme } = whiteLabelReducer.actions;

export default whiteLabelReducer.reducer;
