export type FormType =
  | "LOGIN_FORM"
  | "REQUEST_ACCESS_FORM"
  | "FORGOT_PASSWORD_FORM"
  | "UNAUTHORIZED_PAGE";

export const LOGIN_FORM: FormType = "LOGIN_FORM";
export const REQUEST_ACCESS_FORM: FormType = "REQUEST_ACCESS_FORM";
export const FORGOT_PASSWORD_FORM: FormType = "FORGOT_PASSWORD_FORM";
export const UNAUTHORIZED_PAGE: FormType = "UNAUTHORIZED_PAGE";
export const LOGIN_FORM_SUBTITLE = "Sign In.";
export const LOGIN_FORM_DESCRIPTION_FIRST_PART =
  "The power of better connected marketing,";
export const LOGIN_FORM_DESCRIPTION_SECOND_PART = "at your fingertips.";

export const REQUEST_ACCESS_FORM_SUBTITLE = "Request Access.";
export const FORGOT_PASSWORD_FORM_SUBTITLE = "Forgot Password?";
export const UNAUTHORIZED_PAGE_SUBTITLE = "Unauthorized!";

export const SUBTITLE_MAP_ARRAY = [
  [LOGIN_FORM, LOGIN_FORM_SUBTITLE],
  [REQUEST_ACCESS_FORM, REQUEST_ACCESS_FORM_SUBTITLE],
  [FORGOT_PASSWORD_FORM, FORGOT_PASSWORD_FORM_SUBTITLE],
  [UNAUTHORIZED_PAGE, UNAUTHORIZED_PAGE_SUBTITLE]
] as const;

export const IT_SUPPORT_SLACK_LINK =
  "https://app.slack.com/huddle/T04MMQMF8/CB0C3HH2B";

export const EMAIL_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
