import { useLocation, useNavigate } from "react-router-dom";

import usePageAccess from "src/redux/slices/pageAccess/hooks/usePageAccess";
import {
  LEGAL_TERMS_PATH,
  NAVIGATION_ITEMS_LEGAL
} from "src/utils/constants/navigation";

import HeaderShell from "./components/HeaderShell";
import useNavItems from "./hooks/useNavItems";
import { HeaderStyled } from "./styled";

/**
 * Header component for the application
 */
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navigation } = usePageAccess();

  const currentNav = location.pathname.includes(LEGAL_TERMS_PATH)
    ? NAVIGATION_ITEMS_LEGAL
    : navigation;

  const { navItems, selectedMainTab, selectedSubNav } = useNavItems({
    navItems: currentNav
  });

  /**
   * Handle the tab click.
   */
  function handleTabClick(link: string) {
    navigate(link);
  }

  return (
    <HeaderStyled
      id="ufp-app-header"
      isFullHeader={!!selectedSubNav}
      className="navigation-wrapper"
    >
      <HeaderShell
        navigationItems={navItems}
        onNavigationItemClick={handleTabClick}
        selectedTab={selectedMainTab}
        selectedSubNav={selectedSubNav}
      />
    </HeaderStyled>
  );
};

export default Header;
