import { useTheme } from "@emotion/react";
import { Typography } from "@miq/fiber-ui";
import React from "react";
import type { CarouselProps } from "react-responsive-carousel";
import { Carousel } from "react-responsive-carousel";

import useLoginCarouselItems from "./hooks/useLoginCarouselItems";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const LoginCarousel: React.FC = () => {
  const {
    carouselItems: slides,
    activeItem: activeSlide,
    handleSlideChange
  } = useLoginCarouselItems();
  const theme = useTheme();

  const carouselSettings: Partial<CarouselProps> = {
    showThumbs: false,
    stopOnHover: true,
    showStatus: false,
    showArrows: true,
    showIndicators: true,
    width: 600,
    interval: activeSlide?.interval ?? 8000,
    infiniteLoop: true,
    autoPlay: true,
    transitionTime: 1000,
    onChange: handleSlideChange
  };

  return (
    <>
      <div className="animation-text">
        <div className="animation-title">
          <Typography
            variant="Headline"
            size="2xl"
            fontWeight="medium"
            as="span"
            color={
              theme.vars.palette.surface.foreground.static.neutral.inversed
            }
          >
            {activeSlide.title}
          </Typography>
        </div>
        <div className="animation-description">
          <Typography
            variant="Body"
            size="lg"
            fontWeight="regular"
            as="span"
            color={
              theme.vars.palette.surface.foreground.dynamic.neutral.inversed
                .default.variation
            }
          >
            {activeSlide.description}
          </Typography>
        </div>
      </div>
      <div className="animation">
        {slides.length > 0 && (
          <Carousel {...carouselSettings}>
            {slides.map((slide) => (
              <img src={slide.gif} key={slide.title} alt={slide.title} />
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default React.memo(LoginCarousel);
