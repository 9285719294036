import styled from "@emotion/styled";

export const RestrictedStyled = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .restricted-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .restricted-icon {
    font-size: 44px;

    .fas {
      height: 48px;
      align-items: center;
      display: flex;
    }
  }

  .restricted-title {
    margin-top: 12px;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
  }

  .restricted-subtitle {
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
`;
