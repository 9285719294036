import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Await, useLoaderData } from "react-router-dom";

import Fallback from "src/components/fallback";
import PageLoader from "src/components/pageLoader";
import type { ModuleData } from "src/routes/routes/type";

type WithSuspenseOptionProps = {
  /**
   * Fallback component to show when the component is loading.
   * Default is Fallback component.
   */
  fallback?: React.ReactElement;
  /**
   * Unique identifier for the component.
   * Used to force a re-render when switching between components that use LazyLoad.
   */
  elementKey?: string;
};

/**
 * Adds Suspense and ErrorBoundary to a component.
 *
 * @param LazyComponent The component to wrap with Suspense and ErrorBoundary.
 * @param options Options for the HOC.
 *
 * @returns
 */
export default function withRemoteSuspense<P>(
  LazyComponent: React.ComponentType<P>,
  options: WithSuspenseOptionProps = {}
) {
  const { elementKey, fallback } = options;

  const RenderComponent = (props: P) => {
    const data = useLoaderData() as ModuleData;

    return (
      <Suspense fallback={<PageLoader />}>
        <Await
          resolve={data.remoteData}
          errorElement={fallback ?? <Fallback />}
        >
          {({ mount }) => (
            <ErrorBoundary key={elementKey} fallback={fallback ?? <Fallback />}>
              <LazyComponent
                mount={mount}
                tracking={data.tracking}
                {...props}
              />
            </ErrorBoundary>
          )}
        </Await>
      </Suspense>
    );
  };

  return RenderComponent;
}
