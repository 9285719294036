/* eslint-disable  no-template-curly-in-string */
export const authServerProperties = {
  oidc: {
    display: "page",
    clientId: process.env.REACT_APP_DEV_OKTA_CLIENT_ID,
    issuer: "https://miq.oktapreview.com/oauth2/default",
    redirectUri: `${window.location.origin}/login/callback`,
    scope: "openid profile email offline_access"
  },
  idpID: "0oag8swf06EOMPvp80h7",
  protocol: "https",
  hostname: "miq.oktapreview.com"
};

export const adminServiceProperties = {
  baseURL:
    "api-gateway-secured.dev.miqdigital.com/ui-hub-admin-service-integration",
  noAuthBaseURL:
    "api-gateway-secured.dev.miqdigital.com/ui-hub-admin-service-integration-noauth",
  adminBasePath: "/admin"
};

export const partnershipsUrl =
  "https://partnerships.dev.miqdigital.com/?hub=true";

export const userDetailsUrl =
  "https://api-gateway.dev.miqdigital.com/integration-userprofile-ui/v1/users";

export const creativesUrl = "https://portal.miqdigital.com/";

export const helpHeroAppId = process.env.REACT_APP_DEV_HELP_HERO_CLIENT_ID;

export const labUserServiceProperties = {
  labMiqBaseUrl: "https://lab-integration.dev.miqdigital.com/n/miq",
  labMiqBasePath: "/api"
};

export const mixpanelProperties = {
  enabled: true,
  token: process.env.REACT_APP_MIXPANEL_DEV_TOKEN
};

export const configCatSdkKey = process.env.REACT_APP_DEV_CONFIG_CAT_CLIENT_ID;

export const googleRecaptchaSiteKey =
  process.env.REACT_APP_DEV_RECAPTCHA_SITE_KEY;

export const grafanaEnvironment = "integration";
