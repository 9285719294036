// Remove these imports once the micro-frontends use these CSS libraries.
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { FaroErrorBoundary } from "@grafana/faro-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import WhiteLabelApp from "./components/whiteLabelApp";
import "src/utils/axiosAuthInterceptor";
import { store } from "./redux";
import { initializeFaro } from "./utils/faro";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

initializeFaro();

root.render(
  <React.StrictMode>
    <FaroErrorBoundary>
      <Provider store={store}>
        <WhiteLabelApp />
      </Provider>
    </FaroErrorBoundary>
  </React.StrictMode>
);
