import { motion } from "framer-motion";
import { useRef, useState } from "react";

import type { InsightsData } from "../../type";
import InsightsBadge from "../insightsBadge";
import TypewriterText from "../typeWriterText";

import {
  InsightWrapperStyled,
  LogoWrapperStyled,
  SeparatorStyled
} from "./styled";

type Props = {
  insight: InsightsData;
  onComplete: () => void;
};

/**
 * Insight component to display a single insight.
 */
export default function Insight({ insight, onComplete }: Props) {
  const sequenceLength = [
    insight.query,
    insight.timePart,
    insight.region,
    insight.device
  ].filter(Boolean).length;
  const [animationSequence, setAnimationSequence] = useState<boolean[]>([]);
  const isAnimationComplete = useRef(false);

  /**
   * Mark the sequence as complete.
   * If all the sequences are complete, call the onComplete callback.
   *
   * @returns void
   */
  function markSequenceComplete() {
    if (
      animationSequence.length === sequenceLength &&
      !isAnimationComplete.current
    ) {
      isAnimationComplete.current = true;
      onComplete();
      return;
    }
    setAnimationSequence((prev) => [...prev, true]);
  }

  return (
    <motion.div
      key={`insight-${insight.id}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, height: 0, padding: 0, margin: 0 }}
      transition={{ duration: 0.4 }}
      onAnimationComplete={markSequenceComplete}
    >
      <InsightWrapperStyled>
        {/* Insight label with animation */}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
        >
          <LogoWrapperStyled>
            <InsightsBadge
              type={insight.behaviour.toLowerCase()}
              label={insight.behaviour}
            />
          </LogoWrapperStyled>
        </motion.div>

        {/* Query with typewriter effect */}
        {animationSequence[0] && (
          <>
            <SeparatorStyled />
            <TypewriterText
              className="insight-query"
              text={insight.query}
              onComplete={markSequenceComplete}
            />
          </>
        )}

        {/* Timestamp */}
        {animationSequence[1] && (
          <>
            <SeparatorStyled />
            <TypewriterText
              className="insight-time"
              text={insight.timePart}
              onComplete={markSequenceComplete}
            />
          </>
        )}

        {/* Location */}
        {animationSequence[2] && insight.region && (
          <>
            <SeparatorStyled />
            <TypewriterText
              className="insight-location"
              text={insight.region}
              onComplete={markSequenceComplete}
            />
          </>
        )}

        {/* Device */}
        {animationSequence[3] && insight.device && (
          <>
            <SeparatorStyled />
            <TypewriterText
              className="insight-device"
              text={insight.device}
              onComplete={markSequenceComplete}
            />
          </>
        )}
      </InsightWrapperStyled>
    </motion.div>
  );
}
