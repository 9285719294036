import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import LegalTermsPage, {
  PrivacyPolicyPage,
  TermsAndConditionsPage
} from "src/pages/legalTerms";
import { generateFallbackRoute } from "src/routes/utils";

export const TERMS_AND_CONDITIONS_ID = "terms-and-conditions";
export const PRIVACY_POLICY_ID = "privacy-policy";

const legalTerms: RouteObject = {
  path: "/legal-terms",
  element: <LegalTermsPage />,
  children: [
    {
      path: "terms-and-conditions",
      element: <TermsAndConditionsPage />,
      id: TERMS_AND_CONDITIONS_ID
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicyPage />,
      id: PRIVACY_POLICY_ID
    },
    ...generateFallbackRoute({
      element: <Navigate to="terms-and-conditions" replace />
    })
  ]
};

export default legalTerms;
