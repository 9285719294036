import styled from "@emotion/styled";

export const InsightsPanelStyled = styled.div`
  width: 800px;
  margin-inline: auto;
  height: 420px;
  padding: 30px 24px;
  border-radius: 2px;
  background: linear-gradient(
    116deg,
    rgba(255, 255, 255, 0.3) -29.94%,
    rgba(255, 255, 255, 0.2) -29.93%,
    rgba(255, 255, 255, 0.1) 101.76%
  );
  backdrop-filter: blur(50px);
  overflow: hidden;
`;

export const InsightsListWrapperStyled = styled.ul`
  list-style: none;

  /* Using margin for spacing as flexbox is causing abrupt exit animation  */
  /* https://www.reddit.com/r/reactjs/comments/sthpmq/comment/hx4feqf */
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;
