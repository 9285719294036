import { useSelector } from "react-redux";

import { USER_PERMISSIONS } from "src/utils/constants/user";

import { getUserDetails } from "../userReducer";

export default function useUserDetails() {
  const { userDetails, googleAccInfo } = useSelector(getUserDetails);

  const isDemoMode =
    userDetails?.permissions.includes(USER_PERMISSIONS.DEMO_MODE) ?? false;

  if (!userDetails) {
    throw new Error(
      "Hook can only be used after the user details have been fetched."
    );
  }

  return { userDetails, googleAccInfo, isDemoMode };
}
