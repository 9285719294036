/* eslint-disable  no-template-curly-in-string */
export const authServerProperties = {
  oidc: {
    display: "page",
    clientId: process.env.REACT_APP_PROD_OKTA_CLIENT_ID,
    issuer: "https://miq.okta.com/oauth2/default",
    redirectUri: `${window.location.origin}/login/callback`,
    scope: "openid profile email offline_access"
  },
  idpID: "0oa23lbst7BF5ES502p7",
  protocol: "https",
  hostname: "miq.okta.com"
};

export const adminServiceProperties = {
  baseURL: "hub-api.miqdigital.com/ui-hub-admin-service",
  noAuthBaseURL: "hub-api.miqdigital.com/ui-hub-admin-service-noauth",
  adminBasePath: "/admin"
};

export const partnershipsUrl = "https://partnerships.miqdigital.com/?hub=true";

export const userDetailsUrl =
  "https://api.miqdigital.com/userprofile-ui/v1/users";

export const creativesUrl = "https://portal.miqdigital.com/";

export const helpHeroAppId = process.env.REACT_APP_PROD_HELP_HERO_CLIENT_ID;

export const labUserServiceProperties = {
  labMiqBaseUrl: "https://lab.miqdigital.com/n/miq",
  labMiqBasePath: "/api"
};

export const mixpanelProperties = {
  enabled: true,
  token: process.env.REACT_APP_MIXPANEL_PROD_TOKEN
};

export const configCatSdkKey = process.env.REACT_APP_PROD_CONFIG_CAT_CLIENT_ID;

export const googleRecaptchaSiteKey =
  process.env.REACT_APP_PROD_RECAPTCHA_SITE_KEY;

export const grafanaEnvironment = "production";
