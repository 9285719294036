import type { PropsWithChildren } from "react";
import React from "react";

import ComingSoonStyled from "./styled";

const ComingSoon: React.FC<PropsWithChildren> = ({ children }) => (
  <ComingSoonStyled className="d-flex align-items-center justify-content-center">
    <div className="fall-back-title">{children}</div>
  </ComingSoonStyled>
);

export default ComingSoon;
