import { withFaroRouterInstrumentation } from "@grafana/faro-react";
import { createBrowserRouter } from "react-router-dom";

import routes from "../routes";

const browserRouter = createBrowserRouter(routes);

const router = withFaroRouterInstrumentation(browserRouter);

export default router;
