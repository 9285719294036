// Adding the eslint-disable comment due to a false eslint error in `start` script.
// eslint-disable import/no-cycle
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import App from "src/App";
import type { AppDispatch } from "src/redux";
import {
  getWhiteLabelConfig,
  loadConfigurationThunk
} from "src/redux/slices/whitelabel";

/**
 * Initializes the white label configuration and renders the main application.
 */
const WhiteLabelApp = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoaded, folderName, clientControlConfig } =
    useSelector(getWhiteLabelConfig);
  const showBeacon = clientControlConfig?.showBeacon;

  useEffect(() => {
    if (showBeacon) {
      window.Beacon("init", "2da9836a-2181-4ccc-94ea-bab9970461d0");
    }
  }, [showBeacon]);

  useEffect(() => {
    dispatch(loadConfigurationThunk(folderName));
  }, [dispatch, folderName]);

  if (!isLoaded) {
    return null;
  }

  return <App />;
};

export default WhiteLabelApp;
