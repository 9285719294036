import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";

interface TsaState {
  isOpen: boolean;
}

const initialState: TsaState = {
  isOpen: false
};

/**
 * The slice for the tsa state.
 */
const tsaSlice = createSlice({
  name: "tsa",
  initialState,
  reducers: {
    closeTsaApp: (state) => {
      state.isOpen = false;
    },
    toggleTsaApp: (state) => {
      state.isOpen = !state.isOpen;
    }
  }
});

/**
 * Selector to get the tsa details from the state.
 */
export const getTsaState = (state: RootState) => state.tsa;
export const { toggleTsaApp, closeTsaApp } = tsaSlice.actions;
export default tsaSlice.reducer;
