import axios from "axios";

import { adminServiceProperties, userDetailsUrl } from "src/config/config";
import type {
  GoogleAccInfo,
  User
} from "src/redux/slices/userSlice/userReducer";

type LoginDetailsResponse = {
  responseBody: User;
  responseStatus: string;
};

export const getLoginDetails = () => {
  const headers = {
    "Content-Type": "application/json"
  };
  const url = `//${adminServiceProperties.baseURL}${adminServiceProperties.adminBasePath}/loginDetails`;
  return axios.post<LoginDetailsResponse>(url, { headers });
};

export const getUserProfile = (email: string) => {
  const url = `${userDetailsUrl}/uniquename/${email}`;
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.get<GoogleAccInfo>(url, { headers });
};

type RequestAccessInfo = {
  name: string;
  email: string;
  organization: string;
  jobTitle: string;
  phoneNumber: string;
  clientName: string;
  receiverMail: string;
  senderMail: string;
};

export const getRequestAccessDetails = () => {
  const headers = {
    "Content-Type": "application/json"
  };
  const url = `//${adminServiceProperties.noAuthBaseURL}${adminServiceProperties.adminBasePath}/accessRequestMail`;
  return axios.post<RequestAccessInfo>(url, { headers });
};
