import { useSelector } from "react-redux";

import { getPageAccess } from "../pageAccessReducer";

/**
 * Hook to get the capabilities and navigation items for the current user.
 * Only use this hook in components that are rendered after the page access is initialized.
 */
export default function usePageAccess() {
  const { capabilities, navigation } = useSelector(getPageAccess);

  if (!capabilities || !navigation) {
    throw new Error("Page access not initialized");
  }

  return { capabilities, navigation };
}
