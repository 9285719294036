import PageLoader from "src/components/pageLoader";
import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import { useWhiteLabel } from "src/redux/slices/whitelabel";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";

import LaunchPageV1 from "./components/launchPageV1";
import SizzlePage from "./components/sizzlePage";

/**
 * The LaunchPage component is the entry point for the application.
 */
export default function LaunchPage() {
  const { value: hasSizzlePageAccess, loading } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.heroFeatures.SHOW_HOMEPAGE_SIZZLE,
    false
  );
  const { isWhiteLabel } = useWhiteLabel();

  if (loading) {
    return <PageLoader />;
  }

  if (hasSizzlePageAccess && !isWhiteLabel) {
    return <SizzlePage />;
  }

  return <LaunchPageV1 />;
}
