import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useNavigate,
  useRevalidator
} from "react-router-dom";

import Header from "src/components/header";
import PageLoader from "src/components/pageLoader";
import { TsaAppSidePanel } from "src/components/remoteModules/mfeApps/components/tsa/TsaAppSidePanel";
import useClearSiteData from "src/hooks/useClearSiteData/useClearSiteData";
import useHelpHero from "src/hooks/useHelpHero";
import useMixpanel from "src/hooks/useMixpanel";
import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import { getPageAccess } from "src/redux/slices/pageAccess";
import { fetchUserDetails } from "src/redux/slices/userSlice/thunk";
import { getUserDetails } from "src/redux/slices/userSlice/userReducer";
import { useWhiteLabel } from "src/redux/slices/whitelabel";
import { setPrimitives } from "src/redux/slices/whitelabel/thunk";
import type { AppDispatch } from "src/redux/store";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";
import { setInLocalStorage } from "src/utils/storageUtil";

import HomeStyled from "./styled";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { userDetails, googleAccInfo } = useSelector(getUserDetails);
  const { capabilities, navigation } = useSelector(getPageAccess);

  const {
    clientControlConfig: { showTermsAndConditions }
  } = useWhiteLabel();

  const { initializeMixpanelUser } = useMixpanel();
  const { revalidate } = useRevalidator();
  const { oktaAuth } = useOktaAuth();
  const { value: isPixieEnabled } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.pixie.SHOW_PIXIE,
    false
  );

  useHelpHero();
  useClearSiteData();

  /**
   * Revalidate the loader when the user logs in.
   * This is necessary as we do not load the data until the user is authenticated.
   */
  useEffect(() => {
    revalidate();
  }, [oktaAuth, revalidate]);

  /**
   * Load user details.
   */
  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  /**
   * Initialize Mixpanel user.
   */
  useEffect(() => {
    if (userDetails && googleAccInfo) {
      initializeMixpanelUser();
    }
  }, [googleAccInfo, initializeMixpanelUser, userDetails]);

  /**
   * Identify the user in Beacon.
   */
  useEffect(() => {
    try {
      if (userDetails?.email) {
        window.Beacon?.("identify", {
          email: userDetails.email
        });
      }
    } catch (error) {
      console.error("Error identifying user in Beacon", error);
    }
  }, [userDetails]);

  /**
   * Redirect to legal terms page if the user has not accepted the terms.
   */
  useLayoutEffect(() => {
    if (
      showTermsAndConditions &&
      userDetails &&
      userDetails.termsAccepted !== true &&
      !location.pathname.includes("/legal-terms")
    ) {
      navigate("/legal-terms/terms-and-conditions");
    }
  }, [navigate, location, showTermsAndConditions, userDetails]);

  /**
   * Load pixie primitives and set the value in local storage
   */
  useEffect(() => {
    setInLocalStorage("isPixieEnabled", isPixieEnabled ? "true" : "false");
    if (isPixieEnabled) dispatch(setPrimitives());
  }, [isPixieEnabled, dispatch]);

  if (!userDetails || !capabilities || !navigation) {
    return <PageLoader />;
  }

  return (
    <>
      <Header />
      <HomeStyled className="home">
        <TsaAppSidePanel />
        <Outlet />
      </HomeStyled>
    </>
  );
};

export default Home;
