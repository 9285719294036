import styled from "@emotion/styled";

const UserSwitcherStyled = styled.div`
  &.user-toggle {
    display: flex;
    justify-content: center;
  }
`;

export default UserSwitcherStyled;
