/**
 * Save a key-value pair in local storage
 */
export function setInLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}
/**
 * Retrieves the key from local storage.
 *
 * @param {string} key key to be retrieved
 */
export function getFromLocalStorage(key: string) {
  return localStorage.getItem(key);
}

/**
 * Remove a key from local storage
 */
export function removeFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

/**
 * Sets a cookie with the specified name and value.
 */
export function setCookie(name: string, value: string, days?: number) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
}
