import { useReducer } from "react";

type FormState = {
  isFormSubmitted: boolean;
  userEmail: string;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  isInputBlank: boolean;
};

type FormAction =
  | { type: "EMAIL_INPUT"; payload: string }
  | { type: "SUBMIT_FORM_SUCCESS" }
  | { type: "SUBMIT_FORM_ERROR"; payload: string }
  | { type: "SUBMIT_FORM_LOADING" }
  | { type: "BLANK_INPUT" };

const initialFormState: FormState = {
  isFormSubmitted: false,
  userEmail: "",
  isError: false,
  isLoading: false,
  errorMessage: "",
  isInputBlank: false
};

function formReducer(state: FormState, action: FormAction) {
  const { type } = action;

  switch (type) {
    case "EMAIL_INPUT": {
      const isInputBlank = action.payload.length === 0;
      return {
        ...state,
        userEmail: action.payload,
        isError: false,
        errorMessage: "",
        isInputBlank
      };
    }
    case "SUBMIT_FORM_SUCCESS":
      return {
        ...state,
        isFormSubmitted: true,
        isLoading: false
      };
    case "SUBMIT_FORM_ERROR":
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case "SUBMIT_FORM_LOADING":
      return {
        ...state,
        isLoading: true
      };
    case "BLANK_INPUT":
      return {
        ...state,
        isInputBlank: true
      };
    default:
      return state;
  }
}

const useForgotPasswordState = () => {
  const [formState, dispatchForm] = useReducer(formReducer, initialFormState);

  function onFormLoading() {
    dispatchForm({ type: "SUBMIT_FORM_LOADING" });
  }

  function onBlankInput() {
    dispatchForm({ type: "BLANK_INPUT" });
  }

  function onFormSubmitSuccess() {
    dispatchForm({ type: "SUBMIT_FORM_SUCCESS" });
  }

  function onFormSubmitError(errorMessage: string) {
    dispatchForm({ type: "SUBMIT_FORM_ERROR", payload: errorMessage });
  }

  function onEmailInput(email: string) {
    dispatchForm({ type: "EMAIL_INPUT", payload: email });
  }

  return {
    ...formState,
    onFormLoading,
    onBlankInput,
    onFormSubmitSuccess,
    onFormSubmitError,
    onEmailInput
  };
};

export default useForgotPasswordState;
