import { Tab, TabNavs, Tabs } from "@miq/fiber-ui";
import React from "react";

import useWhiteLabel from "src/redux/slices/whitelabel/hooks/useWhiteLabel";

import UserSwitcherStyled from "./styled";

type UserSwitcherProps = {
  onTabChange: (index: number) => void;
  activeIndex: number;
};

const UserSwitcher: React.FC<UserSwitcherProps> = ({
  onTabChange,
  activeIndex
}) => {
  const {
    clientControlConfig: { oktaLogin }
  } = useWhiteLabel();

  return (
    <UserSwitcherStyled className="user-toggle" data-testid="user-switcher">
      <Tabs
        orientation="horizontal"
        type="rounded"
        activeIndex={activeIndex}
        onChange={onTabChange}
      >
        <TabNavs aria-label="rounded tabs">
          <Tab
            label="Client"
            data-testid="client-user-tab"
            className={activeIndex === 0 ? "active-tab" : "tab"}
            id={0}
          />
          <Tab
            label={oktaLogin.nonClientLoginTitle}
            data-testid="miq-user-tab"
            className={activeIndex === 1 ? "active-tab" : "tab"}
            id={1}
          />
        </TabNavs>
      </Tabs>
    </UserSwitcherStyled>
  );
};

export default UserSwitcher;
