import styled from "@emotion/styled";

import { HEADER_ELEMENT_HEIGHT_VARIABLE } from "src/utils/constants/htmlConstants";

const HeaderStyled = styled.header<{ isFullHeader: boolean }>`
  .header-nav-link {
    padding-block: 18px;
  }

  &.navigation-wrapper {
    height: var(${HEADER_ELEMENT_HEIGHT_VARIABLE});

    .primary-navigation-bar {
      height: auto;
      position: fixed;
      z-index: 9999;

      .aiq-logo {
        height: 24px;
        width: auto;
        display: flex;
      }

      .unified-header-wrapper {
        height: auto;
        min-height: 64px;

        > div > div {
          display: flex;
          width: 100%;
          margin: auto;
          padding: 0;

          div:nth-of-type(3) {
            margin-left: auto;
          }
        }
      }
    }
  }
`;

const SubNavStyled = styled.div`
  background-color: ${({ theme }) =>
    theme.vars.palette.surface.background.lvl1};

  // TODO: This is a temporary fix to make the active subnav items clickable.
  // Remove this once the SubNav component is updated to handle this.
  li.active {
    pointer-events: all;
    cursor: pointer;
  }
`;

export { HeaderStyled, SubNavStyled };
