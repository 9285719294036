import { Button } from "@miq/fiber-ui";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as TsaIcon } from "src/assets/images/tsaMessageAvatar.svg";
import useUserFeatureFlag from "src/hooks/useUserFeatureFlag";
import type { AppDispatch } from "src/redux";
import {
  getTsaState,
  toggleTsaApp
} from "src/redux/slices/tsaSlice/tsaReducer";
import { FEATURE_FLAG_KEYS } from "src/utils/constants/featureFlags";

import { TsaIconWrapper } from "./styled";

export const TsaHeaderNavigationItem = () => {
  const { value: isTsaPresent } = useUserFeatureFlag(
    FEATURE_FLAG_KEYS.tradingAgent.SHOW_TSA_MFE,
    false
  );
  const dispatch = useDispatch<AppDispatch>();
  const { isOpen: isTsaOpen } = useSelector(getTsaState);

  return isTsaPresent ? (
    <TsaIconWrapper data-testid="tsa-icon-wrapper">
      <Button
        disabled={isTsaOpen}
        data-testid="tsa-item-button"
        icon={<TsaIcon />}
        loadingState="none"
        noText
        noOutline
        onClick={() => dispatch(toggleTsaApp())}
      />
    </TsaIconWrapper>
  ) : (
    <></>
  );
};
