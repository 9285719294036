import { Link, Typography } from "@miq/fiber-ui";

import { ReactComponent as MiqFooterSvg } from "src/assets/images/miqFooterSvg.svg";
import PageLayout from "src/pages/legalTerms/components/pageLayout";
import { useWhiteLabel } from "src/redux/slices/whitelabel";
import type { TermsAndConditionsType } from "src/redux/slices/whitelabel/utils";

import { CardList, FooterStyled } from "./styled";

export default function PrivacyPolicy() {
  const {
    clientControlConfig: { productName, showPoweredByMiQLogo }
  } = useWhiteLabel();

  const privacyPolicy: TermsAndConditionsType[] = [
    {
      title: `${productName} Privacy Statement`,
      navTitle: `${productName} privacy statement`,
      body: [
        {
          pointHtml: (
            <>
              <Typography variant="Body" size="md" fontWeight="regular" as="p">
                MiQ Digital Limited (&quot;MiQ&quot; or &quot;we&quot; or
                &quot;us&quot; or &quot;our&quot;) is committed to protecting
                the personal data of individuals whose personal data we process.
              </Typography>
              <br />
              <Typography variant="Body" size="md" fontWeight="regular" as="p">
                This Statement is incorporated by reference into our MiQ Privacy
                Policy which is located at
                https://www.wearemiq.com/privacy-and-compliance/privacy-policy/
                (&quot;Privacy Policy&quot;).
              </Typography>
              <br />
              <Typography variant="Body" size="md" fontWeight="regular" as="p">
                MiQ is committed to working with you to obtain a fair and rapid
                resolution of any queries, complaints, or disputes about
                privacy. If you should have any questions about this Policy, our
                Privacy Policy or our information collection, retention, use and
                sharing practices, please contact us at privacy@miqdigital.com.
              </Typography>
            </>
          )
        }
      ]
    },
    {
      title: "What do we collect?",
      navTitle: "What do we collect?",
      body: [
        {
          pointHtml: (
            <>
              MiQ collects the following information from you in order to give
              you access to the {productName}: <br />
              <CardList>
                <li>Email address</li>
                <li>
                  Job details (e.g., agency, organization, job title, phone
                  number)
                </li>
                <li>Password</li>
              </CardList>
            </>
          )
        }
      ]
    },
    {
      title: "How do we process and use your personal data?",
      navTitle: "How do we process and use your personal data?",
      body: [
        {
          pointHtml: (
            <>
              This information is used only to provide you access to the&nbsp;
              {productName}. Any data collected or accessed through the&nbsp;
              {productName} is governed by our&nbsp;
              <Link
                href="https://www.wearemiq.com/privacy-and-compliance/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Link>
              .
            </>
          )
        }
      ]
    }
  ];

  const navigationItems = privacyPolicy.map((item) => item.navTitle);

  return (
    <>
      <PageLayout navItems={navigationItems} cardsData={privacyPolicy} />
      {showPoweredByMiQLogo && (
        <FooterStyled>
          <MiqFooterSvg className="footer-logo" />
        </FooterStyled>
      )}
    </>
  );
}
