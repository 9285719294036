import axios from "axios";

import { adminServiceProperties } from "src/config/config";

/**
 * Updates the terms accepted for the user.
 */
export function updateTermsAcceptedForUser() {
  const headers = {
    "Content-Type": "application/json"
  };

  return axios.post(
    `//${adminServiceProperties.baseURL}${adminServiceProperties.adminBasePath}/updateTerms`,
    {
      headers
    }
  );
}
