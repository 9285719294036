import { oktaAuth } from "./oktaConfig";

export function getOktaAccessToken() {
  return oktaAuth.getAccessToken();
}

export function getOktaIdToken() {
  return oktaAuth.getIdToken();
}

export function isJwtExpired() {
  const accessToken = oktaAuth.tokenManager.getSync("accessToken");
  if (!accessToken) return true;

  return oktaAuth.tokenManager.hasExpired(accessToken);
}
