import { AnimatePresence } from "framer-motion";
import { useState, useCallback } from "react";

import type { InsightsData } from "../../type";
import Insight from "../insight";

import { InsightsListWrapperStyled, InsightsPanelStyled } from "./styled";
import { generateInsights } from "./util/insights";

const allInsights = generateInsights();

/**
 * Insights Panel component.
 */
export default function InsightsPanel() {
  const [visibleInsights, setVisibleInsights] = useState<InsightsData[]>([
    allInsights[0]
  ]);
  const [currentIndex, setCurrentIndex] = useState(1);

  const MAX_VISIBLE_INSIGHTS = 8;

  /**
   * Add the next insight after typing is complete.
   * Adds a delay before adding the next insight.
   */
  const handleTypingComplete = useCallback(() => {
    /**
     * Add the next insight to the visible insights.
     */
    const addNextInsight = () => {
      // If we have reached the end of the insights, reset the index.
      const nextIndex =
        currentIndex >= allInsights.length
          ? currentIndex % allInsights.length
          : currentIndex;

      // Add the next insight
      const nextInsight = {
        ...allInsights[nextIndex]
      };

      // If we have too many insights, remove the oldest one
      if (visibleInsights.length >= MAX_VISIBLE_INSIGHTS) {
        setVisibleInsights((prev) => {
          const newInsights = [...prev];
          newInsights.shift(); // Remove the oldest insight
          return [...newInsights, nextInsight];
        });
      } else {
        setVisibleInsights((prev) => [...prev, nextInsight]);
      }

      setCurrentIndex((prev) => prev + 1);
    };

    setTimeout(() => {
      addNextInsight();
    }, 500);
  }, [currentIndex, visibleInsights.length]);

  return (
    <InsightsPanelStyled>
      <InsightsListWrapperStyled>
        <AnimatePresence>
          {visibleInsights.map((insight) => (
            <Insight
              key={insight.id}
              insight={insight}
              onComplete={handleTypingComplete}
            />
          ))}
        </AnimatePresence>
      </InsightsListWrapperStyled>
    </InsightsPanelStyled>
  );
}
