import styled from "@emotion/styled";

export const InsightWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding-block: 2px;
  gap: 8px;

  .insight-time,
  .insight-location,
  .insight-device {
    white-space: nowrap;
  }
`;

export const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SignalWrapperStyled = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LocationDeviceWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SeparatorStyled = styled.div`
  width: 1px;
  height: 12px;
  background-color: #ffffff4d;
`;
