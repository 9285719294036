import { useTheme } from "@emotion/react";
import { CustomIcon } from "@miq/fiber-ui";

import useWhiteLabel from "src/redux/slices/whitelabel/hooks/useWhiteLabel";

import FallbackStyled from "./styled";

/**
 *  Default message component when user doesn't have access to that particular page
 */
export default function Fallback() {
  const {
    clientControlConfig: { companyName }
  } = useWhiteLabel();
  const theme = useTheme();

  return (
    <FallbackStyled className="fall-back d-flex flex-row justify-content-center align-items-center">
      <div className="fall-back-content d-flex flex-column justify-content-center align-items-center">
        <div className="fall-back-icon">
          <CustomIcon
            iconName="SmileySad"
            weight="regular"
            size="48"
            color={theme.vars.palette.surface.foreground.static.neutral.lvl1}
          />
        </div>
        <div className="fall-back-title">Woops...!</div>
        <div className="fall-back-subtitle">
          Seems like something went wrong. Please reload/hard reset else reach
          out
          <br />
          to your {companyName} representative.
        </div>
      </div>
    </FallbackStyled>
  );
}
