import type { RouteObject } from "react-router-dom";
import { Outlet } from "react-router-dom";

import CommerceApp from "src/components/remoteModules/mfeApps/components/commerce";
import Discover from "src/components/remoteModules/mfeApps/components/discover";
import Tvi from "src/components/remoteModules/mfeApps/components/tvi";
import { AccessRoute } from "src/routes/components/accessRoute";
import FallbackRedirect from "src/routes/components/fallbackRedirect";
import { ALL_CAPABILITIES } from "src/utils/constants/navigation";

import authLoader, { remoteLoaderWrapper } from "../utils/loader";

const intelligenceRoute: RouteObject = {
  id: "intelligence",
  path: "/intelligence/*",
  element: <Outlet />,
  children: [
    {
      path: "tv/*",
      id: ALL_CAPABILITIES.intelligenceTv.id,
      element: (
        <AccessRoute>
          <Tvi />
        </AccessRoute>
      ),

      loader: authLoader(remoteLoaderWrapper(window._env_.tvi, "tvi", "Tvi"))
    },

    {
      path: "context/*",
      id: ALL_CAPABILITIES.intelligenceContext.id,
      element: (
        <AccessRoute>
          <Discover />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(window._env_.discover, "discover", "Discover")
      )
    },
    {
      path: "commerce/*",
      id: ALL_CAPABILITIES.intelligenceCommerce.id,
      element: (
        <AccessRoute>
          <CommerceApp />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(window._env_.commerce, "commerce", "Commerce")
      )
    },
    {
      path: "*",
      // TODO: The Fallback page should be dynamic. It should be the first child that the user has access to.
      element: <FallbackRedirect id="intelligence" />
    }
  ]
};

export default intelligenceRoute;
