import { useTheme } from "@emotion/react";
import { Shell, Typography } from "@miq/fiber-ui";
import classNames from "classnames";
import React from "react";

import { SubNavStyled } from "src/components/header/styled";
import useMixpanel from "src/hooks/useMixpanel";
import { NAV_TAB_CLICK } from "src/utils/constants/mixpanelConstants";
import type { NavigationItem } from "src/utils/constants/navigation";

type SubNavProps = {
  navItems: NavigationItem[];
  selectedTab: NavigationItem | null;
  onSubNavClick: (link: string) => void;
};

/**
 * Sub navigation component for the Header.
 */
const SubNav: React.FC<SubNavProps> = ({
  navItems,
  selectedTab,
  onSubNavClick
}) => {
  const theme = useTheme();
  const { trackEvent } = useMixpanel();

  /**
   * Render the navigation.
   */
  function renderNavigation() {
    return navItems.map((navItem) => renderNavItem(navItem));
  }

  /**
   * Render the navigation item.
   */
  function renderNavItem(navItem: NavigationItem) {
    const isActive = navItem.id === selectedTab?.id;

    function handleTabClick() {
      trackEvent(`${navItem.title.toLowerCase()}${NAV_TAB_CLICK}`);
      onSubNavClick(navItem.navigationLink);
    }

    return (
      <Shell.SubNavBar.Item
        isActive={isActive}
        onClick={handleTabClick}
        key={navItem.id}
      >
        <Typography
          className={classNames("header-sub-nav-link-text", {
            "header-sub-nav-link-text--active": isActive
          })}
          as="span"
          variant="Body"
          size="md"
          fontWeight={isActive ? "semibold" : "regular"}
          color={
            isActive
              ? theme.vars.palette.surface.foreground.dynamic.neutral.default
                  .variation
              : theme.vars.palette.surface.foreground.dynamic.neutral.default
                  .regular
          }
        >
          {navItem.title}
        </Typography>
      </Shell.SubNavBar.Item>
    );
  }

  return (
    <SubNavStyled data-testid="header-sub-nav-wrapper">
      <Shell.SubNavBar>{renderNavigation()}</Shell.SubNavBar>
    </SubNavStyled>
  );
};

export default SubNav;
