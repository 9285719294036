import adminRoute from "./admin";
import audienceRoute from "./audience";
import campaignsRoute from "./campaigns";
import creativesRoute from "./creatives";
import intelligenceRoute from "./intelligence";
import reportRoute from "./report";

export {
  intelligenceRoute,
  audienceRoute,
  campaignsRoute,
  creativesRoute,
  reportRoute,
  adminRoute
};
