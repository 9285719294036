import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const bodyStyles = ({ theme }: { theme: Theme }) => css`
  html,
  body {
    background: ${theme.vars.palette.data.d1200};
  }
`;

export const WrapperStyled = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
`;

export const BackgroundStyled = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.4;
  background: linear-gradient(
    86deg,
    ${({ theme }) => theme.vars.palette.data.d1200} 0%,
    ${({ theme }) => theme.vars.palette.surface.background.miq} 100%
  );
`;
