import { Helmet } from "react-helmet-async";

import usePageTitle from "./hooks/usePageTitle";

/**
 * Sets the page title and description based on the current page.
 */
export default function PageTitle() {
  const meta = usePageTitle();

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
    </Helmet>
  );
}
