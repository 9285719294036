import { Typography } from "@miq/fiber-ui";
import classNames from "classnames";
import { useEffect, useState } from "react";

/**
 * Typewriter effect for text.
 */
const TypewriterText = ({
  className,
  text,
  onComplete
}: {
  className?: string;
  text: string;
  onComplete: () => void;
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (displayedText.length < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(text.substring(0, displayedText.length + 1));
      }, 20);

      return () => clearTimeout(timeout);
    } else if (!isComplete) {
      setIsComplete(true);
      onComplete();
    }
  }, [displayedText, text, isComplete, onComplete]);

  return (
    <Typography
      className={classNames(className)}
      as="span"
      variant="Body"
      size="md"
      fontWeight="regular"
    >
      {displayedText}
    </Typography>
  );
};

export default TypewriterText;
