import React from "react";
import type { RouteObject } from "react-router-dom";
import { Outlet } from "react-router-dom";

import Advanced from "src/components/remoteModules/mfeApps/components/analyze";
import Report from "src/components/remoteModules/mfeApps/components/reports";
import { AccessRoute } from "src/routes/components/accessRoute";
import FallbackRedirect from "src/routes/components/fallbackRedirect";
import { ALL_CAPABILITIES } from "src/utils/constants/navigation";

import authLoader, { remoteLoaderWrapper } from "../utils/loader";

const route: RouteObject = {
  id: "reports",
  path: "/reports/*",
  element: <Outlet />,
  children: [
    {
      id: ALL_CAPABILITIES.reportsStandard.id,
      path: "standard/*",
      element: (
        <AccessRoute>
          <Report />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(window._env_.report, "report", "Report")
      )
    },
    {
      id: ALL_CAPABILITIES.reportsAdvanced.id,
      path: "advanced/*",
      element: (
        <AccessRoute>
          <Advanced />
        </AccessRoute>
      ),
      loader: authLoader(
        remoteLoaderWrapper(window._env_.analyze, "analyze", "Analyze")
      )
    },
    {
      path: "*",
      element: <FallbackRedirect id="reports" />
    }
  ]
};

export default route;
