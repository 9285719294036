import { Global, useTheme } from "@emotion/react";

import AnimatedCloud from "./components/AnimatedCloud";
import LogoLayers from "./components/logoLayers";
import { BackgroundStyled, WrapperStyled, bodyStyles } from "./styled";

export default function Background() {
  const theme = useTheme();

  return (
    <WrapperStyled>
      <Global styles={bodyStyles({ theme })} />
      <LogoLayers />
      <AnimatedCloud />
      <BackgroundStyled />
    </WrapperStyled>
  );
}
