import { AnimatedCloudStyled } from "./styled";

/**
 * Animated cloud component.
 */
export default function AnimatedCloud() {
  return (
    <AnimatedCloudStyled>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="780"
        height="761"
        viewBox="0 0 1500 1500"
        fill="none"
        className="sway spot-1"
      >
        <g filter="url(#filter0_f_351_398)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1042.29 756.201C1017.31 808.879 960.828 836.52 918.027 876.084C879.831 911.392 850.392 957.906 802.411 977.943C754.735 997.853 701.14 983.054 649.737 988.125C591.181 993.902 531.44 1031.91 476.812 1010.06C422.803 988.468 398.439 925.823 368.747 875.772C341.093 829.157 318.595 780.124 308.77 726.803C299.012 673.851 294.611 618.309 311.905 567.309C328.832 517.394 369.115 480.52 405.282 442.197C440.048 405.358 475.199 368.645 520.584 346.192C566.792 323.331 618.207 318.367 669.296 311.58C727.222 303.884 792.353 272.98 842.202 303.464C892.975 334.513 880.163 416.501 917.148 463.15C955.475 511.492 1035.33 520.872 1059.56 577.624C1083.34 633.3 1068.24 701.48 1042.29 756.201Z"
            fill="url(#paint0_linear_351_398)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_351_398"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur stdDeviation="150" />
          </filter>
          <linearGradient
            id="paint0_linear_351_398"
            x1="900"
            y1="1195"
            x2="220"
            y2="907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF7E00" />
            <stop offset="1" stopColor="#D0009C" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="780"
        height="761"
        viewBox="0 0 1500 1500"
        fill="none"
        className="sway spot-2"
      >
        <g filter="url(#filter0_f_351_398)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1042.29 756.201C1017.31 808.879 960.828 836.52 918.027 876.084C879.831 911.392 850.392 957.906 802.411 977.943C754.735 997.853 701.14 983.054 649.737 988.125C591.181 993.902 531.44 1031.91 476.812 1010.06C422.803 988.468 398.439 925.823 368.747 875.772C341.093 829.157 318.595 780.124 308.77 726.803C299.012 673.851 294.611 618.309 311.905 567.309C328.832 517.394 369.115 480.52 405.282 442.197C440.048 405.358 475.199 368.645 520.584 346.192C566.792 323.331 618.207 318.367 669.296 311.58C727.222 303.884 792.353 272.98 842.202 303.464C892.975 334.513 880.163 416.501 917.148 463.15C955.475 511.492 1035.33 520.872 1059.56 577.624C1083.34 633.3 1068.24 701.48 1042.29 756.201Z"
            fill="url(#paint0_linear_351_398)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_351_398"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur stdDeviation="150" />
          </filter>
          <linearGradient
            id="paint0_linear_351_398"
            x1="1080"
            y1="1195"
            x2="220"
            y2="907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF7E00" />
            <stop offset="1" stopColor="#D0009C" />
          </linearGradient>
        </defs>
      </svg>
    </AnimatedCloudStyled>
  );
}
