import { createAsyncThunk } from "@reduxjs/toolkit";

import type { RootState } from "src/redux/store";

import type { PartialPrimitives } from "./utils";
import {
  getFavicon,
  getLogo,
  loadControlConfig,
  loadPrimitives
} from "./utils";

/**
 * Loads the client control configuration.
 *
 * @param folderName The folder name of the domain.
 */
async function loadClientControlConfig(folderName: string) {
  const content = await loadControlConfig(folderName);
  return content;
}

/**
 * Loads the client primitives.
 *
 * @param folderName The folder name of the domain.
 */
async function loadClientPrimitives(folderName: string) {
  const content = await loadPrimitives(folderName);
  return content;
}

/**
 * Loads the client logo path from the specified path.
 *
 * @param folderName The folder name of the domain.
 * @param logo The logo path.
 */
async function loadLogo(folderName: string, logo: string) {
  const logoPath = await getLogo(folderName, logo);
  return logoPath;
}

/**
 * Thunk to load the client logo.
 */
export const loadLogoThunk = createAsyncThunk(
  "whiteLabel/loadLogo",
  async ({
    folderName,
    logo,
    favicon
  }: {
    folderName: string;
    logo: string;
    favicon: string;
  }) => {
    const logoPath = await loadLogo(folderName, logo);
    const faviconPath = await getFavicon(folderName, favicon);
    return { logo: logoPath, favicon: faviconPath };
  }
);

/**
 * Thunk to load the client configuration.
 *
 * TODO: Add error handling for when the configuration fails to load.
 */
export const loadConfigurationThunk = createAsyncThunk(
  "whiteLabel/loadConfiguration",
  async (folderName: string, { dispatch }) => {
    const clientControlConfig = await loadClientControlConfig(folderName);
    const clientPrimitives = await loadClientPrimitives(folderName);
    const headerLogo = await getLogo(folderName, clientControlConfig.logo);

    dispatch(
      loadLogoThunk({
        folderName,
        logo: clientControlConfig.loginLogo,
        favicon: clientControlConfig.icon
      })
    );

    return {
      clientControlConfig,
      clientPrimitives,
      headerLogo
    };
  }
);

export const setPrimitives = createAsyncThunk<
  { primitives: PartialPrimitives },
  void,
  { state: RootState }
>("whitelabel/loadPrimitives", async (_params, { getState }) => {
  const {
    whiteLabel: { folderName, isWhiteLabel },
    user: { isPixieTheme }
  } = getState();
  const clientPrimitives =
    isWhiteLabel || isPixieTheme
      ? await loadClientPrimitives(folderName)
      : undefined;

  return {
    primitives: clientPrimitives
  };
});
