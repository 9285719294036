import styled from "@emotion/styled";

export const SizzlePageStyled = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const SizzlePageContentStyled = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 28px;
`;
