import styled from "@emotion/styled";

const ComingSoonStyled = styled.div`
  margin-top: 12.5rem;

  .fall-back-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
  }
`;

export default ComingSoonStyled;
