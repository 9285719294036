import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import type { NavigationItem } from "src/utils/constants/navigation";

type Props = {
  navItems: NavigationItem[];
};

/**
 * Hook to get the navigation items, the selected main tab and sub nav.
 */
export default function useNavItems({ navItems }: Props) {
  const location = useLocation();

  const [selectedMainTab, setSelectedMainTab] = useState<NavigationItem | null>(
    null
  );
  const [selectedSubNav, setSelectedSubNav] = useState<NavigationItem | null>(
    null
  );

  useEffect(() => {
    const currentTab = navItems.find((navItem) =>
      location.pathname.startsWith(navItem.navigationLink)
    );
    const clonedCurrentTabChildren = [...(currentTab?.children ?? [])];
    clonedCurrentTabChildren.sort(
      (currentChild, nextChild) =>
        nextChild.navigationLink.length - currentChild.navigationLink.length
    );

    const subTab = clonedCurrentTabChildren.find((navItem) =>
      location.pathname.startsWith(navItem.navigationLink)
    );
    setSelectedMainTab(currentTab ?? null);
    setSelectedSubNav(subTab ?? null);
  }, [location, navItems]);

  return { navItems, selectedMainTab, selectedSubNav };
}
