import { useWhiteLabel } from "src/redux/slices/whitelabel";

const UNIFIED = "unified";

const HUB_META = {
  title: "Hub",
  description: "Hub Intelligence"
};

const UNIFIED_META = {
  title: "Unified Platform",
  description: "Unified Platform"
};

/**
 * Returns the title and description for the current page.
 */
export default function usePageTitle() {
  const { isWhiteLabel, clientControlConfig } = useWhiteLabel();

  if (isWhiteLabel) {
    return {
      title: clientControlConfig?.title || "",
      description: ""
    };
  }

  const meta =
    window.location.origin.includes(UNIFIED) ||
    window.location.origin.includes("localhost")
      ? UNIFIED_META
      : HUB_META;

  return {
    title: meta.title,
    description: meta.description
  };
}
