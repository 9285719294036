import { GlobalBanner as GlobalBannerFiber } from "@miq/fiber-ui";

import useGlobalBanner from "./hooks/useGlobalBanner";

/**
 * Global banner component.
 */
export default function GlobalBanner() {
  const {
    isBannerVisible,
    bannerTitle,
    globalBannerType,
    isGlobalBannerDismissible,
    hideBanner
  } = useGlobalBanner();

  if (!isBannerVisible) {
    return null;
  }

  return (
    <GlobalBannerFiber
      type={globalBannerType}
      title={bannerTitle}
      showCloseIcon={isGlobalBannerDismissible}
      onClose={hideBanner}
    />
  );
}
